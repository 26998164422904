import React, { useEffect, useState } from 'react';
import { useTokenRewards } from '@/hooks/useTokenRewardsCenter';
import { formatPriceNumber } from '@/utils/number';
import { useNavigate } from 'react-router-dom';

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const calculateTimeLeft = (endDate: number): TimeLeft => {
  const difference = endDate - Date.now();
  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  return { days: 0, hours: 0, minutes: 0, seconds: 0 };
};

export const JackpotCenterContainer: React.FC = () => {
  const navigate = useNavigate();
  const {
    loadTokenRewards,
    swapJackpot,
    swapJackpotEntries,
    tokenRewardCenter,
  } = useTokenRewards();
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      await loadTokenRewards();
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (swapJackpot?.ending_time) {
      const endDate = new Date(swapJackpot.ending_time).getTime();
      setTimeLeft(calculateTimeLeft(endDate));

      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(endDate));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [swapJackpot?.ending_time]);

  if (!swapJackpot) {
    return (
      <div className="w-full h-full items-center justify-center">
        Loading...
      </div>
    );
  }

  const jackpotAmount = swapJackpot?.jackpot_amount || 0;
  const jackpotEntries = swapJackpot?.num_entries || 0;
  const userJackpotEntries = tokenRewardCenter?.jackpot_entries || 0;

  return (
    <div className="main-content mx-auto p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">{''}</h1>
        <button
          onClick={() => navigate('/swap-jackpot')}
          className="mt-4 mr-2 text-blue-500 font-semibold"
        >
          How it works
        </button>
      </div>
      <div className="flex flex-col items-center space-y-4">
        <img
          src="assets/images/swap_jackpot_top.png"
          alt="Jackpot Prize"
          className="w-20"
        />
        <h1 className="font-bold text-3xl text-primary">Jackpot</h1>
        <p className="text-center">
          Earn entries to the weekly Jackpot every time you trade with
          AssetDash.
        </p>
      </div>
      <div className="card p-4 rounded-md my-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img
              src="assets/images/swap_jackpot_top.png"
              alt="Jackpot Prize"
              className="h-6 w-6 mr-2"
            />
            <span className="font-semibold">Jackpot Prize</span>
          </div>
          <div className="flex items-center">
            <img
              src={
                swapJackpot.asset?.logo_url ||
                'assets/images/swap_jackpot_top.png'
              }
              alt="Asset"
              className="h-6 w-6 mr-2"
            />
            <span className="text-xl font-bold">
              {swapJackpot.asset?.ticker} {formatPriceNumber(jackpotAmount)}
            </span>
          </div>
        </div>
      </div>

      <div className="card p-4 mb-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img
              src="assets/images/swap_jackpot_jewel_2.png"
              alt="Your Entries"
              className="h-6 w-6 mr-2"
            />
            <span className="font-semibold">Your Entries</span>
          </div>
          <span className="text-xl font-bold">{userJackpotEntries}</span>
        </div>
      </div>

      <div className="card p-4 mb-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img
              src="assets/images/swap_jackpot_jewel_2.png"
              alt="Total Entries"
              className="h-6 w-6 mr-2"
            />
            <span className="font-semibold">Total Entries</span>
          </div>
          <span className="text-xl font-bold">{jackpotEntries}</span>
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4">
        {(Object.keys(timeLeft) as (keyof TimeLeft)[]).map(
          (interval, index) => (
            <div
              key={index}
              className="w-[76px] card flex flex-col text-center rounded-lg p-2"
            >
              <span className="text-xl font-bold">{timeLeft[interval]}</span>
              <span className="text-sm">
                {interval.charAt(0).toUpperCase() + interval.slice(1)}
              </span>
            </div>
          ),
        )}
      </div>

      <h3 className="text-lg font-semibold ml-5 my-4">Your Entries</h3>

      {swapJackpotEntries && swapJackpotEntries.length === 0 ? (
        <p className="text-center">No entries found</p>
      ) : (
        <div className="space-y-4">
          {(swapJackpotEntries || []).map(entry => (
            <div
              key={entry.id}
              className="flex justify-between items-center p-4 border-b border-gray-text dark:border-learn-course-card"
            >
              <span className="text-sm text-gray-600 dark:text-gray-300">
                {new Date(entry.created).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </span>
              <div className="flex items-center">
                <span className="text-lg font-semibold text-primary mr-2">
                  {formatPriceNumber(entry.amount)}
                </span>
                <img
                  src="assets/images/swap_jackpot_jewel_2.png"
                  alt="Entry Jewel"
                  className="h-4 w-4"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
