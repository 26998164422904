import { useNavigate } from 'react-router-dom';

export const DashDropCard: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={`h-96 w-56 p-4 flex flex-col justify-between card`}>
      <div className="md:col-span-2 lg:col-span-2 bg-white p-1 mb-2 dark:bg-deals-card rounded-13 h-max">
        <img
          src="/assets/images/dash-drop.png"
          className="min-w-42 h-42 object-contain mx-auto"
          alt="logo"
        />
      </div>
      <div className="space-y-1">
        <div className="text-md leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
          Dash Drop
        </div>
        <div className="text-xs leading-17 text-ellipsis overflow-hidden text-gray-text h-16">
          Weekly Dash Drop to win prizes!
        </div>
      </div>
      <div
        onClick={() => {
          navigate('/giveaways');
        }}
        className={`py-2 font-semibold rounded-8 text-center bg-price-green text-input-box cursor-pointer`}
      >
        View
      </div>
    </div>
  );
};
