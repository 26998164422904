import React from 'react';
import { useNavigate } from 'react-router-dom';

export const GoldCenterHeader: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-3">
      <div className="col-span-1"></div>
      <h1 className="col-span-1 text-32 text-center flex-1 leading-none pb-4">
        Gold Center
      </h1>
      <div className="flex w-full justify-end items-start">
        <div
          className="col-span-1 h-10 w-32 flex items-center justify-center cursor-pointer p-2 text-sm space-x-1
        text-md text-center px-4 py-1 border-1 bg-opacity-10 bg-accent border-accent text-accent rounded-lg"
          onClick={() => {
            navigate('/swap-rewards');
          }}
        >
          How it works
        </div>
      </div>
    </div>
  );
};
