import { useEffect, useState } from 'react';
import { useLearn } from '@/hooks/useLearn';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export default function CourseTag() {
  const {
    courseTags,
    fetchCourseTags,
    selectedCourse,
    setSelectedCourse,
    setPage,
    isActionLoading: isLoading,
  } = useLearn();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetchCourseTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = id => {
    const resp = selectedCourse?.find(item => item === id);
    analytics.track(`learn_tag_click`, {
      id: id,
    });
    if (resp) {
      setSelectedCourse(current => current.filter(curr => curr !== id));
    } else {
      setSelectedCourse([id]);
    }
    setPage(1);
    setIsDropdownOpen(false);
  };

  const getSelectedTagName = () => {
    if (!selectedCourse || selectedCourse.length === 0) return 'All Topics';
    const selectedTag = courseTags?.find(tag => tag.id === selectedCourse[0]);
    return selectedTag?.name || 'All Topics';
  };

  if (isLoading) {
    return (
      <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 my-auto justify-start items-center">
        <SkeletonLoader height={300} />
      </div>
    );
  }

  if (!courseTags) {
    return <div></div>;
  }

  return (
    <>
      <div className="md:hidden relative w-full px-4">
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="w-full py-2 px-4 bg-short-description dark:bg-blue-dark rounded-xl flex justify-between items-center"
        >
          <span className="font-bold">{getSelectedTagName()}</span>
          <ChevronDownIcon
            className={`w-5 h-5 transition-transform ${
              isDropdownOpen ? 'transform rotate-180' : ''
            }`}
          />
        </button>

        {isDropdownOpen && (
          <div className="absolute z-10 mt-2 w-full bg-short-description dark:bg-blue-dark rounded-xl shadow-lg">
            {courseTags.map(courseTag => (
              <div
                key={courseTag.id}
                onClick={() => handleSelect(courseTag.id)}
                className={`cursor-pointer px-4 py-3 first:rounded-t-xl last:rounded-b-xl ${
                  selectedCourse.indexOf(courseTag.id) > -1
                    ? 'bg-white text-black'
                    : 'text-black dark:text-gray-learn hover:bg-white/10'
                }`}
              >
                {courseTag.name}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="hidden md:flex flex-wrap justify-center md:justify-start lg:justify-around items-center">
        {courseTags.map(courseTag => (
          <div
            key={courseTag.id}
            onClick={() => handleSelect(courseTag.id)}
            className={`cursor-pointer min-w-32 mt-4 lg:mt-0 h-max py-2 mr-4 font-bold border-1 rounded-full text-center ${
              selectedCourse.indexOf(courseTag.id) > -1
                ? 'bg-white text-black border-white'
                : 'text-black dark:text-gray-learn border-gray-text'
            }`}
          >
            {courseTag.name}
          </div>
        ))}
      </div>
    </>
  );
}
