import { axiosAssetDashApi } from './api';

export interface CoinCenterJson {
  total_coins?: number | null;
  liquid_coins?: number | null;
  locked_coins?: number | null;
  bid_coins?: number | null;
  spent_coins?: number | null;
}

export interface CoinCenterResponse {
  coin_center: CoinCenterJson;
}

export interface UserCoinCenter {
  news_url: string;
  image_url: string;
  title: string;
  text: string;
  source_name: string;
  timestamp: string;
  topics: string[];
  sentiment: string;
  type: string;
  tickers: string[];
}

export const getUserCoinCenter = async (): Promise<{
  data: CoinCenterResponse;
}> => {
  return await axiosAssetDashApi.get('/api/api_v5/users/coin-center');
};

export interface CoinDistributionsResponse {
  coin_distributions: CoinDistributionJson[];
}

export interface CoinDistributionJson {
  created: string;
  distribution_type: string;
  distribution_description: string;
  coins_multiplier: number;
  coins_multiplier_original?: number;
  coins_original: number;
  coins_total: number;
  unlock_date: string;
  lock_status: string;
}

export const getCoinDistributions = async (
  sort_by: string,
  filter_by: string,
  page: number,
): Promise<{
  data: CoinDistributionsResponse;
}> => {
  const params = {
    sort_by,
    filter_by,
    page,
  };
  return await axiosAssetDashApi.get('/api/api_v4/coins/coin-distributions', {
    params,
  });
};

export interface CoinSpendingsResponse {
  coin_spendings: CoinSpendingJson[];
}

export interface CoinSpendingJson {
  created: string;
  spending_type: string;
  spending_description: string;
  coins_spent?: number;
}

export const getCoinSpendings = async (): Promise<{
  data: CoinSpendingsResponse;
}> => {
  return await axiosAssetDashApi.get('/api/api_v4/coins/coin-spendings');
};
