export const NavGameIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/linear/game">
      <g id="game">
        <path
          id="Vector"
          d="M17.1358 14.5337L20.6941 18.092"
          stroke="currentColor"
          strokeWidth="1.4997"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M20.6589 14.5688L17.1006 18.1272"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M12.5171 16.33H12.5054"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M7.92139 16.33H7.90972"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M10.2192 18.6398V18.6165"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M10.2192 14.044V14.0206"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_7"
          d="M17.801 25.6616H10.8024C4.97024 25.6616 2.63737 23.3287 2.63737 17.4966V15.1637C2.63737 9.33153 4.97024 6.99866 10.8024 6.99866H17.801C23.6332 6.99866 25.9661 9.33153 25.9661 15.1637V17.4966C25.9661 23.3287 23.6332 25.6616 17.801 25.6616Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_8"
          d="M13.1231 2.33276L13.1348 3.5111C13.1465 4.15276 13.6598 4.6661 14.3015 4.6661H14.3365C14.9781 4.6661 15.4915 5.1911 15.4915 5.83276C15.4915 6.47443 14.9665 6.99943 14.3248 6.99943H13.1581"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
