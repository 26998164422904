import { axiosAssetDashApi } from './api';

const BASE_URL = 'api/api_v5/portfolio-sync';

export function getPortfolioSync(checkCache = true) {
  return axiosAssetDashApi.get(`${BASE_URL}/all`, {
    params: { check_cache: checkCache },
  });
}

export function getAssetPrices() {
  return axiosAssetDashApi.get(`${BASE_URL}/asset-prices`);
}

export function getNftChainPrices() {
  return axiosAssetDashApi.get(`${BASE_URL}/nft-chain-prices`);
}

export function getPortfolioChartSeries(series?: string) {
  const params = {
    ...(series && { series }),
  };
  return axiosAssetDashApi.get(`${BASE_URL}/portfolio-chart-series`, {
    params,
  });
}

export function getAssetHoldingChartSeries(assetId?: string) {
  const params = {
    ...(assetId && { asset_id: assetId }),
  };
  return axiosAssetDashApi.get(`${BASE_URL}/holding-chart-series`, {
    params,
  });
}

export function getNFTHoldingChartSeries(nftItemId?: string) {
  const params = {
    ...(nftItemId && { nft_item_id: nftItemId }),
  };
  return axiosAssetDashApi.get(`${BASE_URL}/nft-holding-chart-series`, {
    params,
  });
}

interface EditNFTDetailsParams {
  userWalletCurrentNftItemId: string;
  costBasisUsd?: number;
  costBasisNative?: number;
  floorMultiplier?: number;
  manualPriceUsd?: number;
}

export function editNFTDetails({
  userWalletCurrentNftItemId,
  costBasisUsd,
  costBasisNative,
  floorMultiplier,
  manualPriceUsd,
}: EditNFTDetailsParams) {
  const params = {
    ...(costBasisUsd !== null && { cost_basis_usd: costBasisUsd }),
    ...(costBasisNative !== null && { cost_basis_native: costBasisNative }),
    ...(floorMultiplier !== null && { floor_multiplier: floorMultiplier }),
    ...(manualPriceUsd !== null && { manual_price_usd: manualPriceUsd }),
  };

  return axiosAssetDashApi.post(
    `${BASE_URL}/nfts/details/${userWalletCurrentNftItemId}`,
    {
      ...params,
    },
  );
}

export function getWalletTransactions(checkCache: boolean) {
  const params = {
    check_cache: checkCache,
  };
  return axiosAssetDashApi.get(`${BASE_URL}/wallet/activity`, {
    params,
  });
}
