import { useNavigate } from 'react-router-dom';

export default function FeaturedCourse({ data, title }) {
  const navigate = useNavigate();

  const handleStart = course => {
    analytics.track('learn_featured_course_view_detail', {
      id: course.id,
    });
    navigate(`/learn/${course.id}`);
  };

  return (
    <div className="space-y-4">
      {title && <p className="px-2 font-bold text-22">{title}</p>}
      <div className="w-full flex justify-between overflow-x-scroll">
        {data?.map(course => {
          const { reward } = course;
          const { cash_back, tickets, coins } = reward || '';
          return (
            <div
              className="text-left"
              key={course.id}
              onClick={() => handleStart(course)}
            >
              <div className="rounded-13 w-96 mr-4 relative border border-solid border-input-box cursor-pointer">
                <img
                  src={course.banner_image_url}
                  className="h-48 w-full object-cover rounded-13"
                  alt="feature course"
                />
                <div className="absolute top-0 p-4 text-white flex flex-col h-fill-avail w-96">
                  {/* <div className="space-y-4">
                  <p className="text-right text-17">{course.partner_name}</p>
                  <p className="text-8 leading-8">{course.title}</p>
                </div> */}
                  <div className="flex space-x-1 md:space-x-2 justify-end mt-auto">
                    {coins > 0 && (
                      <div className="flex space-x-1 md:space-x-2 items-center bg-light-yellow-green px-1 md:px-2 rounded-full border-1 border-solid border-price-green">
                        <img
                          src="/assets/icons/coin.png"
                          className="h-4 w-4"
                          alt="coin"
                        />
                        <p className="text-price-green text-10 md:text-sm font-bold">
                          {coins} Coins
                        </p>
                      </div>
                    )}
                    {cash_back > 0 && (
                      <div className="flex space-x-1 md:space-x-2 items-center bg-light-yellow-card px-1 md:px-2 rounded-full border-1 border-solid border-main-yellow">
                        <img
                          src="/assets/images/cash.png"
                          className="h-4 w-4"
                          alt="cash"
                        />
                        <p className="text-main-yellow text-10 md:text-sm font-bold">
                          ${cash_back} Cash
                        </p>
                      </div>
                    )}
                    {tickets > 0 && (
                      <div className="flex space-x-1 md:space-x-2 items-center bg-light-yellow-green px-1 md:px-2 rounded-full border-1 border-solid border-price-green">
                        <img
                          src="/assets/images/ticket.png"
                          className="h-4 w-4"
                          alt="ticket"
                        />
                        <p className="text-price-green text-10 md:text-sm font-bold">
                          {tickets} {tickets > 1 ? 'Tickets' : 'Ticket'}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
