import { AuthProvider } from '../hooks/useAuth';
import { PortfolioProvider } from '../hooks/usePortfolio';
import { DealsProviderLegacy } from '../hooks/useDealsLegacy';
import { DealsProvider } from '../hooks/useDeals';
import { GamesProvider } from '../hooks/useGames';
import { MemberProvider } from '../hooks/useMember';
import { LearnProvider } from '../hooks/useLearn';
import { ConfettiProvider } from '../hooks/useConfetti';
import { CardCenterProvider } from '../hooks/useCardCenter';
import { NotificationSettingsProvider } from '../hooks/useNotificationSettings';
import { InboxProvider } from '../hooks/useInbox';
import { RewardsProvider } from '../hooks/useRewards';
import { AuctionsProvider } from '../hooks/useAuctions';
import { ExpeditionsProvider } from '../hooks/useExpeditions';

import { combineComponents } from '../utils/combineComponents';
import { CoinCenterProvider } from '@/hooks/useCoinCenter';
import { SwapCenterProvider } from '@/hooks/useSwapCenter';
import { GoldCenterProvider } from '@/hooks/useGoldCenter';
import { TokenRewardsProvider } from '@/hooks/useTokenRewardsCenter';

const providers = [
  ConfettiProvider,
  AuthProvider,
  PortfolioProvider,
  DealsProviderLegacy,
  MemberProvider,
  DealsProvider,
  GamesProvider,
  LearnProvider,
  CardCenterProvider,
  NotificationSettingsProvider,
  InboxProvider,
  RewardsProvider,
  SwapCenterProvider,
  AuctionsProvider,
  ExpeditionsProvider,
  CoinCenterProvider,
  GoldCenterProvider,
  TokenRewardsProvider,
];

export const AppContextProvider = combineComponents(...providers);
