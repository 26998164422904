import { ExpeditionCenter } from '../../features/expeditions/models/ExpeditionCenter';

// utils
import { formatNumber } from '../../utils/number';

interface ExpeditionCenterCardProps {
  expeditionCenter: ExpeditionCenter;
}

export const ExpeditionCenterCard: React.FC<ExpeditionCenterCardProps> = ({
  expeditionCenter,
}) => {
  return (
    <div className="flex justify-center">
      <div className="p-4 w-full md:w-1/2 flex flex-col space-y-2 justify-between text-element-text-light dark:text-element-text-dark card rounded-lg mb-4 pb-4">
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center space-x-2">
            <img
              className="rounded-full"
              src="/assets/svg/nav/expeditions.svg"
              alt="expeditions"
              height={30}
              width={30}
            />
            <div>App Expeditions</div>
          </div>
          <div className="font-semibold">
            {expeditionCenter.completedAssetDashExpeditions}/
            {expeditionCenter.availableAssetDashExpeditions}
          </div>
        </div>
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center space-x-2">
            <img
              className="rounded-full"
              src="/assets/svg/global.svg"
              alt="expeditions"
              height={30}
              width={30}
            />
            <div>Ecosystem Expeditions</div>
          </div>
          <div className="font-semibold">
            {expeditionCenter.completedPartnerExpeditions}/
            {expeditionCenter.availablePartnerExpeditions}
          </div>
        </div>
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center space-x-2">
            <img
              className="rounded-full"
              src="/assets/icons/coin.png"
              alt="expeditions"
              height={30}
              width={30}
            />
            <div>App Expeditions</div>
          </div>
          <div className="font-semibold">
            {formatNumber(
              expeditionCenter.earnedAssetDashExpeditionCoins || 0,
              0,
            )}
            /
            {formatNumber(
              expeditionCenter.availableAssetDashExpeditionCoins || 0,
              0,
            )}
          </div>
        </div>
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center space-x-2">
            <img
              className="rounded-full"
              src="/assets/icons/coin.png"
              alt="expeditions"
              height={30}
              width={30}
            />
            <div>Ecosystem Expeditions</div>
          </div>
          <div className="font-semibold">
            {formatNumber(
              expeditionCenter.earnedPartnerExpeditionCoins || 0,
              0,
            )}
            /
            {formatNumber(
              expeditionCenter.availablePartnerExpeditionCoins || 0,
              0,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
