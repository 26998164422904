import { formatPriceNumber } from '@/utils/number';
import React from 'react';

interface DealInfoProps {
  deal: {
    type_display?: string;
    blockchain: string;
    custom_data?: Array<{ label: string; value: string }>;
    asset?: { ticker: string; price_usd: number };
    asset_quantity?: number;
    loot_box_asset?: {
      ticker: string;
      price_usd: number;
      name: string;
    };
    loot_box_asset_quantity_low?: number;
    loot_box_asset_quantity_high?: number;
  };
}

export const DealInfo: React.FC<DealInfoProps> = ({ deal }) => {
  return (
    <div className="card p-6 text-sm md:text-base rounded-13">
      <h3 className="text-start text-18 font-bold">Info</h3>
      <div className="mt-4 space-y-2">
        {/* Deal Type */}
        <div className="flex justify-between">
          <span className="text-fade-text-dark">Type</span>
          <span className="font-semibold">{deal.type_display}</span>
        </div>

        {/* Blockchain */}
        <div className="flex justify-between">
          <span className="text-fade-text-dark">Blockchain</span>
          <span className="font-semibold">{deal.blockchain.toUpperCase()}</span>
        </div>

        {/* Custom Data */}
        {deal.custom_data && deal.custom_data.length > 0 && (
          <div className="mt-2 space-y-2">
            {deal.custom_data.map((data, index) => (
              <div key={index} className="flex justify-between">
                <span className="text-fade-text-dark">{data.label}</span>
                <span className="font-semibold">{data.value}</span>
              </div>
            ))}
          </div>
        )}

        {/* Loot Box Asset Info */}
        {deal.loot_box_asset && (
          <div className="space-y-2">
            <div className="flex justify-between">
              <span className="text-fade-text-dark">Lootbox Asset</span>
              <span className="font-semibold">
                {deal.loot_box_asset.name} ({deal.loot_box_asset.ticker})
              </span>
            </div>

            <div className="flex justify-between">
              <span className="text-fade-text-dark">Lootbox Quantity</span>
              <span className="font-semibold">
                {deal.loot_box_asset_quantity_low} -{' '}
                {deal.loot_box_asset_quantity_high} {deal.loot_box_asset.ticker}
              </span>
            </div>

            <div className="flex justify-between">
              <span className="text-fade-text-dark">Price (USD)</span>
              <span className="font-semibold">
                ${formatPriceNumber(deal.loot_box_asset.price_usd)}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
