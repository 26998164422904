import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// components
import PartyLottie from '../../common/PartyLottie';
import { BottomNav } from './BottomNav';
import { Footer } from './Footer';
import { Header } from './Header';

// hooks
import { useAuth } from '../../../hooks/useAuth';
import { useConfetti } from '../../../hooks/useConfetti';
import { useMember } from '../../../hooks/useMember';
import { usePortfolio } from '../../../hooks/usePortfolio';

// recoil
import { useTokenState } from '../../../recoil/token';
import Sidebar from './Sidebar';

interface LayoutProps {
  children: React.ReactNode;
}

const PRICE_UPDATE_INTERVAL = parseInt(
  process.env.REACT_APP_PRICE_UPDATE_INTERVAL || '60000',
);

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const {
    isLoggedIn,
    fetchAllCurrencies,
    fetchUserProfile,
    updateDevicePWAData,
  } = useAuth();
  const { load, backgroundLoadAssetPrices, backgroundLoadNFTChainPrices } =
    usePortfolio();
  const { showConfetti, setShowConfetti } = useConfetti();

  const [token] = useTokenState();
  const { fetchMemberCenterData } = useMember();

  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (token) {
      fetchUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      updateDevicePWAData();
      fetchMemberCenterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => {
        setShowConfetti(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfetti]);

  useEffect(() => {
    let assetPriceId: ReturnType<typeof setInterval> | null = null;
    let nftChainPriceId: ReturnType<typeof setInterval> | null = null;

    if (isLoggedIn) {
      load({ showLoading: true, checkCache: true });
      fetchAllCurrencies();

      assetPriceId = setInterval(() => {
        backgroundLoadAssetPrices();
      }, PRICE_UPDATE_INTERVAL);

      nftChainPriceId = setInterval(() => {
        backgroundLoadNFTChainPrices();
      }, PRICE_UPDATE_INTERVAL);
    } else {
      if (assetPriceId) {
        clearInterval(assetPriceId);
      }
      if (nftChainPriceId) {
        clearInterval(nftChainPriceId);
      }
    }

    return () => {
      if (assetPriceId) {
        clearInterval(assetPriceId);
      }
      if (nftChainPriceId) {
        clearInterval(nftChainPriceId);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const resetPasswordRegex = /^\/reset-password\//;
  if (resetPasswordRegex.test(location.pathname)) {
    return <div className="screen scroller min-h-screen">{children}</div>;
  }

  return (
    <div
      className={`screen scroller min-h-screen ${
        isSidebarOpen ? 'overflow-hidden' : ''
      }`}
    >
      <Header isOpen={isSidebarOpen} />
      <Sidebar isOpen={isSidebarOpen} toggle={toggleSidebar} />
      <div className="flex flex-col flex-grow">
        <main
          className={`${
            isSidebarOpen ? 'shift-content' : 'closed'
          } flex-grow pb-32 pt-10 md:pb-28 md:pt-16`}
        >
          {children}
        </main>
        <Footer />
      </div>
      <BottomNav />
      {showConfetti && <PartyLottie />}
    </div>
  );
};
