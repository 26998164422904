import { JackpotCenterContainer } from '@/components/jackpot/JackpotCenterContainer';
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
export default function JackpotCenterPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <SuperSEO title="AssetDash | Jackpot Center" lang="en_IE" />
      <JackpotCenterContainer />
    </div>
  );
}
