import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AdjustmentsVerticalIcon,
  FunnelIcon,
  ArrowsUpDownIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from '@heroicons/react/24/solid';

// hooks
import { useDeals } from '@/hooks/useDeals';

// components
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';
import { DealsRow } from './DealsRow';
import { DealSearchBar } from './DealSearchBar';
import { DealsRowV2 } from './DealsRowV2';
import {
  DealFilterByOption,
  DealSortByOption,
  DealsPurchaseByOption,
} from '@constants';

const enabledGold = process.env.REACT_APP_ENABLE_GOLD === 'true';

export const DealsTab: React.FC = () => {
  const {
    deals,
    popularDeals,
    vantaDeals,
    premiumDeals,
    giveawayDeals,
    latestDeals,
    userAllDeals,
    dealsTags,
    dealsLoading,
    loadV5Deals,
    loadUserDeals,
    loadUserCoupons,
    loadAllUserDeals,
    setDealsLoading,
  } = useDeals();

  const [purchaseBy, setPurchaseBy] = useState(DealsPurchaseByOption.All);
  const [sortBy, setSortBy] = useState(DealSortByOption.Priority);
  const [filterBy, setFilterBy] = useState(DealFilterByOption.All);
  const [isPurchaseByOpen, setIsPurchaseByOpen] = useState(false);
  const [isFilterByOpen, setIsFilterByOpen] = useState(false);
  const [isSortByOpen, setIsSortByOpen] = useState(false);

  useEffect(() => {
    loadDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseBy, sortBy, filterBy]);

  const loadDeals = async () => {
    if (userAllDeals.length === 0) {
      setDealsLoading(true);
    }

    try {
      const promises = [
        loadV5Deals(purchaseBy, filterBy, sortBy),
        loadUserCoupons(),
      ];
      if (!enabledGold) {
        promises.push(loadUserDeals(), loadAllUserDeals());
      }

      // Use Promise.race to set loading to false as soon as one completes
      Promise.race(promises).then(() => {
        setDealsLoading(false);
      });
    } catch (error) {
      console.error(error);
      setDealsLoading(false);
    }
  };

  const navigate = useNavigate();

  const navigateToDealDetails = (tagId: string) => {
    const params = new URLSearchParams(window.location.search);
    navigate(`/deals/tag/${tagId}?tab=${params.get('tab')}`);
  };

  const handleDropdownClick = (
    dropdownType: 'purchase' | 'filter' | 'sort',
  ) => {
    setIsPurchaseByOpen(
      dropdownType === 'purchase' ? !isPurchaseByOpen : false,
    );
    setIsFilterByOpen(dropdownType === 'filter' ? !isFilterByOpen : false);
    setIsSortByOpen(dropdownType === 'sort' ? !isSortByOpen : false);
  };

  const purchaseByOptions = {
    [DealsPurchaseByOption.All]: 'Gold/Coins',
    [DealsPurchaseByOption.Gold]: 'Gold',
    [DealsPurchaseByOption.Coins]: 'Coins',
  };

  const filterByOptions = {
    [DealFilterByOption.All]: 'All',
    [DealFilterByOption.Active]: 'Active',
    [DealFilterByOption.NotLiveYet]: 'Not Live Yet',
    [DealFilterByOption.SoldOut]: 'Sold Out',
  };

  const sortByOptions = {
    [DealSortByOption.Priority]: 'Priority',
    [DealSortByOption.Created]: 'Latest',
    [DealSortByOption.GoldCostDesc]: (
      <>
        Gold <ArrowDownIcon className="inline-block h-4 w-4" />
      </>
    ),
    [DealSortByOption.GoldCostAsc]: (
      <>
        Gold <ArrowUpIcon className="inline-block h-4 w-4" />
      </>
    ),
    [DealSortByOption.CoinsCostDesc]: (
      <>
        Coins <ArrowDownIcon className="inline-block h-4 w-4" />
      </>
    ),
    [DealSortByOption.CoinsCostAsc]: (
      <>
        Coins <ArrowUpIcon className="inline-block h-4 w-4" />
      </>
    ),
  };

  return (
    <div className="container mx-auto px-2 md:px-4">
      {dealsLoading ? (
        <SkeletonLoader height={600} />
      ) : (
        <div className="space-y-4">
          {!enabledGold && (
            <>
              <div className="flex items-start flex-wrap">
                {dealsTags.map((tag: any) => (
                  <div
                    key={tag.id}
                    className="h-15 cursor-pointer text-xs m-2 py-2 px-4 border-1 bg-opacity-10 border-black dark:border-gray-text rounded-full whitespace-nowrap"
                    onClick={() => navigateToDealDetails(tag.id)}
                  >
                    <div className="text-black dark:text-gray-text text-sm">
                      {tag.name}
                    </div>
                  </div>
                ))}
              </div>
              <DealSearchBar />
            </>
          )}

          {enabledGold && (
            <div className="flex items-center mb-4 space-x-4 py-2">
              {/* Purchase By Dropdown */}
              <div className="relative">
                <button
                  onClick={() => handleDropdownClick('purchase')}
                  className="px-4 py-2 text-sm rounded-lg border border-gray-300 dark:border-gray-600 flex items-center space-x-2"
                >
                  <AdjustmentsVerticalIcon className="h-5 w-5 text-gray-500" />
                  <span>{purchaseByOptions[purchaseBy]}</span>
                </button>
                {isPurchaseByOpen && (
                  <div className="absolute z-10 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-300 dark:border-gray-600">
                    <ul className="py-2 text-sm">
                      {Object.entries(purchaseByOptions).map(([key, label]) => (
                        <li
                          key={key}
                          onClick={() => {
                            setPurchaseBy(key as DealsPurchaseByOption);
                            setIsPurchaseByOpen(false);
                          }}
                          className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                        >
                          {label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {/* Filter By Dropdown */}
              <div className="relative">
                <button
                  onClick={() => handleDropdownClick('filter')}
                  className="px-4 py-2 text-sm rounded-lg border border-gray-300 dark:border-gray-600 flex items-center space-x-2"
                >
                  <FunnelIcon className="h-5 w-5 text-gray-500" />
                  <span>{filterByOptions[filterBy]}</span>
                </button>
                {isFilterByOpen && (
                  <div className="absolute z-10 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-300 dark:border-gray-600">
                    <ul className="py-2 text-sm">
                      {Object.entries(filterByOptions).map(([key, label]) => (
                        <li
                          key={key}
                          onClick={() => {
                            setFilterBy(key as DealFilterByOption);
                            setIsFilterByOpen(false);
                          }}
                          className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                        >
                          {label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {/* Sort By Dropdown */}
              <div className="relative">
                <button
                  onClick={() => handleDropdownClick('sort')}
                  className="px-4 py-2 text-sm rounded-lg border border-gray-300 dark:border-gray-600 flex items-center space-x-2"
                >
                  <ArrowsUpDownIcon className="h-5 w-5 text-gray-500" />
                  <span>{sortByOptions[sortBy]}</span>
                </button>
                {isSortByOpen && (
                  <div className="absolute z-10 mt-2 w-48 rounded-lg shadow-lg  bg-white dark:bg-gray-800  border border-gray-300 dark:border-gray-600">
                    <ul className="py-2 text-sm">
                      {Object.entries(sortByOptions).map(([key, label]) => (
                        <li
                          key={key}
                          onClick={() => {
                            setSortBy(key as DealSortByOption);
                            setIsSortByOpen(false);
                          }}
                          className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                        >
                          {label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}

          {!enabledGold ? (
            <div className="space-y-4">
              <DealsRow
                deals={popularDeals}
                title="Popular Deals"
                dealCategory="popular"
              />
              <DealsRow
                deals={vantaDeals}
                title="Vanta Drops"
                dealCategory="vanta"
              />
              <DealsRow
                deals={premiumDeals}
                title="Gold & Platinum Only"
                dealCategory="premium"
              />
              <DealsRow
                deals={giveawayDeals}
                title="Giveaways"
                dealCategory="giveaways"
              />
              <DealsRow
                deals={latestDeals}
                title="Latest Deals"
                dealCategory="latest"
              />
              <DealsRow
                deals={userAllDeals}
                title="All Deals"
                dealCategory="all"
              />
            </div>
          ) : (
            <div>
              <DealsRowV2 deals={deals} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
