export default function LearnTab({ tabs, selected, onClick }) {
  const tabKeys = Object.keys(tabs);
  return (
    <div className="flex w-fit p-1 h-max space-x-0 md:space-x-4 text-gray-text dark:border-bg bg-toggle-bg dark:bg-deals-card rounded-xl font-semibold">
      {tabKeys.map(key => (
        <div
          className={`
              ${
                selected === key &&
                'bg-white dark:bg-black shadow-tab-shadow text-price-green'
              } text-xs leading-15 rounded-xl cursor-pointer font-bold py-2 md:py-4 px-4 md:px-8 flex justify-center items-center`}
          onClick={() => onClick(key)}
          key={key}
        >
          {tabs[key]}
        </div>
      ))}
    </div>
  );
}
