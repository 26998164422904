import { axiosAssetDashApi } from './api';

export function getCourse(courseId?: string) {
  const params = {
    ...(courseId && { course_id: courseId }),
  };
  return axiosAssetDashApi.get('api/api_v3/learn/course', {
    params,
  });
}

export const getCourseTags = () => {
  return axiosAssetDashApi.get('api/api_v3/learn/course-tags');
};

export const postGenerateUrl = payload => {
  return axiosAssetDashApi.get('api/api_v3/learn/course/generate-share-url', {
    params: payload,
  });
};

export const getCourses = payload => {
  return axiosAssetDashApi.get(
    `api/api_v3/learn/courses${payload}`,
    // , {
    //   params: payload,
    // }
  );
};

export const getLearnCenterData = () => {
  return axiosAssetDashApi.get('api/api_v3/users/learn-center');
};

export const getCourseDetails = payload => {
  return axiosAssetDashApi.get('api/api_v3/learn/course', {
    params: payload,
  });
};

export const postCourseStart = payload => {
  return axiosAssetDashApi.post('api/api_v3/learn/course/start', payload);
};

export const postSubmitAnswer = payload => {
  return axiosAssetDashApi.post(
    'api/api_v3/learn/course/answer-question',
    payload,
  );
};

export const getStartedCourses = payload => {
  return axiosAssetDashApi.get('api/api_v3/learn/courses-started', {
    params: payload,
  });
};

export const getCompletedCourses = payload => {
  return axiosAssetDashApi.get('api/api_v3/learn/courses-completed', {
    params: payload,
  });
};

export const getMyShares = payload => {
  return axiosAssetDashApi.get('api/api_v3/learn/my-shares');
};
