import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTokenRewards } from '@/hooks/useTokenRewardsCenter';
import { formatPriceNumber } from '@/utils/number';

export const JackpotCard: React.FC = () => {
  const navigate = useNavigate();
  const { loadTokenRewards, swapJackpot, tokenRewardCenter } =
    useTokenRewards();

  useEffect(() => {
    const fetchData = async () => {
      await loadTokenRewards();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const jackpotAmount = swapJackpot?.jackpot_amount || 0;
  const my_entries = tokenRewardCenter?.jackpot_entries || 0;

  return (
    <div className="card p-4 rounded-lg shadow">
      <div className="flex justify-between items-center">
        <div className="flex flex-row items-center space-x-2">
          <img
            src="assets/images/swap_jackpot_top.png"
            alt="Gold"
            className="w-6 h-auto"
          />
          <h2 className="text-lg font-bold ">Swap Jackpot</h2>
        </div>
        <button
          onClick={() => navigate('/jackpot')}
          className="text-primary font-bold"
        >
          View All
        </button>
      </div>
      <p className="text-sm mt-2">
        Earn entries to the weekly Jackpot every time you trade on AssetDash.
      </p>
      <div className="mt-4">
        <div className="flex items-center justify-between">
          <img
            src="assets/images/swap_jackpot_top.png"
            alt="Gold"
            className="w-12 h-auto"
          />
          <span className="text-xl font-semibold">
            ${formatPriceNumber(jackpotAmount)}
          </span>
        </div>
        <div className="flex items-center justify-between mt-4">
          <img
            src="assets/images/swap_jackpot_jewel_2.png"
            alt="Gold"
            className="w-12 h-auto"
          />
          <span className="text-xl font-semibold">
            {`${my_entries} ${my_entries === 1 ? 'Entry' : 'Entries'}`}
          </span>
        </div>
      </div>
    </div>
  );
};
