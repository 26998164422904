import { Giveaway } from '@/api/games';

interface GiveawayInfoCardProps {
  giveaway: Giveaway;
  giveawayPassesCount: number;
}

export const GiveawayInfoCard: React.FC<GiveawayInfoCardProps> = ({
  giveaway,
  giveawayPassesCount,
}) => {
  // Calculate the chance of winning
  const chanceOfWinning =
    giveaway.total_passes > 0
      ? (giveawayPassesCount / giveaway.total_passes) * 100
      : 0;

  return (
    <div className="space-y-4 bg-blue-gradient dark:bg-dark-blue-gradient text-black dark:text-gray-300 mt-8 py-4 px-8 rounded-xl relative">
      <img
        src="/assets/icons/giveaway_zap.png"
        alt="instructions"
        className="h-16 w-16 p-2 rounded-full mx-auto absolute -top-8 left-1/2 transform -translate-x-1/2 bg-deals-card-top"
      />
      <p className="text-center text-2xl font-semibold">{giveaway.title}</p>
      <div className="space-y-2 pb-4">
        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <img src="/assets/icons/coin.png" alt="coin" className="h-4 w-4" />
            <p className="font-semibold">Price</p>
          </div>
          <p className="text-left">
            {giveaway.entry_cost_coins} coins per pass
          </p>
        </div>
        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <img
              src="/assets/icons/ticket.png"
              alt="ticket"
              className="h-4 w-4"
            />
            <p className="font-semibold">Limit</p>
          </div>
          <p className="text-left">
            {giveaway.entry_limit
              ? `${giveaway.entry_limit} passes`
              : 'Unlimited'}
          </p>
        </div>
        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <img
              src="/assets/icons/trophy.png"
              alt="trophy"
              className="h-4 w-4"
            />
            <p className="font-semibold">Total Passes</p>
          </div>
          <p className="text-left">{giveaway.total_passes} passes</p>
        </div>
        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <img
              src="/assets/icons/avatar_placeholder.png"
              alt="avatar_placeholder"
              className="h-4 w-4"
            />
            <p className="font-semibold">Your passes</p>
          </div>
          <p className="text-left">
            {giveawayPassesCount}{' '}
            {giveawayPassesCount === 1 ? 'pass' : 'passes'}
          </p>
        </div>
        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <img
              src="/assets/icons/avatar_placeholder.png"
              alt="avatar_placeholder"
              className="h-4 w-4"
            />
            <p className="font-semibold">Your chance of winning</p>
          </div>
          <p className="text-left">{chanceOfWinning.toFixed(2)}%</p>
        </div>
      </div>
    </div>
  );
};
