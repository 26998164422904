import moment from 'moment';

import { GoldDistributionJson } from '../../../api/gold';

class GoldDistributionsResponse {
  goldDistributions?: GoldDistribution[];

  constructor(goldDistributions?: GoldDistribution[]) {
    this.goldDistributions = goldDistributions;
  }

  static fromJson(json: {
    gold_distributions: GoldDistributionJson[];
  }): GoldDistributionsResponse {
    const goldDistributions: GoldDistribution[] = [];

    if (json.gold_distributions !== null) {
      for (const v of json.gold_distributions) {
        goldDistributions.push(GoldDistribution.fromJson(v));
      }
    }

    return new GoldDistributionsResponse(goldDistributions);
  }

  toJson(): { gold_distributions?: GoldDistributionJson[] } {
    if (this.goldDistributions) {
      return {
        gold_distributions: this.goldDistributions.map(v => v.toJson()),
      };
    }

    return {};
  }
}

class GoldDistribution {
  constructor(
    public created: Date,
    public distributionType: string,
    public distributionDescription: string,
    public goldMultiplier: number,
    public goldMultiplierOriginal: number,
    public goldOriginal: number,
    public goldTotal: number,
    public unlockDate: Date,
    public lockStatus: string,
  ) {}

  static fromJson(json: GoldDistributionJson): GoldDistribution {
    return new GoldDistribution(
      new Date(json.created),
      json.distribution_type,
      json.distribution_description,
      json.gold_multiplier,
      json.gold_multiplier_original ?? json.gold_multiplier,
      json.gold_original,
      json.gold_total,
      new Date(json.unlock_date),
      json.lock_status,
    );
  }

  toJson(): GoldDistributionJson {
    return {
      created: this.created.toISOString(),
      distribution_type: this.distributionType,
      distribution_description: this.distributionDescription,
      gold_multiplier: this.goldMultiplier,
      gold_multiplier_original: this.goldMultiplierOriginal,
      gold_original: this.goldOriginal,
      gold_total: this.goldTotal,
      unlock_date: this.unlockDate.toISOString(),
      lock_status: this.lockStatus,
    };
  }

  getCreatedDay(): string {
    return moment(this.created).format('MMM DD, yyyy');
  }

  getUnlockDay(): string {
    return moment(this.unlockDate).format('MMM DD, yyyy');
  }

  isLocked(): boolean {
    return this.lockStatus !== 'unlocked';
  }

  getLockDateDisplay(): string {
    const numDays = Math.round(
      (this.unlockDate.getTime() - new Date().getTime()) /
        (1000 * 60 * 60 * 24),
    );
    if (this.lockStatus === 'unlocked') return 'Unlocked';
    if (numDays === 0) return 'Today';
    if (numDays === 1) return `${numDays} day`;
    return `${numDays} days`;
  }
}

export { GoldDistribution, GoldDistributionsResponse };
