import { createContext, useContext, useState } from 'react';

// api
import {
  getUserDeals,
  getDealAssets,
  getDealTags,
  getUserPremiumDeals,
  getUserVantaDeals,
  getUserDealCoupon,
  getUserPurchases,
  getUserDealsCenter,
  getUserDealDetails,
  getDealList,
  getUserDealPrizes,
} from '../api/deals';
import {
  DealFilterByOption,
  DealSortByOption,
  DealsPurchaseByOption,
} from 'src/constants/deals';

export const DealContext = createContext({} as any);

export function useDeals() {
  return useContext(DealContext);
}

export const DealsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [dealsAssets, setDealsAssets] = useState<any>({});
  const [dealsTags, setDealsTags] = useState<any>([]);
  const [premiumDeals, setPremiumDeals] = useState<any>([]);
  const [vantaDeals, setVantaDeals] = useState<any>([]);
  const [popularDeals, setPopularDeals] = useState<any>([]);
  const [giveawayDeals, setGiveawayDeals] = useState<any>([]);
  const [latestDeals, setLatestDeals] = useState<any>([]);
  const [coupons, setCoupons] = useState<any>([]);
  const [userAllDeals, setUserAllDeals] = useState<any>([]);
  const [userDeals, setUserDeals] = useState<any>([]);
  const [userPurchases, setUserPurchases] = useState<any>([]);
  const [userCashBack, setUserCashBack] = useState<any>([]);
  const [userDealDetail, setUserDealDetail] = useState<any>({});
  const [userDealPrizes, setUserDealPrizes] = useState<any>([]);
  const [userDealDetailsLoading, setUserDealDetailsLoading] =
    useState<any>(false);
  const [generatedCoupons, setGeneratedCoupons] = useState<any>({} as any);
  const [dealsLoading, setDealsLoading] = useState(false);

  //api_v5
  const [deals, setDeals] = useState<any>([]);

  const loadUserDeals = async () => {
    await Promise.all([loadUserDealsAssets(), loadUserFilterTags()]);

    try {
      await Promise.all([loadAllUserPremiumDeals(), loadAllUserVantaDeals()]);

      const [popularDealsResponse, giveawayDealsResponse, latestDealsResponse] =
        await Promise.all([
          getUserDeals({ tag: 'popular', limit: 40 }),
          getUserDeals({ tag: 'giveaway', limit: 40 }),
          getUserDeals({ limit: 40, orderKey: 'latest' }),
        ]);

      setPopularDeals(popularDealsResponse.data.deals || []);
      setGiveawayDeals(giveawayDealsResponse.data.deals || []);
      setLatestDeals(latestDealsResponse.data.deals || []);
    } catch (error) {
      // Handle the error gracefully
      console.error(error);
    }
  };

  const loadUserDealsCategories = async (tag: string) => {
    try {
      const response = await getUserDeals({ tag });
      setUserDeals(response.data.deals);
    } catch (error) {}
  };

  const loadUserDealsAssets = async () => {
    try {
      const response = await getDealAssets();
      setDealsAssets(response.data.assets);
    } catch (error) {}
  };

  const loadUserFilterTags = async () => {
    try {
      const response = await getDealTags();
      setDealsTags(response.data.tags);
    } catch (error) {}
  };

  const loadAllUserPremiumDeals = async () => {
    try {
      const response = await getUserPremiumDeals({ limit: 40 });
      setPremiumDeals(response.data.deals || []);
    } catch (error) {}
  };

  const loadAllUserVantaDeals = async () => {
    try {
      const response = await getUserVantaDeals({ limit: 40 });
      setVantaDeals(response.data.deals || []);
    } catch (error) {}
  };

  const loadUserCoupons = async () => {
    try {
      const response = await getUserDealCoupon();
      setCoupons(response.data.coupons || []);
    } catch (error) {}
  };

  const loadAllUserDeals = async () => {
    try {
      const response = await getUserDeals();
      setUserAllDeals(response.data.deals || []);
    } catch (error) {}
  };

  const loadUserOrders = async () => {
    try {
      const response = await getUserPurchases();
      setUserPurchases(response.data.purchases || []);
    } catch (error) {}
  };

  const loadUserCashBacks = async () => {
    try {
      const response = await getUserDealsCenter();
      setUserCashBack(response.data.deals_center || []);
    } catch (error) {}
  };

  const loadUserDealDetails = async (id: string) => {
    setUserDealDetailsLoading(true);
    try {
      const response = await getUserDealDetails(id);
      setUserDealDetail(response.data || {});
    } catch (error) {}
    setUserDealDetailsLoading(false);
  };

  //api_v5 deals
  const loadUserDealPrizes = async () => {
    try {
      const response = await getUserDealPrizes();
      setUserDealPrizes(response.data['deal_user_prizes'] || []);
    } catch (error) {}
  };

  const loadV5Deals = async (
    purchaseByOption: DealsPurchaseByOption,
    filterByOption: DealFilterByOption,
    sortByOption: DealSortByOption,
  ) => {
    try {
      const response = await getDealList({
        tag: null,
        purchaseFilterKey: purchaseByOption,
        filterKey: filterByOption,
        orderKey: sortByOption,
        page: 1,
      });

      if (!response.data || response.data.length === 0) return;

      setDeals(response.data.deals || []);
    } catch (error: any) {}
  };

  return (
    <DealContext.Provider
      value={{
        // methods
        loadV5Deals,
        loadUserDeals,
        loadUserDealPrizes,
        loadUserCoupons,
        loadAllUserDeals,
        loadUserDealsCategories,
        loadUserOrders,
        loadUserCashBacks,
        loadUserDealDetails,
        setDealsLoading,
        setGeneratedCoupons,
        //v5
        deals,
        // values
        dealsAssets,
        dealsTags,
        premiumDeals,
        vantaDeals,
        popularDeals,
        giveawayDeals,
        latestDeals,
        coupons,
        userAllDeals,
        userDeals,
        userDealPrizes,
        userPurchases,
        userCashBack,
        userDealDetail,
        userDealDetailsLoading,
        generatedCoupons,
        dealsLoading,
      }}
    >
      {children}
    </DealContext.Provider>
  );
};
