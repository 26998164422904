// vendor libraries
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

// recoil
import { useThemeState } from '@/recoil/theme';

interface ErrorModalProps {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  description: string;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  isOpen,
  closeModal,
  title,
  description,
}) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-red-600';
  const textColor = theme === 'light' ? 'text-red-600' : 'text-white';
  const descriptionColor = theme === 'light' ? 'text-red-800' : 'text-red-200';

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm md:max-w-md sm:w-full sm:p-6 ${background}`}
              >
                <div className={textColor}>
                  <div className="space-y-2">
                    <Dialog.Title
                      as="h3"
                      className={`text-xl font-semibold leading-6 text-left flex justify-between items-center space-x-4`}
                    >
                      <div className="text-xl font-semibold text-center">
                        {title}
                      </div>
                      <div className="flex justify-center items-center">
                        <button onClick={closeModal}>
                          <XMarkIcon className="text-white h-6 w-6" />
                        </button>
                      </div>
                    </Dialog.Title>

                    <div className={descriptionColor}>{description}</div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
