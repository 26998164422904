// vendor libraries
import { useMemo, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

// style
import './App.css';

// recoil
import { useThemeState } from './recoil/theme.ts';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  SolflareWalletAdapter,
  PhantomWalletAdapter,
  LedgerWalletAdapter,
  TorusWalletAdapter,
  SolletWalletAdapter,
  SolletExtensionWalletAdapter,
  BraveWalletAdapter,
  CoinbaseWalletAdapter,
  SlopeWalletAdapter,
  GlowWalletAdapter,
  Coin98WalletAdapter,
  NightlyWalletAdapter,
  BackpackWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { MetaMaskProvider } from '@metamask/sdk-react';

// pages
// auth pages
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage.tsx';
import ResetPasswordPage from './pages/auth/ResetPasswordPage.tsx';
import TermsOfServicePage from './pages/TermsOfServicePage.tsx';

// wallets
import WalletsPage from './pages/WalletsPage.tsx';

// learn
import LearnPage from './pages/LearnPage.tsx';
import LearnCourseDetails from './pages/LearnCourseDetails.tsx';
import LearnCourseStart from './pages/LearnCourseStart.tsx';

import CoinCenterPage from './pages/CoinCenterPage.tsx';

// Home
import OnboardPage from './pages/OnboardPage.tsx';

// reward pages
import RewardsPage from './pages/RewardsPage.tsx';
import RewardDetailsPage from './pages/RewardDetailsPage.tsx';
// member pages
import MemberCenterPage from './pages/MemberCenterPage.tsx';
import MyRewardsPage from './pages/MyRewardsPage.tsx';
import GiveawayPage from './pages/GiveawayPage.tsx';
import GiveawayDetailPage from './pages/GiveawayDetailsPage.tsx';
// portfolio pages
import PortfolioPage from './pages/portfolio/PortfolioPage.tsx';
import PortfolioHoldingsPage from './pages/portfolio/PortfolioHoldingsPage.tsx';
import PortfolioNFTGalleryPage from './pages/portfolio/PortfolioNFTGalleryPage.tsx';
import PortfolioNFTCollectionPage from './pages/portfolio/PortfolioNFTCollectionPage.tsx';
import PortfolioActivityPage from './pages/portfolio/PortfolioActivityPage.tsx';
import NFTItemPage from './pages/portfolio/NFTItemPage.tsx';
import AssetDetailsPage from './pages/portfolio/AssetDetailsPage.tsx';
// expedition pages
import ExpeditionsPage from './pages/expeditions/ExpeditionsPage.tsx';
import AssetdashExpeditionDetailPage from './pages/expeditions/AssetdashExpeditionDetailPage.tsx';
import PartnerExpeditionDetailPage from './pages/expeditions/PartnerExpeditionDetailPage.tsx';
import AssetdashExpeditionTagPage from './pages/expeditions/AssetdashExpeditionTagPage.tsx';
import PartnerExpeditionTagPage from './pages/expeditions/PartnerExpeditionTagPage.tsx';

// Games
import GamesPage from './pages/games/GamesPage.tsx';
import GamesTagPage from './pages/games/GamesTagPage.tsx';
import GamesDetailsPage from './pages/games/GamesDetailsPage.tsx';
import SuperGiveawayDetailsPage from './pages/games/SuperGiveawayDetailsPage.tsx';
import ArcadeGamesTagPage from './pages/games/ArcadeGamesTagPage.tsx';
import ArcadeGameDetailsPage from './pages/games/ArcadeGamesDetailsPage.tsx';

// Market
import MarketPage from './pages/market/MarketPage.tsx';
import AuctionDetailsPage from './pages/market/AuctionDetailsPage.tsx';
import DealCategoryPage from './pages/market/DealCategoryPage.tsx';
import DealTagPage from './pages/market/DealTagPage.tsx';
import DealDetailsPage from './pages/market/DealDetailsPage.tsx';

import InboxPage from './pages/InboxPage.tsx';
import InboxMessageDetailsPage from './pages/InboxMessageDetailsPage.tsx';

// Settings
import SyncCenterPage from './pages/settings/SyncCenterPage.tsx';
import AccountSettingsPage from './pages/settings/AccountSettingsPage.tsx';
import HiddenAssetsPage from './pages/settings/HiddenAssetsPage.tsx';
import NotificationSettingsPage from './pages/settings/NotificationSettingsPage.tsx';
import InviteCenterPage from './pages/settings/InviteCenterPage.tsx';
import InviteCodePage from './pages/settings/InviteCodePage.tsx';
import LegalPage from './pages/settings/LegalPage.tsx';
import FAQPage from './pages/settings/FAQPage.tsx';

// providers
import { AppContextProvider } from './app/providers';

// layout
import { Layout } from './components/common/Layout/Layout';
import { ProtectedRoute } from './components/common/ProtectedRoute';

// analytics
import ReactGA from 'react-ga4';
import { PartnerRewardsPage } from './pages/PartnerRewardsPage.tsx';
import { TokenRewardPage } from './pages/TokenRewardPage.tsx';
import ElementsPage from './pages/ElementsPage.tsx';
import GoldCenterPage from './pages/GoldCenterPage.tsx';
import JackpotCenterPage from './pages/JackpotCenterPage.tsx';
import SwapRewardsPage from './pages/SwapRewardsPage.tsx';
import SwapJackpotPage from './pages/SwapJackpotPage.tsx';

let analytics = require('react-segment');

function App() {
  const [theme] = useThemeState();

  const navigate = useNavigate();

  const RedirectToExternal = ({ url }: { url: string }) => {
    useEffect(() => {
      if (localStorage.getItem('token')) {
        navigate('/');
      } else {
        window.location.href = url;
      }
    }, [url]);

    return null; // Render nothing
  };

  analytics.default.load(process.env.REACT_APP_SEGMENT_ID);
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string);

  ReactGA.event({
    category: 'App Actions',
    action: 'App Start',
    label: 'Start', // optional
    value: 1, // optional, must be a number
    nonInteraction: true, // optional, true/false
  });

  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = [
    new SolflareWalletAdapter(),
    new PhantomWalletAdapter(),
    new LedgerWalletAdapter(),
    new TorusWalletAdapter(),
    new SolletWalletAdapter({ network }),
    new SolletExtensionWalletAdapter(),
    new BraveWalletAdapter(),
    new CoinbaseWalletAdapter(),
    new SlopeWalletAdapter(),
    new GlowWalletAdapter(),
    new Coin98WalletAdapter(),
    new NightlyWalletAdapter(),
    new BackpackWalletAdapter(),
  ];

  return (
    <ConnectionProvider endpoint={endpoint}>
      <MetaMaskProvider
        debug={false}
        sdkOptions={{
          checkInstallationImmediately: false,
          dappMetadata: {
            name: 'Asset Dash',
            url: window.location.host,
          },
        }}
      >
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <AppContextProvider>
              <div className={theme}>
                <Layout>
                  <Toaster position="top-right" reverseOrder={true} />
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <ProtectedRoute>
                          <PortfolioPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/login"
                      element={
                        <RedirectToExternal
                          url={`${process.env.REACT_APP_AUTH_URL}/login`}
                        />
                      }
                    />
                    <Route
                      path="/sign-up"
                      element={
                        <RedirectToExternal
                          url={`${process.env.REACT_APP_AUTH_URL}/signup`}
                        />
                      }
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordPage />}
                    />
                    <Route
                      path="/reset-password/:id"
                      element={<ResetPasswordPage />}
                    />
                    <Route
                      path="/terms-of-service"
                      element={<TermsOfServicePage />}
                    />
                    <Route
                      path="/onboard"
                      element={
                        <ProtectedRoute>
                          <OnboardPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/portfolio"
                      element={
                        <ProtectedRoute>
                          <PortfolioPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/portfolio/:holdingTypeGroup"
                      element={
                        <ProtectedRoute>
                          <PortfolioHoldingsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/portfolio/nft-gallery"
                      element={
                        <ProtectedRoute>
                          <PortfolioNFTGalleryPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/portfolio/nft-activity"
                      element={
                        <ProtectedRoute>
                          <PortfolioActivityPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/portfolio/nft-collections/:assetId"
                      element={
                        <ProtectedRoute>
                          <PortfolioNFTCollectionPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/member"
                      element={
                        <ProtectedRoute>
                          <MemberCenterPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/elements"
                      element={
                        <ProtectedRoute>
                          <ElementsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/market"
                      element={
                        <ProtectedRoute>
                          <MarketPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/deals"
                      element={
                        <ProtectedRoute>
                          <Navigate to="/market" />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/auctions/:id"
                      element={
                        <ProtectedRoute>
                          <AuctionDetailsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/deals/category/:category"
                      element={
                        <ProtectedRoute>
                          <DealCategoryPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/deals/tag/:tagId"
                      element={
                        <ProtectedRoute>
                          <DealTagPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/deals/:id"
                      element={
                        <ProtectedRoute>
                          <DealDetailsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/my-rewards"
                      element={
                        <ProtectedRoute>
                          <MyRewardsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/giveaways"
                      element={
                        <ProtectedRoute>
                          <GiveawayPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/learn"
                      element={
                        <ProtectedRoute>
                          <LearnPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/learn/:id"
                      element={
                        <ProtectedRoute>
                          <LearnCourseDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/learn/:id/start"
                      element={
                        <ProtectedRoute>
                          <LearnCourseStart />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/giveaways/:id"
                      element={
                        <ProtectedRoute>
                          <GiveawayDetailPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/assets/:assetId"
                      element={
                        <ProtectedRoute>
                          <AssetDetailsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/nfts/:nftItemId"
                      element={
                        <ProtectedRoute>
                          <NFTItemPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/wallets/:walletId?"
                      element={
                        <ProtectedRoute>
                          <WalletsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/coins"
                      element={
                        <ProtectedRoute>
                          <CoinCenterPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/jackpot"
                      element={
                        <ProtectedRoute>
                          <JackpotCenterPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/gold"
                      element={
                        <ProtectedRoute>
                          <GoldCenterPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/games"
                      element={
                        <ProtectedRoute>
                          <GamesPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/expeditions"
                      element={
                        <ProtectedRoute>
                          <ExpeditionsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/expeditions/assetdash/tag/:tagId"
                      element={
                        <ProtectedRoute>
                          <AssetdashExpeditionTagPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/expeditions/partner/tag/:tagId"
                      element={
                        <ProtectedRoute>
                          <PartnerExpeditionTagPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/expeditions/partner/:id"
                      element={
                        <ProtectedRoute>
                          <PartnerExpeditionDetailPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/expeditions/assetdash/:id"
                      element={
                        <ProtectedRoute>
                          <AssetdashExpeditionDetailPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/games/ecosystem/tag/:tagId"
                      element={
                        <ProtectedRoute>
                          <GamesTagPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/games/ecosystem/:gameId"
                      element={
                        <ProtectedRoute>
                          <GamesDetailsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/games/arcade/tag/:tagId"
                      element={
                        <ProtectedRoute>
                          <ArcadeGamesTagPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/games/arcade/:arcadeGameId"
                      element={
                        <ProtectedRoute>
                          <ArcadeGameDetailsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/games/super-drops/:giveawayId"
                      element={
                        <ProtectedRoute>
                          <SuperGiveawayDetailsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/rewards"
                      element={
                        <ProtectedRoute>
                          <RewardsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/swap-rewards"
                      element={
                        <ProtectedRoute>
                          <SwapRewardsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/swap-jackpot"
                      element={
                        <ProtectedRoute>
                          <SwapJackpotPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/rewards/partner"
                      element={
                        <ProtectedRoute>
                          <PartnerRewardsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/rewards/token"
                      element={
                        <ProtectedRoute>
                          <TokenRewardPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/rewards/:partnerRewardId"
                      element={
                        <ProtectedRoute>
                          <RewardDetailsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/inbox"
                      element={
                        <ProtectedRoute>
                          <InboxPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/inbox/:messageId"
                      element={
                        <ProtectedRoute>
                          <InboxMessageDetailsPage />
                        </ProtectedRoute>
                      }
                    />

                    {/* settings routes */}
                    <Route
                      path="/sync"
                      element={
                        <ProtectedRoute>
                          <SyncCenterPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/account"
                      element={
                        <ProtectedRoute>
                          <AccountSettingsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/hidden-assets"
                      element={
                        <ProtectedRoute>
                          <HiddenAssetsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/notifications"
                      element={
                        <ProtectedRoute>
                          <NotificationSettingsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/invite"
                      element={
                        <ProtectedRoute>
                          <InviteCenterPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/invite-code"
                      element={
                        <ProtectedRoute>
                          <InviteCodePage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/legal"
                      element={
                        <ProtectedRoute>
                          <LegalPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/faq"
                      element={
                        <ProtectedRoute>
                          <FAQPage />
                        </ProtectedRoute>
                      }
                    />
                    {/* default route */}
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Layout>
              </div>
            </AppContextProvider>
          </WalletModalProvider>
        </WalletProvider>
      </MetaMaskProvider>
    </ConnectionProvider>
  );
}

export default App;
