import React from 'react';
interface DealDescriptionProps {
  deal: {
    description: string;
    website_url?: string;
    twitter_url?: string;
    discord_url?: string;
  };
}

export const DealDescription: React.FC<DealDescriptionProps> = ({ deal }) => {
  const handleLinkClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="card p-6 rounded-13">
      <div className="flex justify-between items-center">
        <h3 className="text-18 font-semibold">Description</h3>
        <div className="flex space-x-4">
          {/* Website Link */}
          {deal.website_url && (
            <button
              onClick={() => handleLinkClick(deal.website_url!)}
              className="focus:outline-none"
            >
              <img
                src={'/assets/svg/assets/website.svg'}
                className="w-6"
                alt="website icon"
              />
            </button>
          )}

          {deal.twitter_url && (
            <button
              onClick={() => handleLinkClick(deal.twitter_url!)}
              className="focus:outline-none"
            >
              <img
                src={'/assets/svg/assets/twitter.svg'}
                className="w-6"
                alt="twitter icon"
              />
            </button>
          )}

          {deal.discord_url && (
            <button
              onClick={() => handleLinkClick(deal.discord_url!)}
              className="focus:outline-none"
            >
              <img
                src={'/assets/svg/assets/discord.svg'}
                className="w-6"
                alt="discord icon"
              />
            </button>
          )}
        </div>
      </div>

      <div className="mt-4">
        <p className="text-gray-text leading-5">
          {deal.description || 'No description available.'}
        </p>
      </div>
    </div>
  );
};
