export const CheckCircle = () => {
  return (
    <>
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8259_31255)">
          <path
            d="M15.3269 8.37197V9.00002C15.3261 10.4721 14.8494 11.9046 13.968 13.0836C13.0865 14.2627 11.8476 15.1252 10.4359 15.5426C9.02417 15.96 7.51535 15.9099 6.13446 15.3998C4.75356 14.8896 3.57458 13.9467 2.77334 12.7117C1.97209 11.4768 1.59152 10.0159 1.68838 8.54694C1.78524 7.07801 2.35435 5.67975 3.31081 4.56068C4.26728 3.44161 5.55987 2.66171 6.99579 2.33728C8.43172 2.01285 9.93405 2.16128 11.2787 2.76044"
            stroke="#21CE99"
            strokeWidth="1.36534"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3268 3.5387L8.50015 10.3722L6.45215 8.3242"
            stroke="#21CE99"
            strokeWidth="1.36534"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_8259_31255">
            <rect
              width="16.384"
              height="16.384"
              fill="white"
              transform="translate(0.308105 0.807983)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
