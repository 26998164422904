import React from 'react';
import { useNavigate } from 'react-router-dom';

export const SwapJackpotContainer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen">
      <header className="bg-light dark:bg-dark-card p-4 shadow-md">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold">How it works</h1>
          <button
            className="text-primary hover:underline"
            onClick={() =>
              window.open(
                'https://chill-rook-e76.notion.site/How-it-Works-Jackpot-3d28d4d8c53f4ae9a529dd768135a626?pvs=4',
                '_blank',
              )
            }
          >
            Learn More
          </button>
        </div>
      </header>

      <main className="p-4 space-y-6">
        {/* Centering the image horizontally */}
        <div className="flex justify-center">
          <img
            src="/assets/images/giveaway.png"
            alt="Giveaway"
            className="w-full md:max-w-[400px] h-auto rounded-lg"
          />
        </div>

        <h1 className="text-xl font-semibold">About Swap Jackpot</h1>

        <section className="p-6 bg-light card rounded-lg">
          <h2 className="text-xl font-semibold mb-4">How does It Work</h2>
          <div className="space-y-4">
            {/* Step numbers in primary color */}
            <div className="text-lg">
              <span className="font-semibold text-primary">Step 1: </span>
              Every time you trade with AssetDash you earn entries to the weekly
              Swap Jackpot which features crypto prizes.
            </div>

            <div className="text-lg">
              <span className="font-semibold text-primary">Step 2: </span>
              Trading via the "Trade" section on the mobile app and
              swap.assetdash.com both earn Jackpot Entries.
            </div>

            <div className="text-lg">
              <span className="font-semibold text-primary">Step 3: </span>
              Every Friday - a lucky winner is selected for the Jackpot.
            </div>

            <div className="text-lg">
              <span className="font-semibold text-primary">Step 4: </span>
              Entries are reset and this process repeats every week.
            </div>

            <div className="text-lg">
              <span className="font-semibold text-primary">Step 5: </span>
              If you won, claim your prize in-app!
            </div>
          </div>
        </section>

        <section className="p-6 bg-light card rounded-lg">
          <h2 className="text-xl font-semibold mb-4">About Swap Rewards</h2>
          <p className="text-lg text-gray-700 dark:text-gray-400">
            Save Money. Earn Rewards. Never pay more than $1 in trading fees.
            Tap here to learn more about the benefits and rewards that come with
            trading on AssetDash.
          </p>
          <button
            className="block mt-4 text-lg font-bold text-primary hover:underline"
            onClick={() => navigate('/swap-rewards')}
          >
            Learn More
          </button>
        </section>

        <section className="p-6 bg-light card rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Jackpot Results</h2>
          <p className="text-lg text-gray-700 dark:text-gray-400">
            Tap here to see the winners from the weekly Jackpot.
          </p>
          <button
            className="block mt-4 text-lg font-bold text-primary hover:underline"
            onClick={() =>
              window.open(
                'https://chill-rook-e76.notion.site/How-it-Works-Jackpot-3d28d4d8c53f4ae9a529dd768135a626?pvs=4',
                '_blank',
              )
            }
          >
            See Results
          </button>
        </section>
      </main>
    </div>
  );
};
