import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { getUserCoinCenter } from '@/api/coin';
import {
  CoinCenter,
  CoinCenterResponse,
} from '@/features/coins/models/CoinCenter';

export const CoinStats: React.FC = () => {
  const [coinCenter, setCoinCenter] = useState<CoinCenter>();

  useEffect(() => {
    fetchCoinCenter();
  }, []);

  const fetchCoinCenter = async () => {
    try {
      const response = await getUserCoinCenter();
      setCoinCenter(CoinCenterResponse.fromJson(response.data).coinCenter);
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div className="mt-2 space-y-6">
      {/* Total Coins - Full Width */}
      <div className="w-full p-4 border card rounded-lg flex flex-col items-center">
        <img
          src="/assets/icons/total_coins.png"
          alt="Total Coins"
          className="h-auto w-8 mb-2"
        />
        <CountUp
          duration={2}
          end={coinCenter?.totalCoins || 0}
          className="text-2xl md:text-3xl font-bold"
        />
        <div className="text-lg font-semibold">Total Coins</div>
      </div>

      {/* Other Coin Stats */}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        {/* Liquid Coins */}
        <div className="p-4 border card rounded-lg flex flex-col items-center">
          <img
            src="/assets/icons/liquid_coins.png"
            alt="Liquid Coins"
            className="h-auto w-6 mb-2"
          />
          <CountUp
            duration={2}
            end={coinCenter?.liquidCoins || 0}
            className="text-xl md:text-2xl font-bold"
          />
          <div className="text-md font-semibold">Liquid Coins</div>
        </div>

        {/* Locked Coins */}
        <div className="p-4 border card rounded-lg flex flex-col items-center">
          <img
            src="/assets/icons/locked_coins.png"
            alt="Locked Coins"
            className="h-auto w-6 mb-2"
          />
          <CountUp
            duration={2}
            end={coinCenter?.lockedCoins || 0}
            className="text-xl md:text-2xl font-bold"
          />
          <div className="text-md font-semibold">Locked Coins</div>
        </div>

        {/* Bid Coins */}
        <div className="p-4 border card rounded-lg flex flex-col items-center">
          <img
            src="/assets/icons/bid_coins.png"
            alt="Bid Coins"
            className="h-auto w-6 mb-2"
          />
          <CountUp
            duration={2}
            end={coinCenter?.bidCoins || 0}
            className="text-xl md:text-2xl font-bold"
          />
          <div className="text-md font-semibold">Bid Coins</div>
        </div>

        {/* Spent Coins */}
        <div className="p-4 border card rounded-lg flex flex-col items-center">
          <img
            src="/assets/icons/spent_coins.png"
            alt="Spent Coins"
            className="h-auto w-6 mb-2"
          />
          <CountUp
            duration={2}
            end={coinCenter?.spentCoins || 0}
            className="text-xl md:text-2xl font-bold"
          />
          <div className="text-md font-semibold">Spent Coins</div>
        </div>
      </div>
    </div>
  );
};
