import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

// hooks
import { useDealsLegacy } from '@/hooks/useDealsLegacy';
import { useMember } from '@/hooks/useMember';

// components
import GetLuckyOpeningScreen from '@/components/v2/giveaway/GetLuckyOpeningScreen';
import FortuneCookieDetails from '@/components/v2/giveaway/FortuneCookieResultModal';
import { ConnectInvestmentsModal } from '../common/Modal/ConnectInvestmentsModal';

export const FortuneCookieCard: React.FC = () => {
  const [connectInvestmentsModalOpen, setConnectInvestmentsModalOpen] =
    useState(false);
  const { memberCenterData } = useMember();
  const member_status = memberCenterData?.member_status;
  const [fortuneCookieLoading, setFortuneCookieLoading] = useState(true);

  const {
    openFortuneCookie,
    fortuneCookie,
    checkFortuneCookie,
    fetchWeeklyRaffles,
    showFortuneCookieOpenings,
    openFortuneCookieModal,
    setOpenFortuneCookieModal,
  } = useDealsLegacy();

  const handleFortuneCookie = async () => {
    if (member_status === 'standard') {
      setConnectInvestmentsModalOpen(true);
      return;
    }
    if (!fortuneCookie?.fortune_cookie)
      // if (res) {
      try {
        await openFortuneCookie();
        await fetchWeeklyRaffles();
      } catch (error) {
        toast.error(error?.response?.data?.detail || 'something');
      }
  };

  useEffect(() => {
    getCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCookie = async () => {
    await checkFortuneCookie();
    setFortuneCookieLoading(false);
  };

  return (
    <>
      <div className={`h-96 w-56 p-4 flex flex-col justify-between card`}>
        <div className="md:col-span-2 lg:col-span-2 bg-white p-1 mb-2 dark:bg-deals-card rounded-13 h-max">
          <img
            src="/assets/images/fortune-cookie.png"
            className="min-w-42 h-42 object-contain mx-auto"
            alt="logo"
          />
        </div>
        <div className="space-y-1">
          <div className="text-md leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
            Fortune Cookie
          </div>
          <div className="text-xs leading-17 text-ellipsis overflow-hidden text-gray-text h-16">
            Daily fortune cookie to earn coins!
          </div>
        </div>
        <div
          onClick={() => {
            handleFortuneCookie();
          }}
          className={`py-2 font-semibold rounded-8 text-center ${
            fortuneCookieLoading || fortuneCookie?.fortune_cookie
              ? 'bg-gray-text text-black'
              : 'bg-price-green text-input-box cursor-pointer'
          }`}
        >
          {fortuneCookieLoading || fortuneCookie?.fortune_cookie
            ? 'Already Claimed'
            : 'Open'}
        </div>
      </div>
      {showFortuneCookieOpenings && <GetLuckyOpeningScreen />}
      <FortuneCookieDetails
        open={openFortuneCookieModal}
        setOpen={setOpenFortuneCookieModal}
      />
      <ConnectInvestmentsModal
        isOpen={connectInvestmentsModalOpen}
        closeModal={() => setConnectInvestmentsModalOpen(false)}
        title="Verify to access your daily Fortune Cookie"
        description="You need to be Bronze Tier or higher to access this feature. AssetDash recommends having a verified wallet of $150 or higher to become Bronze Tier. Tap continue below to get started."
        imageLocation="/assets/images/fortune-cookie.png"
      />
    </>
  );
};
