import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  getUserTokenRewardCenter,
  getSwapJackpot,
  TokenRewardsCenter,
  SwapJackpotEntry,
  getSwapJackpotEntries,
  SwapJackpot,
} from '../api/token';
import { useAuth } from './useAuth';

interface TokenRewardsContextType {
  tokenRewardCenter?: TokenRewardsCenter;
  swapJackpot?: SwapJackpot;
  swapJackpotEntries?: SwapJackpotEntry[];
  loadTokenRewards: () => Promise<void>;
}

const TokenRewardsContext = createContext<TokenRewardsContextType | undefined>(
  undefined,
);

export const useTokenRewards = (): TokenRewardsContextType => {
  const context = useContext(TokenRewardsContext);
  if (!context) {
    throw new Error(
      'useTokenRewards must be used within a TokenRewardsProvider',
    );
  }
  return context;
};

export const TokenRewardsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tokenRewardCenter, setTokenRewardCenter] =
    useState<TokenRewardsCenter>();
  const [swapJackpot, setSwapJackpot] = useState<SwapJackpot>();
  const [swapJackpotEntries, setSwapJackpotEntries] = useState<
    SwapJackpotEntry[]
  >([]);
  const { isLoggedIn } = useAuth();

  const loadTokenRewards = useCallback(async () => {
    try {
      const centerResponse = await getUserTokenRewardCenter();
      if (centerResponse.swap_center) {
        setTokenRewardCenter(centerResponse.swap_center);
      }
      const jackpotResponse = await getSwapJackpot();
      setSwapJackpot(jackpotResponse);
      const entriesResponse = await getSwapJackpotEntries();
      setSwapJackpotEntries(entriesResponse.entries);
    } catch (error) {
      console.error('Failed to load token rewards:', error);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      loadTokenRewards();
    }
  }, [loadTokenRewards, isLoggedIn]);

  return (
    <TokenRewardsContext.Provider
      value={{
        tokenRewardCenter,
        swapJackpot,
        swapJackpotEntries,
        loadTokenRewards,
      }}
    >
      {children}
    </TokenRewardsContext.Provider>
  );
};
