export const NavLearnIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame" clipPath="url(#clip0_14879_158)">
      <g id="Group">
        <path
          id="Vector"
          d="M23.4034 16.0412V19.848C23.4034 21.2307 22.5895 22.4646 21.3293 23.0421C19.7979 23.7422 17.4789 24.5036 14.6522 24.5036C11.8256 24.5036 9.50658 23.7422 7.96639 23.0421C6.71498 22.4646 5.90112 21.2307 5.90112 19.848V16.0412L12.1232 18.8854C12.9195 19.2529 13.7771 19.4367 14.6522 19.4367C15.5274 19.4367 16.385 19.2529 17.1813 18.8854L23.4034 16.0412ZM26.9038 11.2228V20.7125C26.0754 20.7125 26.511 20.7125 26.0297 20.7125C25.5484 20.7125 26.0287 20.7125 25.1536 20.7125V12.7872L26.9038 11.2228Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M27.3198 11.5047L27.3197 11.5047L16.1438 16.6135C16.1438 16.6136 16.1437 16.6136 16.1436 16.6136C15.6751 16.8276 15.166 16.9384 14.651 16.9384C14.1359 16.9384 13.6268 16.8276 13.1583 16.6136C13.1582 16.6136 13.1582 16.6136 13.1581 16.6135L1.98292 11.505C1.61978 11.3386 1.39917 10.9952 1.39917 10.5941C1.39917 10.1932 1.61959 9.84996 1.98246 9.68345C1.98261 9.68338 1.98277 9.68331 1.98292 9.68324L13.1591 4.57644L13.1595 4.57626C13.6277 4.362 14.1365 4.2511 14.6514 4.2511C15.1663 4.2511 15.6752 4.362 16.1433 4.57626L16.1437 4.57641L27.3197 9.68444L27.3198 9.68447C27.6817 9.84984 27.9028 10.1933 27.9028 10.595C27.9028 10.9963 27.6821 11.3391 27.3198 11.5047Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_14879_158">
        <rect
          width="28.0036"
          height="28.0036"
          fill="white"
          transform="translate(0.64917)"
        />
      </clipPath>
    </defs>
  </svg>
);
