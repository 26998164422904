import { CoinRewardCard } from './CoinRewardCard';
import { GoldRewardCard } from './GoldRewardCard';
import { JackpotCard } from './JackpotCard';
import { PartnerRewardsCard } from './PartnerRewardsCard';

const enabledGold = process.env.REACT_APP_ENABLE_GOLD === 'true';
export const RewardsContainer: React.FC = () => {
  return (
    <div>
      <div className="text-2xl leading-none mb-4">Rewards</div>
      <div className="flex flex-col lg:flex-row gap-6 text-white">
        <div className="flex flex-col flex-1 gap-6">
          {enabledGold && <GoldRewardCard />}
          <JackpotCard />
          <CoinRewardCard />
          <PartnerRewardsCard />
        </div>
      </div>
    </div>
  );
};
