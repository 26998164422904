import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoldCenter } from '@/hooks/useGoldCenter';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';
import { formatNumber } from '@/utils/number';

export const GoldRewardCard: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, load, userGoldCenter } = useGoldCenter();

  useEffect(() => {
    const fetchData = async () => {
      await load();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const liquidGold = userGoldCenter?.gold_center.liquid_gold ?? 0;
  const lockedGold = userGoldCenter?.gold_center.locked_gold ?? 0;

  return (
    <div className={`card flex flex-col p-4`}>
      {isLoading ? (
        <SkeletonLoader height={150} />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <div className="flex flex-row items-center space-x-2">
              <img
                src="assets/icons/gold.png"
                alt="Gold"
                className="w-6 h-auto"
              />
              <h2 className="text-lg font-bold text-gray-900 dark:text-gray-100">
                AssetDash Gold
              </h2>
            </div>
            <button
              onClick={() => navigate('/gold')}
              className="text-primary font-bold"
            >
              View All
            </button>
          </div>
          <p className="text-sm mt-2 text-gray-700 dark:text-gray-300">
            Earn Gold every time you trade on AssetDash and spend your Gold on
            your favorite digital assets in the Gold Marketplace.
          </p>
          <div className="flex flex-col items-center mt-4">
            <div className="w-full flex flex-row justify-between">
              <img
                src="assets/icons/liquid_gold.png"
                alt="Liquid Gold"
                className="w-12 h-auto"
              />
              <div className="flex flex-row items-center space-x-2 text-[#CFB53B] dark:text-[#FFD700]">
                <div className="text-xl">{formatNumber(liquidGold)}</div>
              </div>
            </div>
            <div className="w-full flex flex-row justify-between mt-4">
              <img
                src="assets/icons/locked_gold.png"
                alt="Locked Gold"
                className="w-12 h-auto"
              />
              <div className="flex flex-row items-center space-x-2 text-[#CFB53B] dark:text-[#FFD700]">
                <div className="text-xl">{formatNumber(lockedGold)}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
