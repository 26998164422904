// models
import { SvgIcon } from '../common/Icon/SvgIcon';
import { AppColors } from '@/features/core/theming/colors';

// utils
import { formatPriceNumber } from '@/utils/number';

// recoil
import { useCurrencyVisibleState } from '@/recoil/currencyVisible';

// components
import { NFTListing } from '@/features/portfolio/models/NFTLIsting';

export const NFTListingItem: React.FC<{
  listing: NFTListing;
  showChainTag?: boolean;
}> = ({ listing, showChainTag = false }) => {
  const [currencyVisible] = useCurrencyVisibleState();

  const handleClick = () => {
    window.open(listing.nftUrl!, '_blank');
  };

  return (
    <div
      onClick={handleClick}
      className="cursor-pointer bg-nft-card-light dark:bg-dark rounded-card-box px-4 py-2 space-y-2"
    >
      <div className="flex flex-col 2xl:flex-row space-x-0 2xl:space-x-4 space-y-4 2xl:space-y-0">
        <div className="relative rounded-sm">
          <img
            className="w-full 2xl:w-32 2xl:h-32 rounded-card-box object-cover"
            src={listing.nftUrl!}
            alt="nft"
          />
          <div className="absolute top-3 left-4">
            {showChainTag && (
              <div className="text-price-green text-10 leading-17 bg-primary-gray dark:bg-tabs-bg w-max px-4p py-3p rounded-2xl mt-1">
                {listing.nftChain}
              </div>
            )}
          </div>
        </div>
        <div className="pt-2 sm:pt-0 sm:pl-2 flex flex-col justify-between">
          <div className="text-sm text-left leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
            {listing.nftName}
          </div>
          <div className="text-sm leading-21 text-start text-black dark:text-white mt-2 flex items-center space-x-1">
            <SvgIcon
              iconFileName={listing.nftChain}
              subFolder="nft"
              color={AppColors.primary()}
              size={{ height: 14, width: 14 }}
            />
            <span>
              {currencyVisible
                ? formatPriceNumber(listing.price!)
                : '****.****'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTListingItem;
