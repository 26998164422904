import { MyGiveawayUserPrize } from '@/api/games';
import { toast } from 'react-hot-toast';

interface MyPrizeGiveawayCardProps {
  prize: MyGiveawayUserPrize;
}

export const MyPrizeGiveawayCard: React.FC<MyPrizeGiveawayCardProps> = ({
  prize,
}) => {
  const copyAndOpenLink = (link: string) => {
    navigator.clipboard.writeText(link);
    toast.success('Copied to clipboard!');
    setTimeout(() => {
      window.open(link, '_blank');
    }, 2000);
  };

  const shareOnTwitter = () => {
    const message = `I just won ${prize.prize_title} in an @assetdash Super Drop 🎉\n\nJoin me on AssetDash and use my code ${prize.pass_code} at sign up to instantly get Coins to join!`;
    const url = 'https://bit.ly/3dNXbSJ';

    const baseUrl = 'https://twitter.com/intent/tweet';
    const params = new URLSearchParams({
      text: message,
      url: url,
    });

    window.open(`${baseUrl}?${params.toString()}`, '_blank');
  };

  return (
    <div
      onClick={() => {
        if (prize.prize_link) copyAndOpenLink(prize.prize_link);
      }}
      className={`card relative cursor-pointer h-96 w-56 p-4 flex flex-col justify-between`}
    >
      <div className="relative md:col-span-2 lg:col-span-2 rounded-13 h-max">
        <img
          src={prize.prize_image_url!}
          className="bg-white dark:bg-deals-card min-w-42 h-42 object-contain mx-auto"
          alt="prize"
        />
        <div className="flex flex-col items-center mt-8">
          <div className="text-lg font-bold text-center leading-tight bg-gradient-to-r from-price-green to-primary bg-clip-text text-transparent">
            {prize.prize_title}
          </div>
          <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
            Super Drop Prize
          </div>
        </div>
      </div>

      <div className="flex flex-row space-x-2 items-center justify-center">
        {prize.prize_link && (
          <button
            type="button"
            className={`inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 text-base font-medium
            focus:outline-none sm:text-sm bg-price-green text-black cursor-pointer`}
            onClick={e => {
              e.stopPropagation();
              copyAndOpenLink(prize.prize_link!);
            }}
          >
            Claim
          </button>
        )}

        <button
          type="button"
          className={`inline-flex justify-center rounded-lg border border-transparent shadow-sm px-2 py-2 text-base font-medium
          focus:outline-none sm:text-sm bg-primary cursor-pointer text-black`}
          onClick={e => {
            e.stopPropagation();
            shareOnTwitter();
          }}
        >
          Share on X
        </button>
      </div>
    </div>
  );
};
