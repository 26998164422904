import React from 'react';

export const HomeBanner: React.FC = () => {
  const handleClick = () => {
    window.location.href = '/swap-rewards';
  };

  return (
    <div className="px-4 py-2">
      <div
        className="flex items-center bg-button-gradient p-4 rounded-lg cursor-pointer shadow-md space-x-4"
        onClick={handleClick}
      >
        <img
          src="/assets/icons/wallet_check.png"
          alt="Banner Icon"
          className="w-10 h-10 ml-4"
        />
        <div className="text-gray-900 dark:text-gray-200 font-semibold">
          New! Save Money. Earn Rewards. Trade with AssetDash and never pay more
          than $1 in fees.
        </div>
      </div>
    </div>
  );
};
