export const NavWalletIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 18V7.84615C3 6.82615 3.82042 6 4.83333 6H23.1667C24.1796 6 25 6.82615 25 7.84615V18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M3 12C3 10.895 3.82042 10 4.83333 10H23.1667C24.1796 10 25 10.895 25 12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M16.6282 15.6101L16.4496 15.9899C15.9851 16.9763 15.0374 17.6 14 17.6C12.9626 17.6 12.0149 16.9763 11.5512 15.9899L11.3727 15.6101C10.9082 14.6237 9.96046 14 8.92308 14H3V19.3C3 20.7913 4.13638 22 5.53846 22H22.4615C23.8636 22 25 20.7913 25 19.3V14H19.0769C18.0395 14 17.0918 14.6237 16.6282 15.6101Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
