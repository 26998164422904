// vendor libraries
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ChevronRightIcon,
  UserPlusIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  CodeBracketIcon,
  BookOpenIcon,
  QuestionMarkCircleIcon,
  DocumentTextIcon,
  UserIcon,
  WalletIcon,
  GiftIcon,
  AcademicCapIcon,
  CircleStackIcon,
  ChatBubbleBottomCenterTextIcon,
  BellAlertIcon,
  CloudArrowDownIcon,
  Cog6ToothIcon,
  Bars3Icon,
  HomeIcon,
  SwatchIcon,
  UserCircleIcon,
  LightBulbIcon,
  MapIcon,
  PuzzlePieceIcon,
  ChatBubbleOvalLeftIcon,
  FireIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { IconButtonWrapper } from '../Button/IconButtonWrapper';
import { AuthButton } from '../Button/AuthButton';

import { useAuth } from '../../../hooks/useAuth';
import { usePortfolio } from '../../../hooks/usePortfolio';
import { useInbox } from '../../../hooks/useInbox';

// components
import { CurrencyFormatButton } from '@/components/v2/common/Button/CurrencyFormatButton';
import { CurrencyVisibilityButton } from '../Button/CurrencyVisibilityButton';
import { SyncButton } from '../Button/SyncButton';
import { ThemeButton } from '../Button/ThemeButton';

// api
import { getUserCoinCenter } from '../../../api/coin';

// models
import { CoinCenter } from '../../../features/coins/models/CoinCenter';
import { AssetDashLogo } from '@/components/v2/common/Logo/AssetDashLogo';
import { isMobile } from '@/utils/device';
import { getUserGoldCenter } from '@/api/gold';
import {
  GoldCenter,
  GoldCenterResponse,
} from '@/features/golds/models/GoldCenter';
import { useTokenRewards } from '@/hooks/useTokenRewardsCenter';
import { useTokenState } from '@/recoil/token';

interface HeaderProps {
  isOpen: boolean;
}

export const Header: React.FC<HeaderProps> = ({ isOpen }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const [token] = useTokenState();

  const { isLoggedIn, logout } = useAuth();
  const { clearData } = usePortfolio();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const { inboxCenter } = useInbox();

  const { tokenRewardCenter } = useTokenRewards();

  const [coinCenter, setCoinCenter] = useState<CoinCenter>();
  const [goldCenter, setGoldCenter] = useState<GoldCenter>();

  const menuItems = [
    {
      name: 'Portfolio',
      path: '/portfolio',
      icon: <HomeIcon className="w-5 h-5" />,
    },
    {
      name: 'Swap + Whale Watch',
      path: `${process.env.REACT_APP_SWAP_URL}?token=${token}`,
      icon: <SwatchIcon className="w-5 h-5" />,
    },
    {
      name: 'Rent Refund',
      path: `${process.env.REACT_APP_REFUND_URL}`,
      icon: <CurrencyDollarIcon className="w-5 h-5" />,
    },
    {
      name: 'Wallets',
      path: '/wallets',
      icon: <WalletIcon className="w-5 h-5" />,
    },
    {
      name: 'Member',
      path: '/member',
      icon: <UserCircleIcon className="w-5 h-5" />,
    },
    {
      name: 'Elements',
      path: '/elements',
      icon: <LightBulbIcon className="w-5 h-5" />,
    },
    {
      name: 'Market',
      path: '/market',
      icon: <GiftIcon className="w-5 h-5" />,
    },
    {
      name: 'Learn',
      path: '/learn',
      icon: <AcademicCapIcon className="w-5 h-5" />,
    },
    {
      name: 'Expeditions',
      path: '/expeditions',
      icon: <MapIcon className="w-5 h-5" />,
    },
    {
      name: 'Games',
      path: '/games',
      icon: <PuzzlePieceIcon className="w-5 h-5" />,
    },
    {
      name: 'Rewards',
      path: '/rewards',
      icon: <CloudArrowDownIcon className="w-5 h-5" />,
    },
    {
      name: 'Dashdrop',
      path: '/giveaways',
      icon: <ChatBubbleOvalLeftIcon className="w-5 h-5" />,
    },
    {
      name: 'Vanta Burn',
      path: `${process.env.REACT_APP_BURN_URL}`,
      icon: <FireIcon className="w-5 h-5" />,
    },
  ];

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserCoinCenter();
      fetchGoldCenter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const fetchUserCoinCenter = async () => {
    const response = await getUserCoinCenter();
    setCoinCenter(CoinCenter.fromJson(response.data.coin_center));
  };

  const fetchGoldCenter = async () => {
    try {
      const response = await getUserGoldCenter();
      setGoldCenter(GoldCenterResponse.fromJson(response.data).goldCenter);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node)
      ) {
        setIsDrawerOpen(false);
      }
    };

    if (isDrawerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDrawerOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div>
      <header className="bg-white dark:bg-darkest-dark fixed top-0 w-full z-10">
        <nav className="px-1 sm:px-4 lg:px-8 py-2 shadow-md" aria-label="Top">
          <div className="flex flex-row w-full items-center justify-between">
            <div className="hidden md:flex items-center space-x-5">
              {isMobile() ? (
                <img
                  src="/assets/icons/logo.png"
                  className="mx-2 w-8 h-auto"
                  alt="AssetDash"
                />
              ) : (
                <AssetDashLogo />
              )}

              <div className="hidden md:inline-block">
                <ThemeButton />
              </div>
              <div className="hidden md:inline-block md:pl-10">
                <a
                  href={`${process.env.REACT_APP_SWAP_URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border border-primary text-primary bg-transparent rounded-full px-4 py-2"
                >
                  Trade and Earn
                </a>
              </div>
            </div>
            <div className="px-2 md:p-0 md:hidden flex items-center">
              <IconButtonWrapper onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <Bars3Icon width="18" height="18" className="text-primary" />
              </IconButtonWrapper>
            </div>
            <div className="flex justify-end xl:w-1/2 mr-2 md:mr-0">
              <div className="space-x-1 md:space-x-2 flex flex-wrap justify-around md:flex-row items-center md:justify-end">
                {isLoggedIn && (
                  <>
                    <div className="hidden md:flex items-center justify-between mx-2 p-1">
                      <div onClick={() => setIsDrawerOpen(false)}>
                        <SyncButton />
                      </div>
                    </div>
                    <div className="hidden md:flex items-center justify-between mx-2 p-1">
                      <CurrencyVisibilityButton />
                    </div>
                    <div className="hidden md:flex items-center justify-between mx-2 p-1">
                      <CurrencyFormatButton />
                    </div>
                    <div
                      className="h-15 cursor-pointer flex items-center space-x-1 text-xs justify-center px-1 py-1 border-1  border-price-green rounded-full"
                      onClick={() => navigate('/rewards')}
                    >
                      <div className="flex items-center space-x-1">
                        <div className="pl-2">
                          <img
                            className="rounded-full"
                            src="/assets/icons/gold.png"
                            alt="Coin"
                            height={isMobile() ? 18 : 24}
                            width={isMobile() ? 18 : 24}
                          />
                        </div>
                        <span className="pr-2 text-price-green text-sm">
                          {goldCenter?.liquidGold || 0}
                        </span>
                      </div>
                      <div className="flex items-center space-x-1">
                        <div className="pl-2">
                          <img
                            className="rounded-full"
                            src="/assets/icons/liquid_coin.svg"
                            alt="Coin"
                            height={isMobile() ? 18 : 24}
                            width={isMobile() ? 18 : 24}
                          />
                        </div>
                        <span className="pr-2 text-price-green text-sm">
                          {coinCenter?.totalCoins || 0}
                        </span>
                      </div>
                      <div className="flex items-center space-x-1">
                        <div className="pl-2">
                          <img
                            className="rounded-full"
                            src="/assets/icons/diamond.png"
                            alt="Coin"
                            height={isMobile() ? 18 : 24}
                            width={isMobile() ? 18 : 24}
                          />
                        </div>
                        <span className="pr-2 text-price-green text-sm">
                          {tokenRewardCenter?.jackpot_entries || 0}
                        </span>
                      </div>
                    </div>
                    <div
                      className="hidden md:flex intercom-launcher py-3 cursor-pointer"
                      onClick={() => setIsDrawerOpen(false)}
                    >
                      <ChatBubbleBottomCenterTextIcon
                        width={30}
                        height={30}
                        className="text-price-green mr-2"
                      />
                    </div>
                    <IconButtonWrapper onClick={() => navigate('/inbox')}>
                      <div className="relative inline-block cursor-pointer">
                        <img
                          src="/assets/icons/icon_inbox.png"
                          alt="icon"
                          width={24}
                          height={24}
                        />
                        {(inboxCenter?.unreadMessages || 0) > 0 && (
                          <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-price-green rounded-full"></div>
                        )}
                      </div>
                    </IconButtonWrapper>
                    <IconButtonWrapper onClick={() => setIsDrawerOpen(true)}>
                      <Cog6ToothIcon
                        width="18"
                        height="18"
                        className="text-primary font-bold"
                      />
                    </IconButtonWrapper>
                  </>
                )}
                {!isLoggedIn && (
                  <>
                    <ThemeButton />
                    <AuthButton />
                  </>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* Menu */}
      <div
        ref={menuRef}
        className={`bg-gray-100 dark:bg-darkest-dark fixed top-0 left-0 h-full transform transition-transform duration-300 z-20 ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } w-full md:w-96`}
      >
        <nav className="border-b border-input-box px-4" aria-label="Top">
          <div className="flex justify-between w-full items-center py-4">
            <h1 className="text-xl">Menu</h1>
            <IconButtonWrapper onClick={() => setIsMenuOpen(false)}>
              <XMarkIcon width="16" height="16" className="text-primary" />
            </IconButtonWrapper>
          </div>
        </nav>
        <div className="space-y-2 py-5 h-full px-4">
          {menuItems.map((item, index) => {
            const isActive =
              location.pathname === item.path ||
              (location.pathname === '/' && item.path === '/portfolio');
            return (
              <div
                key={index}
                className={`flex items-center justify-between mx-2 p-2 cursor-pointer rounded-lg ${
                  isActive
                    ? 'bg-primary text-white'
                    : 'text-gray-900 dark:text-white'
                }`}
                onClick={() => {
                  setIsMenuOpen(false);
                  if (
                    item.name === 'Swap + Whale Watch' ||
                    item.name === 'Vanta Burn' ||
                    item.name === 'Rent Refund'
                  ) {
                    window.open(item.path, '_blank');
                  } else {
                    navigate(item.path);
                  }
                }}
              >
                <div className="flex items-center space-x-2 text-sm font-semibold">
                  {item.icon}
                  <div>{item.name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* Drawer */}
      <div
        ref={drawerRef}
        className={`bg-gray-100 dark:bg-darkest-dark fixed top-0 right-0 h-full overflow-y-auto transform transition-transform duration-300 z-20 ${
          isDrawerOpen ? 'translate-x-0' : 'translate-x-full'
        } w-full md:w-96`}
      >
        <nav
          className="bg-white dark:bg-deals-card-top px-4 rounded-b-20"
          aria-label="Top"
        >
          <div className="flex justify-between w-full items-center py-4">
            <h1 className="text-xl">Settings</h1>
            <IconButtonWrapper onClick={() => setIsDrawerOpen(false)}>
              <XMarkIcon
                width="12.57"
                height="12.57"
                className=" text-orange-dark"
              />
            </IconButtonWrapper>
          </div>
          <div className="divide-y divide-gray-text dark:divide-gray-600 pb-2">
            <div className="flex items-center justify-between mx-2 p-1">
              <div className="text-sm font-semibold">Theme</div>
              <ThemeButton />
            </div>
            <div className="flex items-center justify-between mx-2 p-1">
              <div className="text-sm font-semibold">Privacy Mode</div>
              <CurrencyVisibilityButton />
            </div>
            <div className="flex items-center justify-between mx-2 p-1">
              <div className="text-sm font-semibold">Currency</div>
              <CurrencyFormatButton />
            </div>
            <div className="flex items-center justify-between mx-2 p-1">
              <div className="text-sm font-semibold">Sync</div>
              <div onClick={() => setIsDrawerOpen(false)}>
                <SyncButton />
              </div>
            </div>
            <div className="flex items-center justify-between mx-2 p-1">
              <div className="text-sm font-semibold">Support</div>
              <div
                className="intercom-launcher py-3 cursor-pointer"
                onClick={() => setIsDrawerOpen(false)}
              >
                <ChatBubbleBottomCenterTextIcon
                  width="17.5"
                  height="17.5"
                  className="text-price-green mr-2"
                />
              </div>
            </div>
          </div>
        </nav>
        <div className="divide-y divide-gray-text dark:divide-gray-600 py-5 h-full px-4">
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/account');
            }}
          >
            <div className="flex items-center space-x-2">
              <UserIcon width="17.5" height="17.5" />
              <div>Account</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/hidden-assets');
            }}
          >
            <div className="flex items-center space-x-2">
              <CircleStackIcon width="17.5" height="17.5" />
              <div>Hidden assets</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/notifications');
            }}
          >
            <div className="flex items-center space-x-2">
              <BellAlertIcon width="17.5" height="17.5" />
              <div>Notifications</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/invite');
            }}
          >
            <div className="flex items-center space-x-2">
              <UserPlusIcon width="17.5" height="17.5" />
              <div>Invite friends</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/invite-code');
            }}
          >
            <div className="flex items-center space-x-2">
              <CodeBracketIcon width="17.5" height="17.5" />
              <div>Invite code</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/legal');
            }}
          >
            <div className="flex items-center space-x-2">
              <BookOpenIcon width="17.5" height="17.5" />
              <div>Legal</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              navigate('/faq');
            }}
          >
            <div className="flex items-center space-x-2">
              <QuestionMarkCircleIcon width="17.5" height="17.5" />
              <div>FAQ</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              window.open('https://assetdash.gitbook.io/api-docs/', '_blank');
            }}
          >
            <div className="flex items-center space-x-2">
              <DocumentTextIcon width="17.5" height="17.5" />
              <div>Documentation</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2 cursor-pointer"
            />
          </div>
          <div
            className="flex items-center justify-between mx-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-deals-card"
            onClick={() => {
              setIsDrawerOpen(false);
              clearData();
              logout();
            }}
          >
            <div className="flex items-center space-x-2">
              <ArrowRightOnRectangleIcon width="17.5" height="17.5" />
              <div>Logout</div>
            </div>
            <ChevronRightIcon
              width="17.5"
              height="17.5"
              className="text-primary-orange mr-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
