import React, { useEffect } from 'react';
import {
  CurrencyDollarIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useCoinCenter } from '@/hooks/useCoinCenter';
import { SkeletonLoader } from '@/components/v2/common/Skeleton/SkeletonLoader';
import { formatNumber } from '@/utils/number';

export const CoinRewardCard: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, load, userCoinCenter } = useCoinCenter();

  useEffect(() => {
    const fetchData = async () => {
      await load();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const liquidCoins = userCoinCenter?.coin_center.liquid_coins ?? 0;
  const lockedCoins = userCoinCenter?.coin_center.locked_coins ?? 0;

  return (
    <div className={`card flex flex-col p-4`}>
      {isLoading ? (
        <SkeletonLoader height={150} />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-bold text-gray-900 dark:text-gray-100">
              💵 My Coins
            </h2>
            <button
              onClick={() => navigate('/coins')}
              className="text-primary font-bold"
            >
              View All
            </button>
          </div>
          <p className="text-sm mt-2 text-gray-700 dark:text-gray-300">
            Earn AssetDash Coins for completing Learns and Expeditions and spend
            them on Deals and Super Drops.
          </p>
          <div className="flex flex-col items-center mt-4">
            <div className="w-full flex flex-row justify-between">
              <img
                src="assets/icons/liquid_coin.svg"
                alt="Liquid Coin"
                className="w-12 h-12"
              />
              <div className="flex flex-row items-center space-x-2">
                <CurrencyDollarIcon className="w-6 h-6 text-gray-900 dark:text-gray-100" />
                <div className="text-xl text-gray-900 dark:text-gray-100">
                  {formatNumber(liquidCoins)}
                </div>
              </div>
            </div>
            <div className="w-full flex flex-row justify-between mt-4">
              <img
                src="assets/icons/liquid_coin.svg"
                alt="Locked Coin"
                className="w-12 h-12 grayscale"
              />
              <div className="flex flex-row items-center space-x-2">
                <LockClosedIcon className="w-6 h-6 text-gray-900 dark:text-gray-100" />
                <div className="text-xl text-gray-900 dark:text-gray-100">
                  {formatNumber(lockedCoins)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
