import { GoldCenterJson } from '../../../api/gold';

class GoldCenterResponse {
  goldCenter?: GoldCenter;

  constructor(goldCenter?: GoldCenter) {
    this.goldCenter = goldCenter;
  }

  static fromJson(json: { gold_center: GoldCenterJson }): GoldCenterResponse {
    return new GoldCenterResponse(
      json.gold_center ? GoldCenter.fromJson(json.gold_center) : undefined,
    );
  }

  toJson(): { gold_center?: GoldCenterJson } {
    if (this.goldCenter) {
      return { gold_center: this.goldCenter.toJson() };
    }
    return {};
  }
}

class GoldCenter {
  totalGold?: number;
  liquidGold?: number;
  lockedGold?: number;
  bidGold?: number;
  spentGold?: number;

  constructor(
    totalGold?: number,
    liquidGold?: number,
    lockedGold?: number,
    bidGold?: number,
    spentGold?: number,
  ) {
    this.totalGold = totalGold;
    this.liquidGold = liquidGold;
    this.lockedGold = lockedGold;
    this.bidGold = bidGold;
    this.spentGold = spentGold;
  }

  static fromJson(json: GoldCenterJson): GoldCenter {
    return new GoldCenter(
      json.total_gold ?? 0,
      json.liquid_gold ?? 0,
      json.locked_gold ?? 0,
      json.bid_gold ?? 0,
      json.spent_gold ?? 0,
    );
  }

  toJson(): GoldCenterJson {
    return {
      total_gold: this.totalGold,
      liquid_gold: this.liquidGold,
      locked_gold: this.lockedGold,
      bid_gold: this.bidGold,
      spent_gold: this.spentGold,
    };
  }
}

export { GoldCenter, GoldCenterResponse };
