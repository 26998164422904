import React, { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';
import { GoldCenterProvider } from '@/hooks/useGoldCenter';
import { GoldCenterContainer } from '@/components/gold/GoldCenterContainer';

const GoldCenterPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <GoldCenterProvider>
      <div>
        <SuperSEO title="AssetDash | Gold Center" lang="en_IE" />
        <GoldCenterContainer />
      </div>
    </GoldCenterProvider>
  );
};

export default GoldCenterPage;
