import { useNavigate } from 'react-router-dom';

import { AssetDashExpedition } from '../../features/expeditions/models/AssetDashExpeditions';

// utils
import { formatNumber } from '../../utils/number';

interface AssetdashExpeditionCardProps {
  assetdashExpedition: AssetDashExpedition;
}

export const AssetdashExpeditionCard: React.FC<
  AssetdashExpeditionCardProps
> = ({ assetdashExpedition }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/expeditions/assetdash/${assetdashExpedition.id}`);
      }}
      className={`cursor-pointer flex flex-col card mb-4 pb-4`}
    >
      <div className="md:col-span-2 lg:col-span-2 flex flex-col justify-center items-center mb-2 rounded-13 h-max">
        <img
          src={assetdashExpedition.imageUrl}
          className="w-full min-w-42 h-auto object-contain rounded-2xl"
          alt="expedition"
        />
        <div className="space-y-1 p-4">
          <div className="text-sm leading-17 font-semibold title-multiline-truncate h-8">
            {assetdashExpedition.name}
          </div>
          <div className="text-xs text-gray-text h-16 card-description-multiline-truncate">
            {assetdashExpedition.description}
          </div>
        </div>
        <div className="inline-flex justify-center items-center space-x-1 text-xs px-4 py-2 border-1 bg-price-green bg-opacity-10 border-price-green rounded-full">
          <span className="text-xs sm:text-sm">Earn</span>
          <img
            className="rounded-full"
            src="/assets/icons/coin.png"
            alt="Coin"
            height={20}
            width={20}
          />
          <span className="text-sm">
            {formatNumber(assetdashExpedition.rewardCoins, 0)} Coins
          </span>
        </div>
      </div>
    </div>
  );
};
