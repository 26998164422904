import React from 'react';
import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Wallet } from '@/features/portfolio/models/Wallet';
import {
  ChevronRightIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

interface DealPrizeWalletProps {
  prizeWallets: Wallet[];
  dealBlockchain?: string;
}

export const DealPrizeWallets: React.FC<DealPrizeWalletProps> = ({
  prizeWallets,
  dealBlockchain,
}) => {
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);

  const filteredWallets = prizeWallets.filter(wallet => {
    const walletChain = getChainFromWalletType(wallet.walletType!);
    return walletChain.toLowerCase() === (dealBlockchain ?? '').toLowerCase();
  });

  if (filteredWallets.length === 0) {
    return null;
  }

  return (
    <div className="card p-6 rounded-13">
      <div className="flex items-center space-x-2">
        <h3 className="text-18 font-bold">Prize Wallet</h3>
        <InformationCircleIcon
          className="h-6 w-6 text-accent cursor-pointer"
          onClick={() => setIsInfoDialogOpen(true)}
        />
      </div>
      <div className="mt-4 space-y-4">
        {filteredWallets.map((wallet, index) => (
          <WalletRow key={index} wallet={wallet} />
        ))}
      </div>

      {/* Info Dialog */}
      <Dialog
        open={isInfoDialogOpen}
        onClose={() => setIsInfoDialogOpen(false)}
      >
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="card p-6 rounded-lg shadow-xl max-w-lg w-full bg-white dark:bg-dark-background text-black dark:text-gray-learn">
            <Dialog.Title className="font-bold text-lg text-element-text-light dark:text-element-text-dark">
              Prize Wallet
            </Dialog.Title>
            <Dialog.Description className="mt-4 text-fade-text-light dark:text-fade-text-dark">
              The prize wallet is your designated wallet where this deal's
              rewards will be distributed after purchase. This wallet is set up
              to receive NFTs, tokens, or other digital assets you acquire
              through this deal.
            </Dialog.Description>
            <button
              className="mt-6 px-4 py-2 bg-primary text-white dark:bg-hover-price-green rounded hover:bg-opacity-90"
              onClick={() => setIsInfoDialogOpen(false)}
            >
              Got it
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const WalletRow: React.FC<{ wallet: Wallet }> = ({ wallet }) => {
  const navigate = useNavigate();

  const chain = getChainFromWalletType(wallet.walletType!);
  const truncatedAddress = `${wallet?.address?.slice(
    0,
    6,
  )}...${wallet?.address?.slice(-4)}`;

  return (
    <button
      onClick={() => {
        navigate(`/wallets/${wallet.id}`);
      }}
      className="w-full flex flex-row items-center justify-between py-2 cursor-pointer rounded-lg"
    >
      <span className="text-fade-text-dark">{chain}</span>
      <div className="flex items-center space-x-2">
        <span className="font-semibold text-right text-element-text-dark">
          {truncatedAddress}
        </span>
        <ChevronRightIcon className="h-5 w-5 text-fade-text-dark" />
      </div>
    </button>
  );
};

// Helper function to map wallet type to chain
const getChainFromWalletType = (walletType: string): string => {
  switch (walletType.toLowerCase()) {
    case 'solana':
      return 'SOL';
    case 'zapper':
      return 'ETH';
    case 'flow':
      return 'FLOW';
    case 'ordinals':
      return 'BTC';
    case 'near':
      return 'NEAR';
    case 'aptos':
      return 'APT';
    case 'ton':
      return 'TON';
    default:
      return walletType.toUpperCase();
  }
};
