import { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';

import { DEFAULT_ERROR_MESSAGE } from '@/features/core/constants/constants';
import {
  getUserGoldCenter,
  GoldCenterResponse,
  GoldDistributionsResponse,
  getGoldDistributions,
  GoldSpendingsResponse,
  getGoldSpendings,
} from '@/api/gold';

interface GoldCenterContextProps {
  load: () => Promise<void>;
  loadUserGoldCenter: () => Promise<void>;
  loadGoldDistributions: (
    sort_by: string,
    filter_by: string,
    page: number,
  ) => Promise<void>;
  loadGoldSpendings: () => Promise<void>;
  clearGoldData: () => void;

  userGoldCenter?: GoldCenterResponse;
  goldDistributions: GoldDistributionsResponse['gold_distributions'];
  goldSpendings: GoldSpendingsResponse['gold_spendings'];

  isLoading: boolean;
}

export const GoldCenterContext = createContext<
  GoldCenterContextProps | undefined
>(undefined);

export const useGoldCenter = (): GoldCenterContextProps => {
  const context = useContext(GoldCenterContext);
  if (!context) {
    throw new Error('useGoldCenter must be used within a GoldCenterProvider');
  }
  return context;
};

export const GoldCenterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userGoldCenter, setUserGoldCenter] = useState<GoldCenterResponse>();
  const [goldDistributions, setGoldDistributions] = useState<
    GoldDistributionsResponse['gold_distributions']
  >([]);
  const [goldSpendings, setGoldSpendings] = useState<
    GoldSpendingsResponse['gold_spendings']
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    await Promise.all([
      loadUserGoldCenter(),
      loadGoldDistributions('created', 'all', 1),
      loadGoldSpendings(),
    ]);
  };

  const loadUserGoldCenter = async () => {
    setIsLoading(true);
    try {
      const response = await getUserGoldCenter();
      setUserGoldCenter(response.data);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const loadGoldDistributions = async (
    sort_by: string,
    filter_by: string,
    page: number,
  ) => {
    setIsLoading(true);
    try {
      const response = await getGoldDistributions(sort_by, filter_by, page);
      setGoldDistributions(response.data.gold_distributions || []);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const loadGoldSpendings = async () => {
    setIsLoading(true);
    try {
      const response = await getGoldSpendings();
      setGoldSpendings(response.data.gold_spendings || []);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const clearGoldData = () => {
    setUserGoldCenter(undefined);
    setGoldDistributions([]);
    setGoldSpendings([]);
  };

  return (
    <GoldCenterContext.Provider
      value={{
        load,
        loadUserGoldCenter,
        loadGoldDistributions,
        loadGoldSpendings,
        clearGoldData,
        userGoldCenter,
        goldDistributions,
        goldSpendings,
        isLoading,
      }}
    >
      {children}
    </GoldCenterContext.Provider>
  );
};
