import React from 'react';

interface DealEligibilityProps {
  deal: {
    eligible_assets?: Array<{
      id: string;
      logo_url: string;
      name: string;
    }>;
    eligible_partners?: Array<{
      partner_id: string;
      logo_url: string;
      name: string;
    }>;
  };
  verifiedAssetIds: any[];
  memberPartnerIds: Set<any>;
}

export const DealEligibility: React.FC<DealEligibilityProps> = ({
  deal,
  verifiedAssetIds,
  memberPartnerIds,
}) => {
  // Check Eligibility
  let isEligible = false;
  if (
    (deal.eligible_assets?.length || 0) === 0 &&
    (deal.eligible_partners?.length || 0) === 0
  ) {
    isEligible = true;
  }

  if (deal.eligible_assets) {
    const eligibleAsset = deal.eligible_assets.find(asset =>
      verifiedAssetIds.includes(asset.id),
    );
    if (eligibleAsset) {
      isEligible = true;
    }
  }

  if (deal.eligible_partners) {
    const eligiblePartner = deal.eligible_partners.find(partner =>
      memberPartnerIds.has(partner.partner_id),
    );
    if (eligiblePartner) {
      isEligible = true;
    }
  }

  // Eligible Assets List
  const eligibleAssets = deal.eligible_assets?.map(asset => (
    <div key={asset.id} className="flex items-center justify-between mb-2">
      <div className="flex items-center space-x-2">
        <img
          src={asset.logo_url}
          alt={asset.name}
          className="w-5 h-5 rounded"
        />
        <span className="font-semibold">{asset.name}</span>
      </div>
      {verifiedAssetIds.includes(asset.id) && (
        <img
          src="/assets/icons/check-circle.png"
          alt="Verified"
          className="w-5 h-5"
        />
      )}
    </div>
  ));

  // Eligible Partners List
  const eligiblePartners = deal.eligible_partners?.map(partner => (
    <div
      key={partner.partner_id}
      className="flex items-center justify-between mb-2"
    >
      <div className="flex items-center space-x-2">
        <img
          src={partner.logo_url}
          alt={partner.name}
          className="w-5 h-5 rounded"
        />
        <span className="font-semibold">{partner.name}</span>
      </div>
      {memberPartnerIds.has(partner.partner_id) && (
        <img
          src="/assets/icons/check-circle.png"
          alt="Verified"
          className="w-5 h-5"
        />
      )}
    </div>
  ));

  return (
    <div className="card p-6 rounded-13">
      <h3 className="text-start text-18 font-bold">Eligibility</h3>
      <div className="mt-4 flex justify-center">
        <div
          className={`border rounded-full px-4 py-2 text-sm font-semibold ${
            isEligible
              ? 'border-primary text-primary'
              : 'border-negative text-negative'
          }`}
        >
          {isEligible
            ? 'You are eligible for this deal'
            : 'You are not eligible for this deal'}
        </div>
      </div>

      <div className="mt-4 space-y-2">
        {eligibleAssets && eligibleAssets.length > 0 && <>{eligibleAssets}</>}
        {eligiblePartners && eligiblePartners.length > 0 && (
          <>{eligiblePartners}</>
        )}
      </div>
    </div>
  );
};
