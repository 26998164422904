import { createContext, useRef, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';

// apis
import {
  getPortfolioSync,
  getAssetPrices,
  getNftChainPrices,
  getPortfolioChartSeries,
  getAssetHoldingChartSeries,
  getNFTHoldingChartSeries,
  getWalletTransactions,
} from '../api/portfolioSync';
import {
  createManualWallet,
  updateWallet,
  deleteWallet,
  syncManualWallet,
} from '../api/wallets';

import { getAssetChartSeries } from '../api/assets';

// models
import { AssetHoldingChartSeries } from '../features/assets/models/AssetHoldingsChart';
import { AssetPriceChartSeries } from '../features/assets/models/ChartResponse';
import { AssetPricesSync } from '../features/portfolio/models/AssetPricesSync';
import { Asset } from '../features/portfolio/models/Asset';
import { CustomNFTPrice } from '../features/portfolio/models/CustomNFTPrice';
import { HiddenAssets } from '../features/portfolio/models/HiddenAsset';
import { Holding } from '../features/portfolio/models/Holding';
import { HoldingCategory } from '../features/portfolio/holdings/models/HoldingCategory';
import { ManualAsset } from '../features/portfolio/models/ManualAsset';
import { NFTChainPrice } from '../features/portfolio/models/NFTChainPriceSync';
import { NFTCollection } from '../features/portfolio/models/NFTCollection';
import { NFTHoldingChartSeries } from '../features/nft/models/NFTHoldingsChart';
import { NFTItem } from '../features/portfolio/models/NFTItem';
import {
  PortfolioChartData,
  PortfolioChartDatapoint,
} from '../features/portfolio/worth/models/CategoryWorthModel';
import { PortfolioChartItem } from '../features/portfolio/models/PortfolioCharts';
import { PortfolioChartSeriesResponse } from '../features/portfolio/models/PortfolioCharts';
import { PortfolioSync } from '../features/portfolio/models/PortfolioSync';
import { WalletHolding } from '../features/portfolio/models/WalletHolding';
import { WalletNFTItem } from '../features/portfolio/models/WalletNFTItem';
import { Wallet } from '../features/portfolio/models/Wallet';
import {
  WalletActivityTransaction,
  WalletActivityTransactionsResponse,
} from '../features/portfolio/models/WalletActivityTransaction';

// enums
import {
  HoldingTypeGroup,
  HoldingTypeGroupInfo,
} from '../features/portfolio/holdings/enums/HoldingTypeGroup';

// utils
import { getHoldingTypeGroup } from '../features/portfolio/utils/holdings/holdingsUtils';

interface PortfolioContextValue {
  load: (params?: {
    checkCache?: boolean;
    showLoading?: boolean;
  }) => Promise<void>;
  clearData: () => Promise<void>;
  fetchPortfolioSeriesChart: (series: string) => Promise<void>;
  fetchAssetHoldingSeriesChart: (assetId: string) => Promise<void>;
  fetchNFTHoldingSeriesChart: (nftItemId: string) => Promise<void>;
  fetchAssetPriceChart: (assetId: string) => Promise<void>;
  backgroundLoadAssetPrices: () => Promise<void>;
  backgroundLoadNFTChainPrices: () => Promise<void>;
  hiddenAssets: HiddenAssets[];
  customNFTPricesMap: Map<string, CustomNFTPrice>;
  assetsMap: Map<string, Asset>;
  manualAssetsMap: Map<string, ManualAsset>;
  nftItemsMap: Map<string, NFTItem>;
  hiddenAssetIds: Set<string>;
  wallets: Wallet[];
  walletHoldings: WalletHolding[];
  assetIdToWalletHoldings: Map<string, WalletHolding[]>;
  elementAsset: Asset;
  walletNFTItems: WalletNFTItem[];
  elementNFTItems: WalletNFTItem[];
  walletTransactions: WalletActivityTransaction[];
  assetIdToWalletNFTItemsMap: Map<string, WalletNFTItem[]>;
  walletsMap: Map<string, Wallet>;
  holdings: Holding[];
  nftCollections: NFTCollection[];
  nftItems: NFTItem[];
  verifiedAssetIds: string[];
  bestPerformance: Holding | null;
  biggestPosition: Holding | null;
  holdingCategories: HoldingCategory[];
  holdingCategoriesMap: Map<string, HoldingCategory>;
  activeHoldingCategory: string | null;
  totalPortfolioValue: number;
  previousTotalPortfolioValue: number;
  portfolioExists: boolean;
  lastSyncTimestamp: Date;
  isPortfolioSeriesChartFetching: boolean;
  isAssetSeriesChartFetching: boolean;
  isNFTSeriesChartFetching: boolean;
  isAssetPriceChartFetching: boolean;
  portfolioHoldingsChartData: PortfolioChartData | null;
  portfolioHoldingsChartDataMap: Map<string, Map<number, PortfolioChartData>>;
  portfolioWalletsChartDataMap: Map<string, Map<number, PortfolioChartData>>;
  assetHoldingChartDataMap: Map<string, AssetHoldingChartSeries>;
  nftHoldingChartDataMap: Map<string, NFTHoldingChartSeries>;
  assetPriceChartMap: Map<string, AssetPriceChartSeries>;
  assetIdToHoldingMap: Map<string, Holding>;
  nftItemToWalletNFTItemMap: Map<string, WalletNFTItem>;
  showBrokenAccountsBanner: boolean;
  syncWallet: (walletId: string) => Promise<void>;
  updateWalletName: (walletId: string, name: string) => Promise<void>;
  removeWallet: (walletId: string) => Promise<void>;
  addNewManualWallet: (walletId: string) => Promise<any>;
  fetchWalletTransactions: (checkCache: boolean) => Promise<void>;
  isLoading: boolean;
}

export const PortfolioContext = createContext({});

export function usePortfolio(): PortfolioContextValue {
  return useContext(PortfolioContext) as PortfolioContextValue;
}

export const PortfolioProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Raw data (response from API)
  let portfolioSyncResponseData = useRef<PortfolioSync | null>(null);
  let nftChainPricesResponseData = useRef<NFTChainPrice | null>(null);
  let assetPricesResponseData = useRef<AssetPricesSync | null>(null);

  // Items
  const [hiddenAssets, setHiddenAssets] = useState<HiddenAssets[]>([]);
  const [customNFTPricesMap, setCustomNFTPricesMap] = useState<
    Map<string, CustomNFTPrice>
  >(new Map());

  const [assetsMap, setAssetsMap] = useState<Map<string, Asset>>(new Map());
  const [manualAssetsMap, setManualAssetsMap] = useState<
    Map<string, ManualAsset>
  >(new Map());
  const [nftItemsMap, setNFTItemsMap] = useState<Map<string, NFTItem>>(
    new Map(),
  );
  const [hiddenAssetIds, setHiddenAssetIds] = useState<Set<string>>(new Set());

  // Wallets
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [walletHoldings, setWalletHoldings] = useState<WalletHolding[]>([]);
  const [walletNFTItems, setWalletNFTItems] = useState<WalletNFTItem[]>([]);
  const [elementNFTItems, setElementNFTItems] = useState<WalletNFTItem[]>([]);

  const [assetIdToWalletNFTItemsMap, setAssetIdToWalletNFTItemsMap] = useState<
    Map<string, WalletNFTItem[]>
  >(new Map());

  //Wallet Holdings by Asset Id
  const [assetIdToWalletHoldings, setAssetIdToWalletHoldingsMap] = useState<
    Map<string, WalletHolding[]>
  >(new Map());
  const [walletsMap, setWalletsMap] = useState<Map<string, Wallet>>(new Map());

  // Aggregate
  const [holdings, setHoldings] = useState<Holding[]>([]);
  const [nftCollections, setNFTCollections] = useState<NFTCollection[]>([]);
  const [nftItems, setNFTItems] = useState<NFTItem[]>([]);
  const [verifiedAssetIds, setVerifiedAssetIds] = useState<string[]>([]);

  // Display

  // Main dashboard
  const [bestPerformance, setBestPerformance] = useState<Holding | null>(null);
  const [biggestPosition, setBiggestPosition] = useState<Holding | null>(null);
  const [holdingCategories, setHoldingCategories] = useState<HoldingCategory[]>(
    [],
  );
  const [holdingCategoriesMap, setHoldingCategoriesMap] = useState<
    Map<string, HoldingCategory>
  >(new Map());
  const [activeHoldingCategory, setActiveHoldingCategory] = useState<
    string | null
  >(null);
  const [totalPortfolioValue, setTotalPortfolioValue] = useState<number>(0);
  const [previousTotalPortfolioValue, setPreviousTotalPortfolioValue] =
    useState<number>(0);
  const [portfolioExists, setPortfolioExists] = useState<boolean>(false);
  const [lastSyncTimestamp, setLastSyncTimestamp] = useState<Date>(new Date());

  const [elementAsset, setElementAsset] = useState<Asset | null>(null);
  // Charts
  // const [portfolioChartSelectedDays, setPortfolioChartSelectedDays] = useState<number>(1);
  const [isPortfolioSeriesChartFetching, setIsPortfolioSeriesChartFetching] =
    useState<boolean>(false);
  // const [isWalletSeriesChartFetching, setIsWalletSeriesChartFetching] = useState<boolean>(true);
  const [isAssetSeriesChartFetching, setIsAssetSeriesChartFetching] =
    useState<boolean>(false);
  const [isNFTSeriesChartFetching, setIsNFTSeriesChartFetching] =
    useState<boolean>(true);
  const [isAssetPriceChartFetching, setIsAssetPriceChartFetching] =
    useState<boolean>(false);

  const [portfolioHoldingsChartData, setPortfolioHoldingsChartData] =
    useState<PortfolioChartData | null>(null);
  const [portfolioHoldingsChartDataMap, setPortfolioHoldingsChartDataMap] =
    useState<Map<string, Map<number, PortfolioChartData>>>(new Map());
  const [portfolioWalletsChartDataMap, setPortfolioWalletsChartDataMap] =
    useState<Map<string, Map<number, PortfolioChartData>>>(new Map());
  const [assetHoldingChartDataMap, setAssetHoldingChartDataMap] = useState<
    Map<string, AssetHoldingChartSeries>
  >(new Map());
  const [nftHoldingChartDataMap, setNFTHoldingChartDataMap] = useState<
    Map<string, NFTHoldingChartSeries>
  >(new Map());
  const [assetPriceChartMap, setAssetPriceChartMap] = useState<
    Map<string, AssetPriceChartSeries>
  >(new Map());

  // Asset screen
  const [assetIdToHoldingMap, setAssetIdToHoldingMap] = useState<
    Map<string, Holding>
  >(new Map());
  const [nftItemToWalletNFTItemMap, setNFTItemToWalletNFTItemMap] = useState<
    Map<string, WalletNFTItem>
  >(new Map());

  // Wallet screen
  const [showBrokenAccountsBanner, setShowBrokenAccountsBanner] =
    useState<boolean>(false);

  // Wallet Activity
  const [walletTransactions, setWalletTransactions] = useState<
    WalletActivityTransaction[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const load = async ({ checkCache = true, showLoading = false } = {}) => {
    if (showLoading) {
      setIsLoading(true);
    }
    const portfolioSync = await fetchPortfolioSync(checkCache);
    const assetPriceData = await fetchAssetPrices();
    const nftChainPrices = await fetchNFTChainPrices();
    const _portfolioHoldingsChartDataMap =
      await _fetchPortfolioSeriesChart('all');

    recalculatePortfolioData({
      portfolioSyncResponse: portfolioSync!,
      assetPricesResponse: assetPriceData!,
      nftChainPricesResponse: nftChainPrices!,
      _portfolioHoldingsChartDataMap,
    });

    setIsLoading(false);
    if (showLoading) {
      setIsLoading(false);
    }
  };

  const clearData = () => {
    // Raw data (response from API)
    portfolioSyncResponseData.current = null;
    nftChainPricesResponseData.current = null;
    assetPricesResponseData.current = null;

    // Items
    setHiddenAssets([]);
    setCustomNFTPricesMap(new Map());

    setElementAsset(null);
    setAssetsMap(new Map());
    setManualAssetsMap(new Map());
    setNFTItemsMap(new Map());
    setHiddenAssetIds(new Set());

    // Wallets
    setWallets([]);
    setWalletHoldings([]);
    setWalletNFTItems([]);
    setElementNFTItems([]);
    setAssetIdToWalletNFTItemsMap(new Map());
    setWalletsMap(new Map());
    setAssetIdToWalletHoldingsMap(new Map());
    // Aggregate
    setHoldings([]);
    setNFTCollections([]);
    setNFTItems([]);
    setVerifiedAssetIds([]);

    // Display

    // Main dashboard
    setBestPerformance(null);
    setBiggestPosition(null);
    setHoldingCategories([]);
    setHoldingCategoriesMap(new Map());
    setActiveHoldingCategory(null);
    setTotalPortfolioValue(0);
    setPreviousTotalPortfolioValue(0);
    setPortfolioExists(true);
    setLastSyncTimestamp(new Date());

    // Charts
    setIsPortfolioSeriesChartFetching(false);
    setIsAssetSeriesChartFetching(false);
    setIsNFTSeriesChartFetching(false);
    setIsAssetPriceChartFetching(false);

    setPortfolioHoldingsChartData(null);
    setPortfolioHoldingsChartDataMap(new Map());
    setPortfolioWalletsChartDataMap(new Map());
    setAssetHoldingChartDataMap(new Map());
    setNFTHoldingChartDataMap(new Map());
    setAssetPriceChartMap(new Map());

    // Asset screen
    setAssetIdToHoldingMap(new Map());
    setNFTItemToWalletNFTItemMap(new Map());

    // Wallet screen
    setShowBrokenAccountsBanner(false);

    setIsLoading(true);
  };

  const backgroundLoadAssetPrices = async () => {
    const assetPricesResponse = await fetchAssetPrices();
    recalculatePortfolioData({
      assetPricesResponse,
    });
  };

  const backgroundLoadNFTChainPrices = async () => {
    const nftChainPricesResponse = await fetchNFTChainPrices();
    recalculatePortfolioData({ nftChainPricesResponse });
  };

  const fetchPortfolioSeriesChart = async (series: string) => {
    setIsPortfolioSeriesChartFetching(true);
    const _portfolioHoldingsChartDataMap =
      await _fetchPortfolioSeriesChart(series);

    recalculatePortfolioData({
      _portfolioHoldingsChartDataMap,
    });

    setIsPortfolioSeriesChartFetching(false);
  };

  const fetchAssetHoldingSeriesChart = async (assetId: string) => {
    setIsAssetSeriesChartFetching(true);
    const _assetHoldingChartDataMap =
      await _fetchAssetHoldingSeriesChart(assetId);

    recalculatePortfolioData({
      portfolioSyncResponse: portfolioSyncResponseData.current!,
      _assetHoldingChartDataMap,
    });

    setIsAssetSeriesChartFetching(false);
  };

  const fetchNFTHoldingSeriesChart = async (assetId: string) => {
    setIsNFTSeriesChartFetching(true);
    const _nftHoldingChartDataMap = await _fetchNFTHoldingSeriesChart(assetId);

    recalculatePortfolioData({
      _nftHoldingChartDataMap,
    });

    setIsNFTSeriesChartFetching(false);
  };

  const fetchAssetPriceChart = async (assetId: string) => {
    setIsAssetPriceChartFetching(true);
    const _assetPriceChartMap = await _fetchAssetPriceChart(assetId);
    setAssetPriceChartMap(_assetPriceChartMap!);
    setIsAssetPriceChartFetching(false);
  };

  const fetchPortfolioSync = async (checkCache: boolean) => {
    try {
      const response = await getPortfolioSync(checkCache);
      return PortfolioSync.fromJSON(response.data);
    } catch (error) {}
  };

  const fetchAssetPrices = async () => {
    try {
      const response = await getAssetPrices();
      return AssetPricesSync.fromJSON(response.data);
    } catch (error) {}
  };

  const fetchNFTChainPrices = async () => {
    try {
      const response = await getNftChainPrices();
      return NFTChainPrice.fromJSON(response.data);
    } catch (error) {}
  };

  const fetchWalletTransactions = async (checkCache: boolean) => {
    try {
      const response = await getWalletTransactions(checkCache);
      const transactions = WalletActivityTransactionsResponse.fromJSON(
        response.data,
      ).transactions;
      setWalletTransactions(transactions);
    } catch (error) {
      toast.error('something went wrong');
    }
  };

  const _fetchPortfolioSeriesChart = async (series: string) => {
    try {
      let _portfolioHoldingsChartDataMap = portfolioHoldingsChartDataMap;

      const response = await getPortfolioChartSeries(series);
      const portfolioChartSeriesResponse =
        PortfolioChartSeriesResponse.fromJSON(response.data);

      if (!_portfolioHoldingsChartDataMap.get(series)) {
        _portfolioHoldingsChartDataMap.set(series, new Map());
      }

      _portfolioHoldingsChartDataMap
        .get(series)!
        .set(
          1,
          _createPortfolioChartData(portfolioChartSeriesResponse.day1Items!, 1),
        );
      _portfolioHoldingsChartDataMap
        .get(series)!
        .set(
          7,
          _createPortfolioChartData(
            portfolioChartSeriesResponse.week1Items!,
            7,
          ),
        );
      _portfolioHoldingsChartDataMap
        .get(series)!
        .set(
          30,
          _createPortfolioChartData(
            portfolioChartSeriesResponse.month1Items!,
            30,
          ),
        );
      _portfolioHoldingsChartDataMap
        .get(series)!
        .set(
          90,
          _createPortfolioChartData(
            portfolioChartSeriesResponse.month3Items!,
            90,
          ),
        );
      _portfolioHoldingsChartDataMap
        .get(series)!
        .set(
          365,
          _createPortfolioChartData(
            portfolioChartSeriesResponse.year1Items!,
            365,
          ),
        );

      _portfolioHoldingsChartDataMap
        .get(series)!
        .set(
          3650,
          _createPortfolioChartData(portfolioChartSeriesResponse.all!),
        );

      return _portfolioHoldingsChartDataMap;
    } catch (error) {}
  };

  const _fetchAssetHoldingSeriesChart = async (assetId: string) => {
    try {
      const _assetHoldingChartDataMap = assetHoldingChartDataMap;
      const response = await getAssetHoldingChartSeries(assetId);
      const assetHoldingChartSeriesResponse = AssetHoldingChartSeries.fromJSON(
        response.data,
      );

      _assetHoldingChartDataMap.set(assetId, assetHoldingChartSeriesResponse);

      return _assetHoldingChartDataMap;
    } catch (error) {}
  };

  const _fetchNFTHoldingSeriesChart = async (nftItemId: string) => {
    try {
      const _nftHoldingChartDataMap = nftHoldingChartDataMap;
      const response = await getNFTHoldingChartSeries(nftItemId);
      const nftHoldingChartSeriesResponse = NFTHoldingChartSeries.fromJSON(
        response.data,
      );

      _nftHoldingChartDataMap.set(nftItemId, nftHoldingChartSeriesResponse);

      return _nftHoldingChartDataMap;
    } catch (error) {}
  };

  const _fetchAssetPriceChart = async (assetId: string) => {
    try {
      const _assetPriceChartMap = assetPriceChartMap;
      const response = await getAssetChartSeries(assetId);
      const assetPriceChartSeries = AssetPriceChartSeries.fromJSON(
        response.data,
      );

      _assetPriceChartMap.set(assetId, assetPriceChartSeries);

      return _assetPriceChartMap;
    } catch (error) {}
  };

  const _createPortfolioChartData = (
    items: PortfolioChartItem[],
    timePeriod?: number,
  ): PortfolioChartData => {
    const portfolioChartData = new PortfolioChartData(0, 0, 0, []);

    items
      ?.slice()
      .reverse()
      .forEach(element => {
        let dateTime: Date | undefined;
        const parsedDate = element.day || element.timestamp_datetime || '';
        dateTime = new Date(parsedDate + (timePeriod === 1 ? 'z' : ''));
        portfolioChartData.datapoints.push(
          new PortfolioChartDatapoint(dateTime, element.value || 0),
        );
      });

    return portfolioChartData;
  };

  const recalculatePortfolioData = ({
    portfolioSyncResponse = portfolioSyncResponseData.current!,
    assetPricesResponse = assetPricesResponseData.current!,
    nftChainPricesResponse = nftChainPricesResponseData.current!,
    _portfolioHoldingsChartDataMap = portfolioHoldingsChartDataMap!,
    _assetHoldingChartDataMap = assetHoldingChartDataMap!,
    _nftHoldingChartDataMap = nftHoldingChartDataMap!,
  }: {
    portfolioSyncResponse?: PortfolioSync;
    assetPricesResponse?: AssetPricesSync;
    nftChainPricesResponse?: NFTChainPrice;
    _portfolioHoldingsChartDataMap?: Map<
      string,
      Map<number, PortfolioChartData>
    >;
    _assetHoldingChartDataMap?: Map<string, AssetHoldingChartSeries>;
    _nftHoldingChartDataMap?: Map<string, NFTHoldingChartSeries>;
  } = {}) => {
    try {
      // Initialze local variables from state variables

      // Items
      let _hiddenAssets = hiddenAssets;

      let _assetsMap = assetsMap;
      let _manualAssetsMap = manualAssetsMap;
      let _nftItemsMap = nftItemsMap;
      let _hiddenAssetIds = hiddenAssetIds;

      // Wallets
      let _wallets = wallets;
      let _walletHoldings = walletHoldings;
      let _walletNFTItems = walletNFTItems;
      let _elementNFTItems = elementNFTItems;
      let _assetIdToWalletNFTItemsMap = assetIdToWalletNFTItemsMap;
      let _walletsMap = walletsMap;

      // Aggregate
      let _holdings = holdings;
      let _nftCollections = nftCollections;
      let _nftItems = nftItems;
      let _verifiedAssetIds = verifiedAssetIds;

      // Display

      // Main dashboard
      let _bestPerformance = bestPerformance;
      let _biggestPosition = biggestPosition;
      let _holdingCategories = holdingCategories;
      let _holdingCategoriesMap = holdingCategoriesMap;
      let _activeHoldingCategory = activeHoldingCategory;
      let _totalPortfolioValue = totalPortfolioValue;
      let _previousTotalPortfolioValue = previousTotalPortfolioValue;
      let _portfolioExists = portfolioExists;
      let _lastSyncTimestamp = lastSyncTimestamp;
      let _elementAsset = elementAsset;

      let _portfolioHoldingsChartData = portfolioHoldingsChartData;
      let _portfolioWalletsChartDataMap = portfolioWalletsChartDataMap;

      // Asset screen
      let _assetIdToHoldingMap = assetIdToHoldingMap;
      let _nftItemToWalletNFTItemMap = nftItemToWalletNFTItemMap;

      // Wallet screen
      let _showBrokenAccountsBanner = showBrokenAccountsBanner;

      // Mark previous
      _previousTotalPortfolioValue = _totalPortfolioValue;

      //Asset

      _elementAsset = portfolioSyncResponse?.elementsAsset ?? null;
      // Ingest from PortfolioSync (live)
      _assetsMap = new Map(
        portfolioSyncResponse?.assets?.map(a => [a.id!, a]) || [],
      );
      _manualAssetsMap = new Map(
        portfolioSyncResponse?.manualAssets?.map(a => [a.id!, a]) || [],
      );
      _nftItemsMap = new Map(
        portfolioSyncResponse?.nftItems?.map(a => [a.id, a]) || [],
      );
      _nftCollections = portfolioSyncResponse?.collections || [];

      _wallets = portfolioSyncResponse?.wallets || [];
      _walletHoldings = portfolioSyncResponse?.walletHoldings || [];
      _walletNFTItems = portfolioSyncResponse?.walletNfts || [];

      const _customNFTPricesMap = new Map(
        portfolioSyncResponse?.customNftPrices?.map(a => [a.nftItemId!, a]) ||
          [],
      );
      _hiddenAssets = portfolioSyncResponse?.hiddenAssets || [];
      _hiddenAssetIds = new Set(_hiddenAssets.map(item => item.assetId!));

      if (!_wallets.length) {
        _walletHoldings = [];
        _walletNFTItems = [];
        return;
      }

      // Update prices
      assetPricesResponse?.assetPrices?.forEach(assetPrice => {
        if (_assetsMap.has(assetPrice.id!)) {
          let asset = _assetsMap.get(assetPrice.id!);
          if (asset) {
            _assetsMap.set(
              assetPrice.id!,
              asset.copyWith({
                priceUsd: assetPrice.priceUsd || asset.priceUsd,
                priceNative: assetPrice.priceNative || asset.priceNative,
              }),
            );
          }
        }
      });

      // Calculate custom prices
      _nftItemToWalletNFTItemMap = new Map();
      _walletNFTItems = _walletNFTItems
        .map(walletNFTItem => {
          let asset = _assetsMap.get(walletNFTItem.assetId!)!;

          let nftItem = _nftItemsMap.get(walletNFTItem.nftItemId!)!;

          // Set the priceNative and calculate priceUsd from the chain price
          let chainPrice =
            nftChainPricesResponse?.getPriceForChain(nftItem.chain) || 0.0;
          let priceNative = asset.priceNative || 0.0;
          let priceUsd =
            chainPrice === 0.0 ? asset.priceUsd || 0 : priceNative * chainPrice;

          // Update the asset map to include the latest price for the NFT asset (using the latest chain price)
          _assetsMap.set(
            asset.id!,
            asset.copyWith({ priceNative: priceNative, priceUsd: priceUsd }),
          );

          // Set the floor values
          let floorPriceNative = priceNative;
          let floorPriceUsd = priceUsd;

          let customNFTPrice = _customNFTPricesMap.get(
            walletNFTItem.nftItemId!,
          );
          if (customNFTPrice) {
            if (
              customNFTPrice.manualPriceUsd &&
              customNFTPrice.manualPriceUsd > 0
            ) {
              priceUsd = customNFTPrice.manualPriceUsd || 0.0;
              priceNative = floorPriceNative;
            } else if (
              customNFTPrice.floorMultiplier &&
              customNFTPrice.floorMultiplier > 0
            ) {
              priceUsd = customNFTPrice.floorMultiplier * floorPriceUsd;
              priceNative = customNFTPrice.floorMultiplier * floorPriceNative;
            }
          }

          let newItem = walletNFTItem.copyWith({
            priceNative: priceNative,
            priceUsd: priceUsd,
            floorPriceUsd: floorPriceUsd,
            floorPriceNative: floorPriceNative,
            nftItem: nftItem,
          });

          _nftItemToWalletNFTItemMap.set(newItem.nftItemId!, newItem);

          return newItem;
        })
        .filter(walletNFTItem => !_hiddenAssetIds.has(walletNFTItem.assetId!));

      _elementNFTItems =
        _walletNFTItems.filter(item => item.assetId === _elementAsset?.id) ||
        [];

      // Update asset prices that are NFTs

      // Group by collection and assign to collection items
      const _walletNFTItemGroups: Map<string, WalletNFTItem[]> = groupBy(
        _walletNFTItems,
        (walletNFTItem: WalletNFTItem) => {
          return walletNFTItem.nftCollectionId!;
        },
      );
      _nftCollections = _nftCollections.map(nftCollection => {
        const walletNFTItems =
          _walletNFTItemGroups.get(nftCollection.id!) || [];
        walletNFTItems.sort((a, b) => (b.priceUsd || 0) - (a.priceUsd || 0));
        const totalValue = walletNFTItems.reduce(
          (sum, item) => sum + (item.priceUsd || 0),
          0,
        );
        return nftCollection.copyWith({
          walletNFTItems: walletNFTItems,
          totalValue: totalValue,
        });
      });

      // Update prices
      _walletHoldings = _walletHoldings
        .map(walletHolding => {
          if (walletHolding.manualAssetId != null) {
            const manualAsset: ManualAsset | undefined = _manualAssetsMap.get(
              walletHolding.manualAssetId,
            );
            return walletHolding.copyWith({ manualAsset });
          } else if (walletHolding.assetId != null) {
            const asset: Asset | undefined = _assetsMap.get(
              walletHolding.assetId,
            );
            if (!asset) {
              const manualAsset: ManualAsset | undefined = _manualAssetsMap.get(
                walletHolding.assetId,
              );
              if (manualAsset) {
                return walletHolding.copyWith({ manualAsset });
              } else {
                return walletHolding;
              }
            }
            let priceUsd: number | undefined = asset.priceUsd;
            if (priceUsd === null || priceUsd === 0) {
              priceUsd = walletHolding.price;
            }

            let value = (priceUsd || 0.0) * (walletHolding.quantity || 0.0);

            // Calculate value, if it's an NFT, sum all the NFT items
            if (asset.assetTypeGroup === 'nft') {
              const assetWalletNFTItems: WalletNFTItem[] =
                _walletNFTItems.filter(
                  item =>
                    item.assetId === asset.id &&
                    item.userWalletId === walletHolding.userWalletId,
                );
              if (assetWalletNFTItems.length > 0) {
                value = assetWalletNFTItems.reduce(
                  (sum, item) => sum + (item.priceUsd || 0),
                  0,
                );
              }
            } else if (asset.assetTypeGroup === 'defi') {
              value = walletHolding.value || 0.0;
            }

            if (
              walletHolding.verified === true &&
              walletHolding.assetId != null
            ) {
              verifiedAssetIds.push(walletHolding.assetId!);
            }

            return walletHolding.copyWith({
              asset,
              price: priceUsd,
              value,
            });
          } else {
            return walletHolding;
          }
        })
        .filter(walletNFTItem => !_hiddenAssetIds.has(walletNFTItem.assetId!));

      // Group WalletNFTItems by assetId
      _assetIdToWalletNFTItemsMap = groupBy(
        _walletNFTItems,
        walletNFTItem => walletNFTItem.assetId || '',
      );

      // Calculate Net Worth
      _totalPortfolioValue = _walletHoldings.reduce(
        (sum, item) => sum + (item.value || 0),
        0,
      );

      // Aggregate portfolio
      _holdings = [];
      const walletHoldingGroupsByAssetId = groupBy(
        _walletHoldings,
        walletHolding => walletHolding.assetId || '',
      );

      setAssetIdToWalletHoldingsMap(walletHoldingGroupsByAssetId);

      for (const [
        assetId,
        assetHoldings,
      ] of walletHoldingGroupsByAssetId.entries()) {
        let asset: Asset | undefined = _assetsMap.get(assetId);
        let price = 0.0;
        if (!asset) {
          const manualAsset: ManualAsset | undefined =
            _manualAssetsMap.get(assetId);
          if (manualAsset) {
            asset = new Asset({
              id: manualAsset.id,
              name: manualAsset.name,
              ticker: manualAsset.tickerDisplay,
              tickerDisplay: manualAsset.tickerDisplay,
              assetType: manualAsset.assetType,
              assetTypeGroup: manualAsset.assetTypeGroup,
              logoUrl: manualAsset.logoUrl,
              slug: manualAsset.slug,
            });
          }
        }

        if (asset) {
          price = asset.priceUsd || 0;

          const quantity = assetHoldings.reduce(
            (sum, item) => sum + (item.quantity || 0),
            0,
          );
          const quantity1dAgo = assetHoldings.reduce(
            (sum, item) => sum + (item.quantity1dAgo || 0),
            0,
          );
          const value1dAgo = assetHoldings.reduce(
            (sum, item) => sum + (item.value1dAgo || 0),
            0,
          );
          const price1dAgo = assetHoldings[0]?.price1dAgo || 0;

          // Calculate value, if it's an NFT, sum all the NFT items
          let value = assetHoldings.reduce(
            (sum, item) => sum + (item.value || 0),
            0,
          );
          if (asset.assetTypeGroup === 'nft') {
            const assetNFTItems: WalletNFTItem[] =
              _assetIdToWalletNFTItemsMap.get(asset.id!) || [];
            if (assetNFTItems.length > 0) {
              value = assetNFTItems.reduce(
                (sum, item) => sum + (item.priceUsd || 0),
                0,
              );
            }
          }

          const allocation = (value / _totalPortfolioValue) * 100;
          const priceGain = price - price1dAgo;
          const priceGainPercentage =
            price1dAgo > 0 ? ((price - price1dAgo) / price1dAgo) * 100 : 0;

          _holdings.push(
            new Holding({
              id: assetId,
              asset,
              price,
              quantity,
              amount: value,
              allocation,
              roi: 0,
              valueChange: value - value1dAgo,
              priceGain,
              priceGainPercentage,
              quantityChange: quantity - quantity1dAgo,
            }),
          );
        }
      }

      // Aggregate holdings into type groups for pie chart/dashboard
      _holdingCategories = [];
      _assetIdToHoldingMap.clear();
      for (const element in HoldingTypeGroup) {
        const enumElement =
          HoldingTypeGroup[element as keyof typeof HoldingTypeGroup];
        if (
          _holdingCategories.some(category => category.type === enumElement)
        ) {
          const newCategory = _holdingCategories.find(
            c => c.type === enumElement,
          );
          if (newCategory) {
            newCategory.holdings = [];
          }
        } else {
          _holdingCategories.push(new HoldingCategory(enumElement, 0, 0, []));
        }
      }
      _holdings.forEach(holding => {
        const category = _holdingCategories.find(
          element =>
            element.type === getHoldingTypeGroup(holding.asset.assetTypeGroup!),
        );
        if (category) {
          category.holdings.push(holding);
        }
        _assetIdToHoldingMap.set(holding.asset.id!, holding);
      });
      _holdingCategories = _holdingCategories.filter(
        element => element.holdings.length > 0,
      );
      _holdingCategories.forEach(element => {
        element.allocation = (element.value / _totalPortfolioValue) * 100.0;
        const yesterdayValue = element.value - element.valueChange;
        if (yesterdayValue > 0) {
          element.dayReturn = (element.valueChange / yesterdayValue) * 100.0;
        }
      });
      _holdingCategories.sort((a, b) => b.value - a.value);
      _holdingCategoriesMap = new Map(
        _holdingCategories.map(category => [
          HoldingTypeGroupInfo.apiValue[category.type],
          category,
        ]),
      );
      // Update wallet values
      const walletHoldingGroupsByWalletId = groupBy(
        _walletHoldings,
        walletHolding => walletHolding.userWalletId!,
      );
      _wallets = _wallets.map(wallet => {
        const walletHoldingGroup =
          walletHoldingGroupsByWalletId.get(wallet.id!) || [];
        const totalWalletValue = walletHoldingGroup.reduce(
          (sum, item) => sum + (item.value || 0),
          0,
        );
        const walletAllocation =
          (totalWalletValue / _totalPortfolioValue) * 100.0;
        return wallet.copyWith({
          totalValue: totalWalletValue,
          portfolioAllocation: walletAllocation,
        });
      });

      _wallets.sort((a, b) => (b.totalValue || 0) - (a.totalValue || 0));
      _walletHoldings.sort((a, b) => (b.value || 0) - (a.value || 0));
      _walletNFTItems.sort((a, b) => (b.priceUsd || 0) - (a.priceUsd || 0));
      _nftCollections.sort((a, b) => (b.totalValue || 0) - (a.totalValue || 0));

      // sort by value change to get top gainer
      _holdings.sort((a, b) => (b.valueChange || 0) - (a.valueChange || 0));
      if (_holdings.length > 0) {
        _bestPerformance = _holdings[0];
      }

      // sort by amount to get the biggest position
      _holdings.sort((a, b) => (b.amount || 0) - (a.amount || 0));
      if (_holdings.length > 0) {
        _biggestPosition = _holdings[0];
      }

      _portfolioExists = _holdings.length > 0;

      // Append current holding category value to charts
      _portfolioHoldingsChartDataMap.forEach((seriesData, series) => {
        seriesData.forEach((chartData, timePeriod) => {
          const lastDatapoint =
            chartData.datapoints[chartData.datapoints.length - 1];
          const seriesDataMap = _portfolioHoldingsChartDataMap.get(series);
          if (seriesDataMap && lastDatapoint) {
            const timePeriodData = seriesDataMap.get(timePeriod);
            if (timePeriodData) {
              if (series === 'all') {
                timePeriodData.datapoints[chartData.datapoints.length - 1] =
                  lastDatapoint.copyWith({ value: _totalPortfolioValue });
              } else {
                const seriesCategory = _holdingCategoriesMap.get(series);
                if (seriesCategory) {
                  timePeriodData.datapoints[chartData.datapoints.length - 1] =
                    lastDatapoint.copyWith({
                      value: _holdingCategoriesMap.get(series)!.value,
                    });
                }
              }
              timePeriodData.priceGain =
                timePeriodData.datapoints[chartData.datapoints.length - 1]
                  .value - timePeriodData.datapoints[0].value;
              timePeriodData.value =
                timePeriodData.datapoints[
                  chartData.datapoints.length - 1
                ].value;
              timePeriodData.priceGainPercent =
                timePeriodData.datapoints[0].value === 0
                  ? 100
                  : (timePeriodData.priceGain /
                      timePeriodData.datapoints[0].value) *
                    100;
            }
          }
        });
      });

      // Append current holding value to charts
      _assetHoldingChartDataMap.forEach((chartData, assetId) => {
        let holding = _assetIdToHoldingMap.get(assetId);
        if (holding) {
          if (chartData.day1Items && chartData.day1Items.length > 0) {
            let lastDatapoint =
              chartData.day1Items[chartData.day1Items.length - 1];
            chartData.day1Items[chartData.day1Items.length - 1] =
              lastDatapoint.copyWith({
                price: holding.price,
                quantity: holding.quantity,
                value: holding.amount,
              });
          }

          if (chartData.week1Items && chartData.week1Items.length > 0) {
            let lastDatapoint =
              chartData.week1Items[chartData.week1Items.length - 1];
            chartData.week1Items[chartData.week1Items.length - 1] =
              lastDatapoint.copyWith({
                price: holding.price,
                quantity: holding.quantity,
                value: holding.amount,
              });
          }

          if (chartData.month1Items && chartData.month1Items.length > 0) {
            let lastDatapoint =
              chartData.month1Items[chartData.month1Items.length - 1];
            chartData.month1Items[chartData.month1Items.length - 1] =
              lastDatapoint.copyWith({
                price: holding.price,
                quantity: holding.quantity,
                value: holding.amount,
              });
          }
          if (chartData.month3Items && chartData.month3Items.length > 0) {
            let lastDatapoint =
              chartData.month3Items[chartData.month3Items.length - 1];
            chartData.month3Items[chartData.month3Items.length - 1] =
              lastDatapoint.copyWith({
                price: holding.price,
                quantity: holding.quantity,
                value: holding.amount,
              });
          }
          if (chartData.year1Items && chartData.year1Items.length > 0) {
            let lastDatapoint =
              chartData.year1Items[chartData.year1Items.length - 1];
            chartData.year1Items[chartData.year1Items.length - 1] =
              lastDatapoint.copyWith({
                price: holding.price,
                quantity: holding.quantity,
                value: holding.amount,
              });
          }

          if (chartData.all && chartData.all.length > 0) {
            let lastDatapoint = chartData.all[chartData.all.length - 1];
            chartData.all[chartData.all.length - 1] = lastDatapoint.copyWith({
              price: holding.price,
              quantity: holding.quantity,
              value: holding.amount,
            });
          }
        }
      });

      // Append current nft value to charts
      _nftHoldingChartDataMap.forEach((chartData, nftItemId) => {
        const walletNFTItem = _nftItemToWalletNFTItemMap.get(nftItemId);

        if (walletNFTItem) {
          if (chartData.day1Items && chartData.day1Items.length > 0) {
            const lastDatapoint =
              chartData.day1Items[chartData.day1Items.length - 1];
            _nftHoldingChartDataMap.get(nftItemId)!.day1Items![
              chartData.day1Items.length - 1
            ] = lastDatapoint.copyWith({
              priceUsd: walletNFTItem.priceUsd,
            });
          }
          if (chartData.week1Items && chartData.week1Items.length > 0) {
            const lastDatapoint =
              chartData.week1Items[chartData.week1Items.length - 1];
            _nftHoldingChartDataMap.get(nftItemId)!.week1Items![
              chartData.week1Items.length - 1
            ] = lastDatapoint.copyWith({
              priceUsd: walletNFTItem.priceUsd,
            });
          }
          if (chartData.month1Items && chartData.month1Items.length > 0) {
            const lastDatapoint =
              chartData.month1Items[chartData.month1Items.length - 1];
            _nftHoldingChartDataMap.get(nftItemId)!.month1Items![
              chartData.month1Items.length - 1
            ] = lastDatapoint.copyWith({
              priceUsd: walletNFTItem.priceUsd,
            });
          }
          if (chartData.month3Items && chartData.month3Items.length > 0) {
            const lastDatapoint =
              chartData.month3Items[chartData.month3Items.length - 1];
            _nftHoldingChartDataMap.get(nftItemId)!.month3Items![
              chartData.month3Items.length - 1
            ] = lastDatapoint.copyWith({
              priceUsd: walletNFTItem.priceUsd,
            });
          }
          if (chartData.year1Items && chartData.year1Items.length > 0) {
            const lastDatapoint =
              chartData.year1Items[chartData.year1Items.length - 1];
            _nftHoldingChartDataMap.get(nftItemId)!.year1Items![
              chartData.year1Items.length - 1
            ] = lastDatapoint.copyWith({
              priceUsd: walletNFTItem.priceUsd,
            });
          }
        }
      });

      // Append current wallets value to charts
      _walletsMap = new Map();
      _wallets.forEach(wallet => {
        _walletsMap.set(wallet.id!, wallet);
      });
      Object.entries(_portfolioWalletsChartDataMap).forEach(
        ([walletId, seriesData]) => {
          Object.entries(seriesData).forEach(([timePeriod, chartData]) => {
            const lastDatapoint = (chartData as PortfolioChartData).datapoints[
              (chartData as PortfolioChartData).datapoints.length - 1
            ];
            const wallet = _walletsMap.get(walletId);
            if (wallet) {
              const currentSeriesData =
                _portfolioWalletsChartDataMap.get(walletId);
              if (currentSeriesData) {
                const currentChartData = currentSeriesData.get(
                  parseInt(timePeriod),
                );
                if (currentChartData) {
                  currentChartData.datapoints[
                    (chartData as PortfolioChartData).datapoints.length - 1
                  ] = lastDatapoint.copyWith({ value: wallet.totalValue });
                }
              }
            }
          });
        },
      );

      _showBrokenAccountsBanner = _wallets.some(
        element => element.readyStatus === 'error',
      );
      _lastSyncTimestamp = new Date();

      // set values

      // Raw data (response from API)
      portfolioSyncResponseData.current = portfolioSyncResponse;
      assetPricesResponseData.current = assetPricesResponse;
      nftChainPricesResponseData.current = nftChainPricesResponse;

      // Items
      setHiddenAssets(_hiddenAssets);
      setCustomNFTPricesMap(_customNFTPricesMap);

      setElementAsset(_elementAsset);
      setAssetsMap(_assetsMap);
      setManualAssetsMap(_manualAssetsMap);
      setNFTItemsMap(_nftItemsMap);
      setHiddenAssetIds(_hiddenAssetIds);

      // Wallets
      setWallets(_wallets);
      setWalletHoldings(_walletHoldings);
      setWalletNFTItems(_walletNFTItems);
      setElementNFTItems(_elementNFTItems);
      setAssetIdToWalletNFTItemsMap(_assetIdToWalletNFTItemsMap);
      setWalletsMap(_walletsMap);

      // Aggregate
      setHoldings(_holdings);
      setNFTCollections(_nftCollections);
      setNFTItems(_nftItems);
      setVerifiedAssetIds(_verifiedAssetIds);

      // Display

      // Main dashboard
      setBestPerformance(_bestPerformance);
      setBiggestPosition(_biggestPosition);
      setHoldingCategories(_holdingCategories);
      setHoldingCategoriesMap(_holdingCategoriesMap);
      setActiveHoldingCategory(_activeHoldingCategory);
      setTotalPortfolioValue(_totalPortfolioValue);
      setPreviousTotalPortfolioValue(_previousTotalPortfolioValue);
      setPortfolioExists(_portfolioExists);
      setLastSyncTimestamp(_lastSyncTimestamp);

      setPortfolioHoldingsChartData(_portfolioHoldingsChartData);
      setPortfolioHoldingsChartDataMap(_portfolioHoldingsChartDataMap);
      setPortfolioWalletsChartDataMap(_portfolioWalletsChartDataMap);
      setAssetHoldingChartDataMap(_assetHoldingChartDataMap);
      setNFTHoldingChartDataMap(_nftHoldingChartDataMap);

      // Asset screen
      setAssetIdToHoldingMap(_assetIdToHoldingMap);
      setNFTItemToWalletNFTItemMap(_nftItemToWalletNFTItemMap);

      // Wallet screen
      setShowBrokenAccountsBanner(_showBrokenAccountsBanner);
    } catch (error) {}
  };

  const addNewManualWallet = async (name: string): Promise<any> => {
    const manualWallet = await createManualWallet(name);
    await load({ checkCache: false });

    return manualWallet;
  };

  const updateWalletName = async (
    walletId: string,
    name: string,
  ): Promise<void> => {
    await updateWallet(walletId, name);
    await load({ checkCache: false });
  };

  const removeWallet = async (walletId: string): Promise<void> => {
    await deleteWallet(walletId);
    await load({ checkCache: false });
  };

  const syncWallet = async (walletId: string): Promise<void> => {
    try {
      await syncManualWallet(walletId);
      await load({ checkCache: false });
    } catch (error) {}
  };

  return (
    <PortfolioContext.Provider
      value={{
        load,
        clearData,
        fetchPortfolioSeriesChart,
        fetchAssetHoldingSeriesChart,
        fetchNFTHoldingSeriesChart,
        fetchAssetPriceChart,
        backgroundLoadAssetPrices,
        backgroundLoadNFTChainPrices,

        // Items
        hiddenAssets,
        customNFTPricesMap,
        assetsMap,
        manualAssetsMap,
        nftItemsMap,
        hiddenAssetIds,
        elementNFTItems,

        // Wallets
        wallets,
        walletHoldings,
        walletNFTItems,
        assetIdToWalletNFTItemsMap,
        walletsMap,
        assetIdToWalletHoldings,
        // Aggregate
        holdings,
        nftCollections,
        nftItems,
        verifiedAssetIds,

        // Display

        // Main dashboard
        bestPerformance,
        biggestPosition,
        holdingCategories,
        holdingCategoriesMap,
        activeHoldingCategory,
        totalPortfolioValue,
        previousTotalPortfolioValue,
        portfolioExists,
        lastSyncTimestamp,

        // Charts
        // portfolioChartSelectedDays,
        isPortfolioSeriesChartFetching,
        // isWalletSeriesChartFetching,
        isAssetSeriesChartFetching,
        isNFTSeriesChartFetching,
        isAssetPriceChartFetching,
        portfolioHoldingsChartData,
        portfolioHoldingsChartDataMap,
        portfolioWalletsChartDataMap,
        assetHoldingChartDataMap,
        nftHoldingChartDataMap,
        assetPriceChartMap,

        // Asset screen
        assetIdToHoldingMap,
        nftItemToWalletNFTItemMap,

        // Wallet screen
        showBrokenAccountsBanner,
        syncWallet,
        updateWalletName,
        removeWallet,
        addNewManualWallet,

        // Activity screen
        fetchWalletTransactions,
        walletTransactions,

        isLoading,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};

function groupBy<T>(
  list: T[],
  keyGetter: (input: T) => string,
): Map<string, T[]> {
  const map = new Map<string, T[]>();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
