export const NavHomeIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/linear/home">
      <g id="home">
        <path
          id="Vector"
          d="M14 20.9965V17.4965"
          strokeWidth="2.3434"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M16.2514 3.28927L24.3348 9.76298C25.2446 10.4862 25.8278 12.0142 25.6295 13.1573L24.0781 22.4421C23.7982 24.0985 22.2118 25.4399 20.5322 25.4399H7.46811C5.8001 25.4399 4.20209 24.0868 3.92215 22.4421L2.37079 13.1573C2.18416 12.0142 2.76737 10.4862 3.66553 9.76298L11.7489 3.30093C12.997 2.2978 15.0149 2.2978 16.2514 3.28927Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
