// vendor libraries
import { createContext, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { formatNumber } from '../utils/number';

import { useCurrencyVisibleState } from '../recoil/currencyVisible';

import { updateCurrentUserWebDevice, completeOnboarding } from '../api/user';

// apis
import {
  postAuthSignup,
  postLoginUser,
  postLogout,
  postOAuthLogin,
} from '../api/auth';
import {
  getAllCurrencies,
  getCurrentUser,
  postUserCurrency,
} from '../api/user';

// recoil
import { useResetTokenState, useTokenState } from '../recoil/token';

interface AuthContextValue {
  currencies: any[];
  login: (
    email: string,
    password: string,
  ) => Promise<{ status: string; message: string }>;
  logout: (navigateToLogin?: boolean) => Promise<void>;
  signup: (
    email: string,
    password: string,
  ) => Promise<{ status: string; message: string; data?: any }>;
  isLoading: boolean;
  fetchUserProfile: () => Promise<void>;
  updateUserCurrency: (currency: object) => Promise<void>;
  fetchAllCurrencies: () => Promise<void>;
  user: any;
  isLoggedIn: boolean;
  convertUSDCurrency: (usdValue: number | undefined) => string;
  oAuthLogin: (
    token: string,
    platform: string,
    walletDataParams: any,
  ) => Promise<{ status: string; message: string; data?: any }>;
  setUser: (user: any) => void;
  updateDevicePWAData: () => Promise<void>;
}

export const AuthContext = createContext({} as AuthContextValue);

export function useAuth(): AuthContextValue {
  return useContext(AuthContext);
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [token, setToken] = useTokenState();
  const resetToken = useResetTokenState();
  const [currencyVisible] = useCurrencyVisibleState();

  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = async (
    email: string,
    password: string,
  ): Promise<{ status: string; message: string }> => {
    setIsLoading(true);
    return postLoginUser(email, password)
      .then(async res => {
        if (res.status === 200 && res.data.access_token) {
          setUser(res.data.user);
          setToken(res.data.access_token);
          setIsLoggedIn(true);
          setIsLoading(false);
          bootIntercom(
            res.data.user.id,
            res.data.user.email,
            res.data.user.member_number,
            res.data.user.member_status,
          );
          if (res.data.user.status === 'onboard') {
            await completeOnboarding();
          }
          navigate('/portfolio');

          return { status: 'ok', message: 'success' };
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('walletData');
          setUser('');
          setIsLoggedIn(false);
          setIsLoading(false);
          return {
            status: 'error',
            message: 'Email or password is invalid',
          };
        }
      })
      .catch(err => {
        toast.error(err?.response?.data?.detail || 'Something went wrong');
        setIsLoading(false);
        return {
          status: 'error',
          message: 'Email or password is invalid',
        };
      });
  };

  const logout = async (navigateToLogin = true) => {
    window.Intercom('shutdown');
    if (token) {
      await postLogout(token);
      resetToken();
      setUser('');
      setIsLoggedIn(false);
      setIsLoading(false);
    }
    if (navigateToLogin) {
      window.location.href = `${process.env.REACT_APP_AUTH_URL}/login`;
    } else {
      window.location.href = `${process.env.REACT_APP_AUTH_URL}/signup`;
    }
  };

  const signup = async (
    email: string,
    password: string,
    signup_invite_code?: string,
  ): Promise<{ status: string; message: string; data?: any }> => {
    const formValues: {
      email: string;
      password: string;
      signup_invite_code?: string;
      signup_source?: string;
    } = { email, password, signup_source: 'web' };
    if (signup_invite_code) {
      formValues.signup_invite_code = signup_invite_code;
    }
    setIsLoading(true);
    return postAuthSignup(formValues)
      .then(async res => {
        if (res.status === 200 && res.data.access_token) {
          setToken(res.data.access_token);
          setUser(res.data.user);
          setIsLoggedIn(true);
          setIsLoading(false);
          bootIntercom(
            res.data.user.id,
            res.data.user.email,
            res.data.user.member_number,
            res.data.user.member_status,
          );
          return { status: 'ok', message: 'success', data: res.data };
        } else {
          return {
            status: 'error',
            message: 'Something went wrong',
          };
        }
      })
      .catch(err => {
        setIsLoading(false);

        return {
          status: 'error',
          message: err?.response?.data?.detail || 'Something went wrong',
        };
      });
  };

  const fetchUserProfile = async () => {
    try {
      const resp = await getCurrentUser();
      if (resp.status === 200) {
        setUser(resp.data);
        bootIntercom(
          resp.data.id,
          resp.data.email,
          resp.data.member_number,
          resp.data.member_status,
        );
        setIsLoggedIn(true);
        if (resp.data.status === 'onboard') {
          await completeOnboarding();
        }
      } else {
        setUser('');
        setIsLoggedIn(false);
        resetToken();
        navigate('/sign-up');
      }
    } catch (err) {
      setUser('');
      setIsLoggedIn(false);
      resetToken();
      navigate('/sign-up');
    }
  };

  const fetchAllCurrencies = async () => {
    try {
      const resp = await getAllCurrencies();
      if (resp.status === 200) {
        setCurrencies(resp.data.currencies);
      }
    } catch (error) {}
  };

  const updateUserCurrency = async currency => {
    setUser({ ...user, currency });
    try {
      await postUserCurrency({ currency_id: currency.id });
    } catch (error) {}
  };

  const convertUSDCurrency = (usdValue?: number) => {
    const { exchange_rate, symbol, symbol_location } = user.currency || {};
    if (typeof usdValue !== 'number') return `${symbol}0`;

    const value = Math.abs(
      Number(usdValue) / (exchange_rate ? exchange_rate : 1),
    );
    const formattedValue = currencyVisible ? formatNumber(value) : '****.****';
    const sign = usdValue >= 0 ? '' : '-';

    switch (symbol_location) {
      case 'back':
        return `${sign}${formattedValue} ${symbol || '$'}`;
      case 'front':
        if (formattedValue[0] === '<') {
          return `< ${sign}${symbol || '$'}${formattedValue.slice(2)}`;
        } else {
          return `${sign}${symbol || '$'}${formattedValue}`;
        }
      default:
        return `${sign}$${formattedValue}`;
    }
  };

  const oAuthLogin = async (
    token: string,
    platform: string,
    walletDataParams: any,
  ) => {
    setIsLoading(true);
    return postOAuthLogin(token, platform, walletDataParams)
      .then(async res => {
        if (res.status === 200 && res.data.access_token) {
          setUser(res.data.user);
          setToken(res.data.access_token);
          setIsLoggedIn(true);
          setIsLoading(false);
          bootIntercom(
            res.data.user.id,
            res.data.user.email,
            res.data.user.member_number,
            res.data.user.member_status,
          );
          return { status: 'ok', message: 'success', data: res.data };
        } else {
          setIsLoading(false);
          navigate('/login');
          return { status: 'error', message: 'Unable to Login' };
        }
      })
      .catch(err => {
        setIsLoading(false);
        toast.error(err?.response?.data?.detail);
        return { status: 'error', message: err?.response?.data?.detail };
      });
  };

  const updateDevicePWAData = async () => {
    progressier.add({
      assetdash_id: user.id,
    });
    const subscribed = await progressier.push.isSubscribed();
    await updateCurrentUserWebDevice({
      progressier_id: progressier.user.id,
      pwa_installed: progressier.native.installed,
      is_mobile: progressier.utils.isMobile(),
      push_subscribed: subscribed,
    });
  };

  const bootIntercom = (
    userId: string,
    email: string,
    memberNumber: string,
    memberStatus: string,
  ) => {
    window.Intercom('boot', {
      app_id: 'cw5h5egq',
      custom_launcher_selector: '.intercom-launcher',
      hide_default_launcher: true,
      user_id: userId,
      email: email,
      custom_attributes: {
        member_number: memberNumber,
        member_status: memberStatus,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        currencies,
        login,
        logout,
        signup,
        fetchUserProfile,
        user,
        isLoggedIn,
        updateUserCurrency,
        fetchAllCurrencies,
        convertUSDCurrency,
        oAuthLogin,
        setUser,
        updateDevicePWAData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
