// vendor libraries
import { useState, useEffect } from 'react';

// hooks
import { useGames } from '@/hooks/useGames';

// components
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';
import { BannerCarousel } from '../common/Banner/BannerCarousel';
import { FortuneCookieCard } from './FortuneCookieCard';
import { DashDropCard } from './DashDropCard';
import { ElementsBanner } from './ElementsBanner';
import { GiveawayCard } from './GiveawayCard';
import { OptionsTab } from '@/components/v2/common/Tab/OptionsTab';
import { MyGiveawayUserPrize } from '@/api/games';
import { MyPrizeGiveawayCard } from './MyPrizeGiveawayCard';
export const GamesContainer: React.FC = () => {
  const {
    loadGamesDashboardBanners,
    loadGiveaways,
    loadArcadeGameTags,
    loadArcadeGames,
    loadPartnersGameTags,
    loadPartnersGames,
    loadPartnersStores,

    // values
    giveaways,
    giveawayMyPrizes,
    gamesDashboardBanners,
  } = useGames();

  const [isLoading, setIsLoading] = useState(true);

  const dropsTabs = {
    drops: 'Drops',
    my_prizes: 'My Prizes',
  };

  const [activeTab, setActiveTab] = useState('drops');

  const onTabClick = (key: string) => {
    setActiveTab(key);
  };

  const [myGiveawayUserPrizes, setMyGiveawayUserPrizes] = useState<
    MyGiveawayUserPrize[]
  >([]);

  useEffect(() => {
    if (giveawayMyPrizes) {
      setMyGiveawayUserPrizes(giveawayMyPrizes);
    }
  }, [giveawayMyPrizes]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await loadGamesDashboardBanners();
        await loadGiveaways();
        await loadArcadeGameTags();
        await loadArcadeGames();
        await loadPartnersGameTags();
        await loadPartnersGames();
        await loadPartnersStores();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-2 py-4 space-y-4">
      {isLoading ? (
        <SkeletonLoader height={800} />
      ) : (
        <>
          <div className="w-full">
            <ElementsBanner />
          </div>
          {gamesDashboardBanners.length > 0 && (
            <BannerCarousel banners={gamesDashboardBanners} />
          )}
          <h1 className="text-3xl">Popular</h1>
          <div className="flex overflow-x-auto flex-row space-x-4 items-start">
            <FortuneCookieCard />
            <DashDropCard />
          </div>
          {
            <div className="w-full space-y-4">
              <OptionsTab
                tabs={dropsTabs}
                activeTab={activeTab}
                onClick={onTabClick}
              />
              <div className="w-full overflow-x-auto scrollbar">
                {activeTab === 'drops' ? (
                  giveaways.length > 0 ? (
                    <div className="flex flex-row space-x-4 items-start">
                      {giveaways.map(giveaway => (
                        <GiveawayCard key={giveaway.id} giveaway={giveaway} />
                      ))}
                    </div>
                  ) : (
                    <div>No Super Drops</div>
                  )
                ) : myGiveawayUserPrizes.length > 0 ? (
                  <div className="flex flex-row space-x-4 items-start">
                    {myGiveawayUserPrizes.map(userPrize => (
                      <MyPrizeGiveawayCard
                        key={userPrize.pass_code}
                        prize={userPrize}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center">
                    <img
                      className="w-1/4"
                      src="/assets/images/giveway_deals.png"
                      alt="giveway deals"
                    />
                    <div className="font-bold text-lg text-center">
                      Results are not in yet!
                    </div>
                  </div>
                )}
              </div>
            </div>
          }
        </>
      )}
    </div>
  );
};
