import moment from 'moment';

import { GoldSpendingJson } from '../../../api/gold';

class GoldSpendingsResponse {
  goldSpendings?: GoldSpending[];

  constructor(goldSpendings?: GoldSpending[]) {
    this.goldSpendings = goldSpendings;
  }

  static fromJson(json: {
    gold_spendings: GoldSpendingJson[];
  }): GoldSpendingsResponse {
    const goldSpendings = json.gold_spendings.map(v =>
      GoldSpending.fromJson(v),
    );
    return new GoldSpendingsResponse(goldSpendings);
  }

  toJson(): { gold_spendings: GoldSpendingJson[] } {
    return {
      gold_spendings: this.goldSpendings
        ? this.goldSpendings.map(v => v.toJson())
        : [],
    };
  }
}

class GoldSpending {
  created: Date;
  spendingType: string;
  spendingDescription: string;
  goldSpent: number;

  constructor(
    created: Date,
    spendingType: string,
    spendingDescription: string,
    goldSpent: number,
  ) {
    this.created = created;
    this.spendingType = spendingType;
    this.spendingDescription = spendingDescription;
    this.goldSpent = goldSpent;
  }

  static fromJson(json: GoldSpendingJson): GoldSpending {
    return new GoldSpending(
      new Date(json.created),
      json.spending_type,
      json.spending_description,
      json.gold_spent ?? 0,
    );
  }

  toJson(): GoldSpendingJson {
    return {
      created: this.created.toISOString(),
      spending_type: this.spendingType,
      spending_description: this.spendingDescription,
      gold_spent: this.goldSpent,
    };
  }

  getCreatedDay(): string {
    return moment(this.created).format('MMM DD, yyyy');
  }
}

export { GoldSpendingsResponse, GoldSpending };
