import { axiosAssetDashApi } from './api';

export interface GoldCenterJson {
  total_gold?: number | null;
  liquid_gold?: number | null;
  locked_gold?: number | null;
  bid_gold?: number | null;
  spent_gold?: number | null;
}

export interface GoldCenterResponse {
  gold_center: GoldCenterJson;
}

export const getUserGoldCenter = async (): Promise<{
  data: GoldCenterResponse;
}> => {
  return await axiosAssetDashApi.get('/api/api_v5/users/gold-center');
};

export interface GoldDistributionsResponse {
  gold_distributions: GoldDistributionJson[];
}

export interface GoldDistributionJson {
  created: string;
  distribution_type: string;
  distribution_description: string;
  gold_multiplier: number;
  gold_multiplier_original?: number;
  gold_original: number;
  gold_total: number;
  unlock_date: string;
  lock_status: string;
}

export const getGoldDistributions = async (
  sort_by: string,
  filter_by: string,
  page: number,
): Promise<{
  data: GoldDistributionsResponse;
}> => {
  const params = {
    sort_by,
    filter_by,
    page,
  };
  return await axiosAssetDashApi.get(
    '/api/api_v5/rewards/gold/gold-distributions',
    {
      params,
    },
  );
};

export interface GoldSpendingsResponse {
  gold_spendings: GoldSpendingJson[];
}

export interface GoldSpendingJson {
  created: string;
  spending_type: string;
  spending_description: string;
  gold_spent?: number;
}

export const getGoldSpendings = async (): Promise<{
  data: GoldSpendingsResponse;
}> => {
  return await axiosAssetDashApi.get('/api/api_v5/rewards/gold/gold-spendings');
};
