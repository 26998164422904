// vendor libraries
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

// api
import { Giveaway } from '@/api/games';

// components
import { Countdown } from '@/components/v2/common/Countdown/Countdown';

// hooks
import { useGames } from '@/hooks/useGames';

interface GiveawayCardProps {
  giveaway: Giveaway;
}

export const GiveawayCard: React.FC<GiveawayCardProps> = ({ giveaway }) => {
  const navigate = useNavigate();
  const { isGiveawayOpen, isGiveawayNotLiveYet } = useGames();

  return (
    <div
      onClick={() => {
        navigate(`/games/super-drops/${giveaway.id}`);
      }}
      className={`cursor-pointer h-96 w-56 p-4 flex flex-col justify-between card`}
    >
      <div className="md:col-span-2 lg:col-span-2 mb-2 rounded-13 h-max">
        <img
          src={giveaway.image_url!}
          className="min-w-42 h-42 object-contain mx-auto"
          alt="logo"
        />
      </div>
      <div className="space-y-1">
        <div className="text-md leading-17 font-semibold text-ellipsis whitespace-nowrap overflow-hidden">
          {giveaway.title}
        </div>
        <div className="text-xs text-gray-text h-16 card-description-multiline-truncate">
          {giveaway.description}
        </div>
      </div>
      <div className="space-y-1">
        {isGiveawayOpen(giveaway) ? (
          <div className="flex justify-center w-full px-4 py-1 border-1 bg-opacity-10 rounded-2xl bg-price-green border-price-green text-price-green">
            <Countdown targetTime={moment(giveaway.end_time + 'Z')} />
          </div>
        ) : isGiveawayNotLiveYet(giveaway) ? (
          <div className="flex justify-center w-full px-4 py-1 border-1 bg-opacity-10 rounded-2xl bg-gray-text border-gray-text text-gray-text">
            Not Live Yet
          </div>
        ) : (
          <div className="flex justify-center w-full px-4 py-1 border-1 bg-opacity-10 rounded-2xl bg-gray-text border-gray-text text-gray-text">
            Completed
          </div>
        )}
      </div>
    </div>
  );
};
