import moment from 'moment';

import { createContext, useContext, useState } from 'react';
import { AxiosResponse } from 'axios';
import { toast } from 'react-hot-toast';

import { useConfetti } from '../hooks/useConfetti';

import { DEFAULT_ERROR_MESSAGE } from '../features/core/constants/constants';

import {
  getGamesDashboardBanners,
  getGamesPartnersTagsList,
  getGamesPartnersGamesList,
  getGamesPartnersGame,
  getGamesPartnerGameStoresList,
  getGamesPartnersStore,
  getGiveawayDetails,
  getGiveawayMyDetails,
  getGiveawayPassPurchases,
  getGiveawayPassLeaderboards,
  getGiveawayUserPrizes,
  GamesPartnersTag,
  PartnerGame,
  PartnerGameStore,
  PartnerGamePass,
  GiveawayPassPurchase,
  GiveawayPassLeaderboard,
  MyGiveawayUserPrize,
  postGamesBuyPartnerGamePass,
  getGiveaways,
  Giveaway,
  GiveawayUserPrize,
  postBuyGiveawayPasses,
  getArcadeGameTags,
  getArcadeGames,
  getArcadeGameDetail,
  ArcadeGameTag,
  ArcadeGame,
  getGiveawayMyPrizes,
} from '../api/games';

import {
  BannersResponse,
  DashboardBanner,
} from '../features/core/models/Banner';

interface GamesContextProps {
  gamesDashboardBanners: DashboardBanner[];
  gamesPartnersTags: GamesPartnersTag[];
  gamesPartnersGamesList: PartnerGame[];
  gamesPartnersGameDetails?: {
    partner_game: PartnerGame;
    partner_game_store: PartnerGameStore;
    partner_game_pass?: PartnerGamePass;
  };
  gamesPartnersStoresList: PartnerGameStore[];
  gamesPartnersStoreDetails?: {
    partner_game_store: PartnerGameStore;
    partner_games: PartnerGame[];
  };
  giveaways: Giveaway[];
  giveawayMyPrizes: MyGiveawayUserPrize[];
  giveawayDetails?: Giveaway;
  giveawayMyDetails?: {
    giveaway_passes_count: number;
    giveaway_user_prizes: MyGiveawayUserPrize[];
  };
  giveawayPassPurchases: GiveawayPassPurchase[];
  giveawayPassLeaderboards: GiveawayPassLeaderboard[];
  giveawayUserPrizes: GiveawayUserPrize[];
  loadGamesDashboardBanners: () => Promise<void>;
  loadPartnersGameTags: () => Promise<void>;
  loadPartnersGames: (tag?: string) => Promise<void>;
  loadGamesPartnersGame: (gameListingId: string) => Promise<void>;
  loadPartnersStores: () => Promise<void>;
  loadGamesPartnersStore: (gameStoreId: string) => Promise<void>;
  postGamesBuyPartnerGamePass: (
    partnerGameId: string,
  ) => Promise<AxiosResponse<{ partner_game_pass: PartnerGamePass }>>;
  loadGiveaways: () => Promise<void>;
  loadGiveaway: (giveawayId: string) => Promise<void>;
  buyGiveawayPasses: (giveawayId: string, amount: number) => Promise<void>;
  loadArcadeGameTags: () => Promise<void>;
  loadArcadeGames: (tag?: string) => Promise<void>;
  loadArcadeGameDetail: (arcadeGameId: string) => Promise<void>;
  isGiveawayOpen: (giveaway: Giveaway) => boolean;
  isGiveawayCompleted: (giveaway: Giveaway) => boolean;
  isGiveawayNotLiveYet: (giveaway: Giveaway) => boolean;
  arcadeGameTags: ArcadeGameTag[];
  arcadeGamesList: ArcadeGame[];
  arcadeGameDetail?: ArcadeGame;
}

export const GamesContext = createContext<GamesContextProps | undefined>(
  undefined,
);

export const useGames = (): GamesContextProps => {
  const context = useContext(GamesContext);
  if (!context) {
    throw new Error('useGames must be used within a GamesProvider');
  }
  return context;
};

export const GamesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setShowConfetti } = useConfetti();

  const [gamesDashboardBanners, setGamesDashboardBanners] = useState<
    DashboardBanner[]
  >([]);
  const [gamesPartnersTags, setGamesPartnersTags] = useState<
    GamesPartnersTag[]
  >([]);
  const [gamesPartnersGamesList, setGamesPartnersGamesList] = useState<
    PartnerGame[]
  >([]);
  const [gamesPartnersGameDetails, setGamesPartnersGameDetails] = useState<{
    partner_game: PartnerGame;
    partner_game_store: PartnerGameStore;
    partner_game_user_pass?: PartnerGamePass;
  }>();
  const [gamesPartnersStoresList, setGamesPartnersStoresList] = useState<
    PartnerGameStore[]
  >([]);
  const [gamesPartnersStoreDetails, setGamesPartnersStoreDetails] = useState<{
    partner_game_store: PartnerGameStore;
    partner_games: PartnerGame[];
  }>();
  const [giveaways, setGiveaways] = useState<Giveaway[]>([]);
  const [giveawayDetails, setGiveawayDetails] = useState<Giveaway>();
  const [giveawayMyDetails, setGiveawayMyDetails] = useState<{
    giveaway_passes_count: number;
    giveaway_user_prizes: MyGiveawayUserPrize[];
  }>();
  const [giveawayPassPurchases, setGiveawayPassPurchases] = useState<
    GiveawayPassPurchase[]
  >([]);
  const [giveawayPassLeaderboards, setGiveawayPassLeaderboards] = useState<
    GiveawayPassLeaderboard[]
  >([]);
  const [giveawayMyPrizes, setGiveawayMyPrizes] = useState<
    MyGiveawayUserPrize[]
  >([]);
  const [giveawayUserPrizes, setGiveawayUserPrizes] = useState<
    GiveawayUserPrize[]
  >([]);
  // arcade
  const [arcadeGameTags, setArcadeGameTags] = useState<ArcadeGameTag[]>([]);
  const [arcadeGamesList, setArcadeGamesList] = useState<ArcadeGame[]>([]);
  const [arcadeGameDetail, setArcadeGameDetail] = useState<ArcadeGame>();

  const loadGamesDashboardBanners = async () => {
    try {
      const response = await getGamesDashboardBanners();
      const bannersResponse = BannersResponse.fromJson(response.data);
      setGamesDashboardBanners(bannersResponse.banners || []);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadPartnersGameTags = async () => {
    try {
      const response = await getGamesPartnersTagsList();
      setGamesPartnersTags(response.data.tags);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadPartnersGames = async (tag?: string) => {
    try {
      const response = await getGamesPartnersGamesList(tag);
      setGamesPartnersGamesList(response.data.partner_games);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadGamesPartnersGame = async (gameListingId: string) => {
    try {
      const response = await getGamesPartnersGame(gameListingId);
      setGamesPartnersGameDetails(response.data);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadPartnersStores = async () => {
    try {
      const response = await getGamesPartnerGameStoresList();
      setGamesPartnersStoresList(response.data.partner_game_stores);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadGamesPartnersStore = async (gameStoreId: string) => {
    try {
      const response = await getGamesPartnersStore(gameStoreId);
      setGamesPartnersStoreDetails(response.data);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadGiveaways = async () => {
    try {
      const response = await getGiveaways();
      setGiveaways(response.data.giveaways);

      const giveawayMyPrizesResponse = await getGiveawayMyPrizes();
      setGiveawayMyPrizes(giveawayMyPrizesResponse.data.giveaway_my_prizes);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadGiveaway = async (giveawayId: string) => {
    const giveawayPromise = getGiveawayDetails(giveawayId);
    const giveawayMyDetailsPromise = getGiveawayMyDetails(giveawayId);
    const giveawayPassPurchasePromise = getGiveawayPassPurchases(giveawayId);
    const giveawayPassLeaderboardsPromise =
      getGiveawayPassLeaderboards(giveawayId);
    const giveawayUserPrizesPromise = getGiveawayUserPrizes(giveawayId);

    // Wait for all promises to settle
    const results = await Promise.allSettled([
      giveawayPromise,
      giveawayMyDetailsPromise,
      giveawayPassPurchasePromise,
      giveawayPassLeaderboardsPromise,
      giveawayUserPrizesPromise,
    ]);

    // Destructure results for clarity
    const [
      giveawayResult,
      giveawayMyDetailsResult,
      giveawayPassPurchaseResult,
      giveawayPassLeaderboardsResult,
      giveawayUserPrizesResult,
    ] = results;

    // Handle each result individually
    if (giveawayResult.status === 'fulfilled') {
      setGiveawayDetails(giveawayResult.value.data.giveaway);
    } else {
      console.error('Error fetching giveaway details:', giveawayResult.reason);
      toast.error('Failed to load giveaway details.');
    }

    if (giveawayMyDetailsResult.status === 'fulfilled') {
      setGiveawayMyDetails(giveawayMyDetailsResult.value.data);
    } else {
      console.error(
        'Error fetching your giveaway details:',
        giveawayMyDetailsResult.reason,
      );
      toast.error('Failed to load your giveaway details.');
    }

    if (giveawayPassPurchaseResult.status === 'fulfilled') {
      setGiveawayPassPurchases(
        giveawayPassPurchaseResult.value.data.giveaway_pass_purchases,
      );
    } else {
      console.error(
        'Error fetching pass purchases:',
        giveawayPassPurchaseResult.reason,
      );
      toast.error('Failed to load pass purchases.');
    }

    if (giveawayPassLeaderboardsResult.status === 'fulfilled') {
      setGiveawayPassLeaderboards(
        giveawayPassLeaderboardsResult.value.data.giveaway_pass_leaderboards,
      );
    } else {
      console.error(
        'Error fetching pass leaderboards:',
        giveawayPassLeaderboardsResult.reason,
      );
      toast.error('Failed to load pass leaderboards.');
    }

    if (giveawayUserPrizesResult.status === 'fulfilled') {
      setGiveawayUserPrizes(
        giveawayUserPrizesResult.value.data.giveaway_user_prizes,
      );
    } else {
      console.error(
        'Error fetching user prizes:',
        giveawayUserPrizesResult.reason,
      );
      toast.error('Failed to load user prizes.');
    }
  };

  const buyGiveawayPasses = async (giveawayId: string, amount: number) => {
    try {
      await postBuyGiveawayPasses(giveawayId, amount);
      await loadGiveaway(giveawayId);
      setShowConfetti(true);
      toast.success('Super giveaway passes purchased!');
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadArcadeGameTags = async () => {
    try {
      const response = await getArcadeGameTags();
      setArcadeGameTags(response.data.tags);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadArcadeGames = async (tag?: string) => {
    try {
      const response = await getArcadeGames(tag);
      setArcadeGamesList(response.data.arcade_games);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const loadArcadeGameDetail = async (arcadeGameId: string) => {
    try {
      const response = await getArcadeGameDetail(arcadeGameId);
      setArcadeGameDetail(response.data.arcade_game);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const isGiveawayOpen = (giveaway: Giveaway): boolean => {
    return (
      giveaway.status === 'open' ||
      (moment(giveaway.start_time + 'Z').isBefore(moment()) &&
        moment(giveaway.end_time + 'Z').isAfter(moment()))
    );
  };

  const isGiveawayCompleted = (giveaway: Giveaway): boolean => {
    return (
      giveaway.status === 'in_progress' ||
      giveaway.status === 'closed' ||
      moment(giveaway.end_time + 'Z').isBefore(moment())
    );
  };

  const isGiveawayNotLiveYet = (giveaway: Giveaway): boolean => {
    return giveaway.status === 'not_live_yet';
  };

  return (
    <GamesContext.Provider
      value={{
        // methods
        loadGamesDashboardBanners,
        loadPartnersGameTags,
        loadPartnersGames,
        loadGamesPartnersGame,
        loadPartnersStores,
        loadGamesPartnersStore,
        postGamesBuyPartnerGamePass,
        loadGiveaways,
        loadGiveaway,
        buyGiveawayPasses,
        loadArcadeGameTags,
        loadArcadeGames,
        loadArcadeGameDetail,

        // utils
        isGiveawayOpen,
        isGiveawayCompleted,
        isGiveawayNotLiveYet,

        // values
        gamesDashboardBanners,
        gamesPartnersTags,
        gamesPartnersGamesList,
        gamesPartnersGameDetails,
        gamesPartnersStoresList,
        gamesPartnersStoreDetails,
        giveaways,
        giveawayMyPrizes,
        giveawayDetails,
        giveawayMyDetails,
        giveawayPassPurchases,
        giveawayPassLeaderboards,
        giveawayUserPrizes,
        arcadeGameTags,
        arcadeGamesList,
        arcadeGameDetail,
      }}
    >
      {children}
    </GamesContext.Provider>
  );
};
