import { UserIcon } from '@heroicons/react/24/solid';

import { GiveawayPassLeaderboard } from '@/api/games';

interface GiveawayLeaderboardTabProps {
  giveawayPassLeaderboards: GiveawayPassLeaderboard[];
  giveawayPassesCount: number;
}

export const GiveawayLeaderboardTab: React.FC<GiveawayLeaderboardTabProps> = ({
  giveawayPassLeaderboards,
  giveawayPassesCount,
}) => {
  return (
    <div>
      <div className="flex justify-between p-4 border-b border-gray-text bg-price-green bg-opacity-20">
        <div className="flex items-center justify-between w-2/3">
          <div className="bg-white rounded-xl w-6 h-6 text-center text-black"></div>
          <div className="flex items-center justify-start space-x-1">
            <UserIcon height="20" width="20" />
            <div>Me</div>
          </div>
          <div></div>
        </div>
        <div className="text-price-green flex justify-end w-1/3">
          {giveawayPassesCount} pass
          {giveawayPassesCount > 1 && 'es'}
        </div>
      </div>
      {giveawayPassLeaderboards.map((leaderboard, i) => (
        <div className="flex justify-between p-4 border-b border-gray-text">
          <div className="flex items-center justify-between w-2/3">
            <div className="bg-white rounded-xl w-6 h-6 text-center text-black">
              {i + 1}
            </div>
            <div className="flex items-center justify-start space-x-1">
              <UserIcon height="20" width="20" />
              <div>{leaderboard.public_user_id}</div>
            </div>
            <div></div>
          </div>
          <div className="text-price-green flex justify-end w-1/3">
            {leaderboard.total_passes} pass
            {leaderboard.total_passes > 1 && 'es'}
          </div>
        </div>
      ))}
    </div>
  );
};
