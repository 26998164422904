import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';

// types
import { ExtendedColumn } from '../../common/Table/types';

// constants
import { SORT_ASC, SORT_DESC } from '@constants';

// components
import { AssetLogo } from '../../common/Logo/AssetLogo';
import { ComponentPlaceholder } from '../../common/Placeholder/ComponentPlaceholder';
import { LargeTable } from '../../common/Table/LargeTable';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';
import { useAuth } from '@/hooks/useAuth';

// utils
import { formatNumber, getNumberColor } from '@/utils/number';

// models
import { Holding } from '@/features/portfolio/models/Holding';
import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
} from '@heroicons/react/24/outline';

interface PortfolioHoldingsTableProps {
  holdingTypeGroup: string;
}

export const PortfolioHoldingsTable: React.FC<PortfolioHoldingsTableProps> = ({
  holdingTypeGroup,
}) => {
  const [sortValue, setSortValue] = useState('value');
  const [sort, setSort] = useState(SORT_DESC);
  const [searchTerm, setSearchTerm] = useState('');

  const { isLoading, holdings } = usePortfolio();
  const [data, setData] = useState<Holding[]>([]);
  const { convertUSDCurrency } = useAuth();

  const navigate = useNavigate();

  const columns = useMemo(
    () =>
      [
        {
          Header: '',
          accessor: 'logo_url',
          disableSortBy: true,
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;
            const asset = holding.asset;

            return (
              <div className="w-8 h-8">
                <AssetLogo
                  type={asset.assetTypeGroup}
                  logoUrl={asset.logoUrl}
                  width={28}
                  height={28}
                />
              </div>
            );
          },
        },
        {
          Header: 'Name',
          accessor: 'ticker_display',
          disableSortBy: true,
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;
            const asset = holding.asset;

            return <div>{`${asset.tickerDisplay}`}</div>;
          },
        },
        {
          Header: 'Quantity',
          accessor: 'quantity',
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;

            return <div>{formatNumber(holding.quantity)}</div>;
          },
        },
        {
          Header: 'Price',
          accessor: 'price',
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;

            return <div>{convertUSDCurrency(holding.price)}</div>;
          },
        },
        {
          Header: 'Price 1D',
          accessor: 'priceGain',
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;
            const priceGain = holding.priceGain!;
            return (
              <div
                className="flex flex-row items-center justify-end"
                style={{ color: getNumberColor(priceGain) }}
              >
                {priceGain >= 0 ? '+' : ''}
                {convertUSDCurrency(priceGain)}
              </div>
            );
          },
        },
        {
          Header: 'Value',
          accessor: 'amount',
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;

            return <div>{convertUSDCurrency(holding.amount)}</div>;
          },
        },
        {
          Header: 'Value 1D',
          accessor: 'valueGainPercent',
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;

            const valueGainPercent =
              holding.amount - holding.valueChange === 0
                ? holding.amount > 0
                  ? 100
                  : 0
                : (holding.valueChange /
                    (holding.amount - holding.valueChange)) *
                  100;

            return (
              <div
                className="flex flex-row items-center justify-end"
                style={{ color: getNumberColor(valueGainPercent) }}
              >
                {valueGainPercent >= 0 ? (
                  <ArrowLongUpIcon width={16} />
                ) : (
                  <ArrowLongDownIcon width={16} />
                )}
                {`${formatNumber(valueGainPercent)}%`}
              </div>
            );
          },
        },
        {
          Header: 'Allocation',
          accessor: 'allocation',
          Cell: (tableProps: CellProps<Holding>) => {
            const holding = tableProps?.row?.original;
            return <div>{`${formatNumber(holding.allocation)}%`}</div>;
          },
        },
      ] as ExtendedColumn<Holding>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const rowHandler = (assetTypeGroup: string, id: string) => {
    analytics.track('portfolio_type_page_asset_click', {
      asset_id: id,
      asset_type_group: assetTypeGroup,
      platform: 'web',
    });
    navigate(`/assets/${id}`);
  };

  const handleSort = (value: typeof SORT_ASC | typeof SORT_DESC) => {
    if (value !== sortValue) {
      setSort(SORT_DESC);
    } else {
      setSort(prevState => (prevState === SORT_ASC ? SORT_DESC : SORT_ASC));
    }

    setSortValue(value);
  };

  useEffect(() => {
    if (!holdings) return;

    const filteredHoldings =
      holdingTypeGroup !== 'all'
        ? holdings?.filter(
            holding => holding.asset.assetTypeGroup === holdingTypeGroup,
          )
        : holdings;

    const searchFilteredHoldings = filteredHoldings.filter(
      holding =>
        holding?.asset?.tickerDisplay
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()),
    );

    const sortedHoldings = searchFilteredHoldings.sort((a, b) => {
      let fieldA = a[sortValue];
      let fieldB = b[sortValue];
      if (sortValue === 'valueGainPercent') {
        fieldA =
          a.amount - a.valueChange === 0
            ? a.amount > 0
              ? 100
              : 0
            : (a.valueChange / (a.amount - a.valueChange)) * 100;
        fieldB =
          b.amount - b.valueChange === 0
            ? b.amount > 0
              ? 100
              : 0
            : (b.valueChange / (b.amount - b.valueChange)) * 100;
      }

      if (typeof fieldA === 'string') {
        fieldA = fieldA.toLowerCase();
        fieldB = fieldB.toLowerCase();
      }

      if (fieldA < fieldB) {
        return sort === SORT_DESC ? 1 : -1;
      }
      if (fieldA > fieldB) {
        return sort === SORT_DESC ? -1 : 1;
      }

      return 0;
    });

    setData(sortedHoldings);
  }, [sortValue, sort, holdings, holdingTypeGroup, searchTerm]);

  return (
    <div className="flex flex-col space-y-4 my-0 md:mx-3">
      <input
        type="text"
        placeholder="Search by name"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        className="w-full mb-2 p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-search-bg/50 dark:bg-gray-800 dark:text-gray-200"
      />
      <div className="card w-570 h-full overflow-hidden flex flex-col">
        {holdings?.length > 0 && !isLoading ? (
          <div className="assets-type-table">
            <LargeTable
              data={data}
              columns={columns}
              handleSort={handleSort}
              sortValue={sortValue}
              sort={sort}
              rowHandler={rowHandler}
            />
          </div>
        ) : isLoading ? (
          <div className="px-6">
            <div className="flex-grow">
              <SkeletonLoader height="100%" />
            </div>
          </div>
        ) : (
          <ComponentPlaceholder />
        )}
      </div>
    </div>
  );
};
