import { formatPriceNumber } from '@/utils/number';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Props for the component
interface DealUserPrize {
  deal: any;
  prizeCode: string;
  delivered: boolean;
  deliveryLink: string;
}

interface DealUserPrizeCardProps {
  userPrize: DealUserPrize;
}

export const DealUserPrizeCard: React.FC<DealUserPrizeCardProps> = ({
  userPrize,
}) => {
  const navigate = useNavigate();
  const deal = userPrize.deal;

  // Default fallback image
  const imageUrl =
    deal.image_url ||
    'https://storage.googleapis.com/assetdash-prod-images/deals/gift.png';

  const handleCardClick = () => {
    navigate(`/deals/${deal.id}`);
  };

  return (
    <div
      onClick={handleCardClick}
      className={`cursor-pointer border p-2 md:p-4 rounded-lg w-36 md:w-56 h-80 m-3 ${
        deal.status === 'sold_out'
          ? 'border-red-500'
          : deal.type === 'nft'
          ? 'border-purple-500'
          : 'border-gray-300 dark:border-gray-600'
      }`}
    >
      <div className="relative">
        <img
          src={imageUrl}
          alt={deal.title}
          className="rounded-lg w-full h-40 object-cover"
        />
        {(deal.cost_coins || deal.cost_gold) && (
          <div
            className="absolute top-2 right-2 flex items-center justify-center px-2 py-1 text-xs border bg-white dark:bg-gray-800 rounded-full"
            style={{
              borderColor: deal.cost_coins ? '#21CE99' : '#F5AC6E',
            }}
          >
            <span className="font-semibold text-center w-8">
              {deal.cost_coins
                ? `${formatPriceNumber(deal.cost_coins)}`
                : `${formatPriceNumber(deal.cost_gold)}`}
            </span>
            <img
              src={
                deal.cost_coins
                  ? '/assets/icons/coin.png'
                  : '/assets/icons/gold.png'
              }
              alt="currency icon"
              className="ml-1 h-4 w-4"
            />
          </div>
        )}
      </div>

      <div className="space-y-3 mt-2">
        <div className="text-sm font-bold overflow-hidden text-ellipsis whitespace-nowrap">
          {deal.name}
        </div>
        <div className="flex justify-between items-center mt-2">
          <div className="text-xs text-gray-500 dark:text-gray-400">Type</div>
          <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">
            {deal.type_display}
          </div>
        </div>
        {deal.blockchain && (
          <div className="flex justify-between items-center mt-2">
            <div className="text-xs text-gray-500 dark:text-gray-400">
              Blockchain
            </div>
            <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">
              {deal.blockchain.toUpperCase()}
            </div>
          </div>
        )}
      </div>

      <div className="mt-2 text-center p-2 rounded-lg border border-primary text-primary font-bold">
        Tap to View
      </div>
    </div>
  );
};
