import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '@/utils/number';
import { useTokenRewards } from '@/hooks/useTokenRewardsCenter';
import {
  GoldCenter,
  GoldCenterResponse,
} from '@/features/golds/models/GoldCenter';
import { getUserGoldCenter } from '@/api/gold';

export const SwapRewardsContainer: React.FC = () => {
  const navigate = useNavigate();

  const [goldCenter, setGoldCenter] = useState<GoldCenter>();

  const { loadTokenRewards, tokenRewardCenter } = useTokenRewards();

  useEffect(() => {
    const loadRewards = async () => {
      fetchGoldCenter();
      loadTokenRewards();
    };

    loadRewards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGoldCenter = async () => {
    try {
      const response = await getUserGoldCenter();
      setGoldCenter(GoldCenterResponse.fromJson(response.data).goldCenter);
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div className="min-h-screen">
      <div className="p-4">
        {/* Header Section */}
        <div className="flex items-center mb-4">
          <img
            src="/assets/icons/total_gold.png"
            alt="Rewards Icon"
            className="h-12"
          />
          <div className="ml-4">
            <h1 className="text-2xl font-semibold">
              Save Money. Earn Rewards.
            </h1>
            <p className="text-gray-500 dark:text-gray-400">
              Earn AssetDash Gold and Jackpot Entries every time you trade with
              AssetDash. Never spend more than $1 in fees.
            </p>
          </div>
        </div>

        {/* Rewards Title */}
        <h2 className="text-xl font-medium mb-2">Rewards</h2>

        {/* Gold Rewards */}
        <div className="mb-4 rounded-lg bg-light dark:bg-dark-card cursor-pointer">
          <div
            className="card p-4 flex items-center justify-between"
            onClick={() => navigate('/gold')}
          >
            <div className="flex items-center">
              <img
                src="/assets/svg/ic_wallet_reward_gold.svg"
                alt="Gold Reward"
                className="w-6 h-6"
              />
              <div className="ml-4">
                <h2 className="text-lg font-semibold">
                  My Gold: {formatNumber(goldCenter?.liquidGold ?? 0, 3)}
                </h2>
                <p className="text-gray-500 dark:text-gray-400">
                  Earn Gold on every trade and spend it on NFTs, memecoins,
                  Solana, and more.
                </p>
              </div>
            </div>
            <img
              src="/assets/svg/ic_wallet_arrow.svg"
              alt="Arrow Icon"
              className="w-6 h-6"
            />
          </div>
        </div>

        {/* Jackpot Entries */}
        <div
          className="card p-4 mb-4 rounded-lg bg-light dark:bg-dark-card cursor-pointer"
          onClick={() => navigate('/jackpot')}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                src="/assets/svg/ic_wallet_reward_jackpot_entry.svg"
                alt="Jackpot Entry"
                className="w-6 h-6"
              />
              <div className="ml-4">
                <h2 className="text-lg font-semibold">
                  My Jackpot Entries: {tokenRewardCenter?.jackpot_entries ?? 0}
                </h2>
                <p className="text-gray-500 dark:text-gray-400">
                  Every trade earns you entries to the Weekly Jackpot.
                </p>
              </div>
            </div>
            <img
              src="/assets/svg/ic_wallet_arrow.svg"
              alt="Arrow Icon"
              className="w-6 h-6"
            />
          </div>
        </div>

        {/* Benefits Title */}
        <h2 className="text-xl font-medium mb-2">Benefits</h2>

        {/* Spend AssetDash Gold */}
        <div
          className="card p-4 mb-4 rounded-lg bg-light dark:bg-dark-card cursor-pointer"
          onClick={() => navigate('/market?tab=deals')}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                src="/assets/svg/ic_wallet_reward_spend.svg"
                alt="Spend Gold"
                className="w-6 h-6"
              />
              <div className="ml-4">
                <h2 className="text-lg font-semibold">Spend AssetDash Gold</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  AssetDash Gold can be spent instantly in the Gold Marketplace
                  on memecoins, NFTs, Loot Boxes, and more.
                </p>
              </div>
            </div>
            <img
              src="/assets/svg/ic_wallet_arrow.svg"
              alt="Arrow Icon"
              className="w-6 h-6"
            />
          </div>
        </div>

        {/* Save Money Section */}
        <div className="card p-4 mb-4 rounded-lg bg-light dark:bg-dark-card">
          <div className="flex items-center">
            <img
              src="/assets/svg/ic_wallet_reward_dollar.svg"
              alt="Save Money"
              className="w-6 h-6"
            />
            <div className="ml-4">
              <h2 className="text-lg font-semibold">Save Money</h2>
              <p className="text-gray-500 dark:text-gray-400">
                Never pay more than $1 in trading fees with our ultra-low fee
                structure.
              </p>
            </div>
          </div>
        </div>

        {/* Elements Boost */}
        <div
          className="card p-4 mb-4 rounded-lg bg-light dark:bg-dark-card cursor-pointer"
          onClick={() =>
            window.open(
              'https://www.tensor.trade/trade/assetdash_elements',
              '_blank',
            )
          }
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                src="/assets/svg/ic_wallet_reward_jackpot_entry.svg"
                alt="Jackpot Entry"
                className="w-6 h-6"
              />
              <div className="ml-4">
                <h2 className="text-lg font-semibold">Elements Boost</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  Double your Gold Rewards and Jackpot Entries on every trade by
                  owning an Element.
                </p>
              </div>
            </div>
            <img
              src="/assets/svg/ic_wallet_arrow.svg"
              alt="Arrow Icon"
              className="w-6 h-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
