import { axiosAssetDashApi } from './api';

export const getCurrentUser = async () => {
  return await axiosAssetDashApi.get('/api/api_v4/users/me');
};

export const getAllCurrencies = async () => {
  return await axiosAssetDashApi.get('/api/api_v3/users/currency');
};

export const editCurrentUser = async (formValues: any) => {
  return await axiosAssetDashApi.put('/api/api_v4/users/me', formValues);
};

export const updateCurrentUserWebDevice = async (formValues: any) => {
  return await axiosAssetDashApi.put('/web/users/me/web-device', formValues);
};

export const deleteAccount = async () => {
  return await axiosAssetDashApi.delete(
    '/api/api_v3/users/delete_own_account',
    {
      data: { reason: 'none - Web' },
    },
  );
};

export const postUserCurrency = async (formValue: any) => {
  return await axiosAssetDashApi.patch(
    `/api/api_v3/users/change_currency?currency_id=${formValue.currency_id}`,
  );
};

export const getUserSyncData = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/users/sync-center`);
};

export const getUserMemberCenter = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/users/member-center`);
};

export const userPortfolioSync = async () => {
  return await axiosAssetDashApi.post(
    `/api/api_v3/users/trigger-portfolio-sync`,
  );
};

export const getUserDealsCenter = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/users/deals-center`);
};

export const getUserRewards = async () => {
  return await axiosAssetDashApi.get(`/api/api_v3/users/my-rewards`);
};

export const getUserInviteCenter = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/users/invite-center`);
};

export const getUserSocialCenter = async () => {
  return await axiosAssetDashApi.get(`/api/api_v4/users/social-center`);
};

export const getNotificationSettings = async () => {
  return await axiosAssetDashApi.get(`/api/api_v3/users/notification_settings`);
};

export const updateNotificationSettings = async (settings: any) => {
  return await axiosAssetDashApi.patch(
    `/api/api_v3/users/notification_settings/${settings.id}`,
    settings,
  );
};

export const getInboxCenter = async () => {
  return await axiosAssetDashApi.get(`/api/api_v5/users/inbox-center`);
};

export const getInboxUserMessages = async (page: number, tagId?: string) => {
  const params = {
    page: page,
    ...(tagId && { tag_id: tagId }),
  };

  return await axiosAssetDashApi.get(`/api/api_v5/inbox/messages/list`, {
    params,
  });
};

export const getInboxTags = async () => {
  return await axiosAssetDashApi.get(`/api/api_v5/inbox/tags/list`);
};

export const getInboxUserMessageDetail = async (inboxUserMessageId: string) => {
  return await axiosAssetDashApi.get(
    `/api/api_v4/inbox/messages/${inboxUserMessageId}`,
  );
};

export const completeOnboarding = async () => {
  return await axiosAssetDashApi.post(`/api/api_v5/users/complete_onboard`);
};
