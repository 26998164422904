import { ImageTag } from './ImageTag';

export const ImageWrapper: React.FC<{
  verified: boolean;
  url: string;
  alt: string;
  className?: string;
  aspectRatio?: number;
}> = ({ verified, url, alt, className, aspectRatio = 1 }) => {
  return (
    <div
      className="relative w-full rounded-4 overflow-hidden"
      style={{
        paddingBottom: `${(1 / aspectRatio) * 100}%`,
      }}
    >
      <div className="absolute inset-0">
        {verified && (
          <img
            src="/assets/icons/verified.png"
            alt="verified"
            className="absolute top-2 right-2 w-3 h-3 z-10"
          />
        )}
        <ImageTag
          className={`w-full h-full object-cover ${className || ''}`}
          url={url}
          alt={alt}
        />
      </div>
    </div>
  );
};
