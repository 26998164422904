// vendor libraries
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircleIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';

// hooks
import { usePortfolio } from '@/hooks/usePortfolio';
import { useAuth } from '@/hooks/useAuth';

// components
import { WalletListItem } from './WalletListItem';
import { AddWalletCard } from './AddWalletCard';
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';

// recoil
import { useTokenState } from '@/recoil/token';
import { useThemeState } from '@/recoil/theme';

// models
import { Wallet } from '@/features/portfolio/models/Wallet';
import { WalletHoldingsList } from '../wallet_details/WalletHoldingsList';
import { WalletDetailsCard } from '../wallet_details/WalletDetailsCard';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';

interface WalletsListProps {
  setShowManualWallet: (show: boolean) => void;
}

export const WalletsList: React.FC<WalletsListProps> = ({
  setShowManualWallet,
}) => {
  const [token] = useTokenState();
  const [theme] = useThemeState();
  const navigate = useNavigate();
  const { totalPortfolioValue, wallets, isLoading } = usePortfolio();
  const { convertUSDCurrency } = useAuth();
  const [showAddWalletCard, setShowAddWalletCard] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
  const [showMobileWalletDetails, setShowMobileWalletDetails] = useState(false);

  const handleRoute = (wallet: Wallet) => {
    if (window.innerWidth <= 768) {
      setSelectedWallet(wallet);
      setShowMobileWalletDetails(true);
    } else {
      setShowManualWallet(false);
      navigate(`/wallets/${wallet.id}`);
    }
  };

  const handleBackToList = () => {
    setShowMobileWalletDetails(false);
    setSelectedWallet(null);
  };

  const handleManualAssetSelect = (
    selectedAsset: string,
    holding?: WalletHolding,
  ) => {
    // Implement your manual asset selection logic here
  };

  if (isLoading) {
    return <SkeletonLoader height={600} />;
  }

  if (showMobileWalletDetails && selectedWallet) {
    return (
      <div className="h-screen card px-2 py-4 overflow-scroll scroller">
        <button
          onClick={handleBackToList}
          className="flex items-center space-x-2 mb-4 text-primary"
        >
          <ChevronLeftIcon className="h-5 w-5" />
          <span>Back to Wallets</span>
        </button>

        <WalletDetailsCard
          selectedWallet={selectedWallet}
          handleManualAssetSelect={handleManualAssetSelect}
        />

        <div className="mt-4">
          <WalletHoldingsList
            handleManualAssetSelect={handleManualAssetSelect}
          />
        </div>
      </div>
    );
  }

  // Show wallet list view (existing code)
  return (
    <div className="h-screen card px-2 py-4 overflow-scroll scroller">
      {!showAddWalletCard ? (
        <div className="divide-y divide-tabs-bg space-y-4">
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <div className="text-gray-text leading-4.75">Wallets</div>
              <button
                className="flex items-center space-x-2 bg-primary-gray dark:bg-blue-dark py-4 px-8 leading-17 font-semibold text-sm rounded-xl "
                onClick={() => {
                  if (process.env.REACT_APP_ENABLE_LINK_APP === 'true') {
                    window.location.href = `${process.env
                      .REACT_APP_LINK_URL!}/?token=${token}&theme=${theme}&source=web`;
                  } else {
                    setShowAddWalletCard(true);
                  }
                }}
              >
                <span>New wallet</span>
                <PlusCircleIcon className="h-4 w-4 text-price-red" />
              </button>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2 py-3 px-4 bg-price-green rounded-lg space-y-2">
                <div className="text-10 font-bold">Total Worth</div>
                <div className="text-2xl font-semibold">
                  {convertUSDCurrency(totalPortfolioValue)}
                </div>
              </div>
              <div className="py-3 px-4 bg-card-pink rounded-lg space-y-2">
                <div className="text-10 font-bold">Quantity</div>
                <div className="text-2xl font-semibold">{wallets?.length}</div>
              </div>
            </div>
          </div>
          <div className="space-y-4 py-4">
            {wallets &&
              wallets.length > 0 &&
              wallets.map(wallet => {
                return (
                  <div
                    key={wallet.id}
                    onClick={() => {
                      handleRoute(wallet);
                    }}
                    className="cursor-pointer"
                  >
                    <WalletListItem wallet={wallet} />
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <AddWalletCard
          handleManualWalletClick={() => setShowManualWallet(true)}
          hideAddWalletCard={() => setShowAddWalletCard(false)}
        />
      )}
    </div>
  );
};
