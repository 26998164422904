import { useNavigate } from 'react-router-dom';
import { DealProgressBar } from './DealProgressBar';
import { ImageTag } from '../../common/Image/ImageTag';

// Utility function to format numbers (similar to NumberUtils.displayInteger in Flutter)
const formatNumber = (num: number) => {
  return num.toLocaleString();
};

// Utility function to calculate remaining time (if not already implemented)
const getRemainingTime = (startTime: string) => {
  const now = new Date();
  const start = new Date(startTime);
  const diff = start.getTime() - now.getTime();
  if (diff <= 0) return 'Live';
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  return `${hours}h ${minutes}m remaining`;
};

export const DealCardV2: React.FC<{ deal: any }> = ({ deal }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/deals/${deal.id}`)}
      className={`cursor-pointer border p-2 md:p-4 rounded-lg w-38 md:w-56 h-80 m-2 ${
        deal.status === 'sold_out'
          ? 'border-red-500'
          : deal.type === 'nft'
          ? 'border-purple-500'
          : 'border-gray-300 dark:border-gray-600'
      }`}
    >
      <div className="relative">
        <ImageTag
          url={deal.image_url}
          alt={deal.title}
          className="rounded-lg w-full h-40 object-cover"
        />
        {(deal.cost_coins || deal.cost_gold) && (
          <div
            className="absolute top-2 right-2 flex items-center justify-center px-2 py-1 text-xs border bg-white dark:bg-gray-800 rounded-full"
            style={{
              borderColor: deal.cost_coins ? '#21CE99' : '#F5AC6E',
            }}
          >
            <span className="font-semibold text-center text-main-yellow w-8">
              {deal.cost_coins
                ? `${formatNumber(deal.cost_coins)}`
                : `${formatNumber(deal.cost_gold)}`}
            </span>
            <img
              src={
                deal.cost_coins
                  ? '/assets/icons/coin.png'
                  : '/assets/icons/gold.png'
              }
              alt="currency icon"
              className="ml-1 h-4 w-4"
            />
          </div>
        )}
      </div>

      <div className="space-y-3 mt-2">
        <div className="text-sm font-bold overflow-hidden text-ellipsis whitespace-nowrap">
          {deal.title}
        </div>
        <div className="flex justify-between items-center mt-2">
          <div className="text-xs text-gray-500 dark:text-gray-400">Type</div>
          <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">
            {deal.type_display}
          </div>
        </div>
        <div className="flex justify-between items-center mt-2">
          <div className="text-xs text-gray-500 dark:text-gray-400">
            Blockchain
          </div>
          <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">
            {deal.blockchain}
          </div>
        </div>
        <div className="flex justify-center items-center mt-2">
          {deal.status === 'sold_out' ? (
            <div className="border border-primary-red text-primary-red rounded-full px-2 py-1 text-xs">
              Sold Out
            </div>
          ) : (
            <div className="border border-primary text-primary rounded-full px-6 py-1 text-xs">
              {deal.start_time && deal.status !== 'sold_out'
                ? getRemainingTime(deal.start_time)
                : 'Get Deal'}
            </div>
          )}
        </div>
        <DealProgressBar
          percent={deal.percent_prizes_claimed}
          color={deal.type === 'nft' ? 'purple' : 'green'}
        />
      </div>
    </div>
  );
};
