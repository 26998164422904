// src/components/v2/portfolio/EmptyPortfolioState.tsx
import { useNavigate } from 'react-router-dom';

export const EmptyPortfolioState: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center py-20 px-4 text-center">
      <img
        src="https://storage.googleapis.com/assetdash-prod-images/mobile_app/empty_portfolio.png"
        alt="Empty Portfolio"
        className="max-w-[300px] mb-8"
      />

      <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
        Track your portfolio like a pro
      </h1>

      <p className="text-base text-gray-600 dark:text-gray-300 mb-4 max-w-md">
        Connect your wallets and start tracking your crypto, NFTs, DeFi, and
        more!
      </p>

      <p className="text-sm text-gray-500 dark:text-gray-400 mb-8">
        You can simply paste your wallet address to add.
      </p>

      <button
        onClick={() => navigate('/wallets')}
        className="px-6 py-3 bg-primary text-white rounded-lg font-medium hover:bg-primary-dark transition-colors"
      >
        Get Started
      </button>
    </div>
  );
};
