import { axiosAssetDashApi } from './api';
export interface SwapJackpotEntry {
  id: string;
  asset: any;
  swapItemId: string;
  created: Date;
  amount: number;
}

export interface SwapJackpot {
  id: string;
  asset: any;
  asset_id: string;
  ending_time: Date;
  jackpot_amount: number;
  num_entries: number;
}

export interface SwapJackpotEntriesResponse {
  entries: SwapJackpotEntry[];
}

export interface TokenRewardsCenter {
  jackpot_entries?: number;
  total_swaps?: number;
  total_swap_volume?: number;
  swap_rewards?: UserSwapReward[];
  swap_reward_withdrawals?: UserSwapRewardWithdrawal[];
  leaderboard_rank?: number;
  leaderboard_legendary?: boolean;
  leaderboard_top?: boolean;
  leaderboard_active?: boolean;
  public_user_id?: string;
}

export interface TokenRewardsCenterResponse {
  swap_center?: TokenRewardsCenter;
}

export interface UserSwapReward {
  asset?: any;
  total_amount?: number;
  withdrawn_amount?: number;
  current_amount?: number;
  withdrawal_threshold?: number;
}

export interface UserSwapRewardWithdrawal {
  created?: string;
  asset?: any;
  amount: number;
  withdrawal_tx_id?: string;
  solscan_link?: string;
}

export const getUserTokenRewardCenter =
  async (): Promise<TokenRewardsCenterResponse> => {
    const response = await axiosAssetDashApi.get(
      '/api/api_v5/users/swap-center',
    );
    return response.data;
  };

export const getSwapJackpot = async (): Promise<SwapJackpot> => {
  const response = await axiosAssetDashApi.get('/api/api_v4/swap/jackpot');
  return response.data.jackpot;
};

export const getSwapJackpotEntries =
  async (): Promise<SwapJackpotEntriesResponse> => {
    const response = await axiosAssetDashApi.get(
      '/api/api_v4/swap/jackpot_entries',
    );
    return response.data;
  };
