import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

// components
import CourseTag from '@/components/v2/learn/CourseTag';
import FeaturedCourse from '@/components/v2/learn/FeaturedCourse';
import LearnTab from '@/components/v2//learn/LearnTab';
import ListAllCourses from '@/components/v2/learn/ListAllCourses';
import ShareLearn from '@/components/v2/learn/ShareLearn';
import TrendingCourse from '@/components/v2/learn/TrendingCourse';

// hooks
import { useLearn } from '../hooks/useLearn';

import { SkeletonLoader } from '../components/common/Skeleton/SkeletonLoader';

export default function LearnPage() {
  const limit = 30;

  const {
    selectedCourse,
    fetchCourses,
    courses,
    setCourses,
    page,
    setPage,
    activeList,
    setActiveList,
    fetchStartedCourses,
    fetchCompletedCourses,
    featuredCourses,
    trendingCourses,
    isLoading,
    fetchFeaturedCourse,
    fetchTrendingCourse,
    fetchMyShares,
  } = useLearn();

  const tabs = {
    all: 'All Courses',
    started: 'Started',
    completed: 'Completed',
    myShares: 'My Shares',
  };

  const onTabClick = key => {
    setCourses(null);
    setPage(1);
    setActiveList(key);
    analytics.track(`learn_${key}_tab_click`);
  };

  useEffect(() => {
    // Initial load
    if (!featuredCourses && !trendingCourses) {
      fetchFeaturedCourse();
      fetchTrendingCourse();
    }

    // Course list fetching
    if (activeList === 'all') {
      const payload = {
        tag_ids: selectedCourse,
        page: page,
        limit: limit,
      };
      fetchCourses(payload);
    } else if (activeList === 'started') {
      const payload = {
        page: page,
        limit: limit,
      };
      fetchStartedCourses(payload);
    } else if (activeList === 'completed') {
      const payload = {
        page: page,
        limit: limit,
      };
      fetchCompletedCourses(payload);
    } else if (activeList === 'myShares') {
      fetchMyShares();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeList, selectedCourse, page]);

  return (
    <>
      <SuperSEO title={`AssetDash | Learn`} lang="en_IE" />
      <div className="main-learn-content mx-auto">
        <div className="my-2 md:my-9 space-y-8">
          {featuredCourses && featuredCourses.length > 0 ? (
            <div>
              <FeaturedCourse data={featuredCourses} title="Featured" />
            </div>
          ) : (
            isLoading && <SkeletonLoader height={300} />
          )}

          {trendingCourses && trendingCourses.length > 0 ? (
            <div>
              <TrendingCourse data={trendingCourses} title="Trending Courses" />
            </div>
          ) : (
            isLoading && <SkeletonLoader height={300} />
          )}

          <div className="flex justify-center">
            <LearnTab tabs={tabs} selected={activeList} onClick={onTabClick} />
            {/* <LearnRewards /> */}
          </div>

          {activeList === 'all' && (
            <div>
              <div className="mb-8">
                <div className="flex justify-center">
                  <CourseTag />
                </div>
              </div>
              <div className="space-y-4">
                {courses && courses.length > 0 && (
                  <p className="px-2 font-bold text-22">All Courses</p>
                )}
                <ListAllCourses type="all" />
              </div>
            </div>
          )}
          {activeList === 'started' && (
            <div className="space-y-4">
              <p className="px-2 font-bold text-22">Started Courses</p>
              <ListAllCourses type="started" />
            </div>
          )}
          {activeList === 'completed' && (
            <div className="space-y-4">
              <p className="px-2 font-bold text-22">Completed Courses</p>
              <ListAllCourses type="completed" />
            </div>
          )}
          {activeList === 'myShares' && (
            <div className="space-y-4">
              <p className="px-2 font-bold text-22">My Rewards</p>
              <ShareLearn type="myShares" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
