// vendor libraries
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useParams } from 'react-router-dom';

// recoil
import { useThemeState } from '@/recoil/theme.ts';

// hooks
import { useAuth } from '@/hooks/useAuth.tsx';

// components
import { AssetLogo } from '../../common/Logo/AssetLogo.tsx';

// constants
import { walletStatusToColor } from '../constants.ts';

// models
import { Wallet } from '@/features/portfolio/models/Wallet.ts';

interface WalletListItemProps {
  wallet: Wallet;
}

export const WalletListItem: React.FC<WalletListItemProps> = ({ wallet }) => {
  const [theme] = useThemeState();
  const strokeTrail = theme === 'light' ? '#A8A8A8' : '#FFFFFF';

  const { convertUSDCurrency } = useAuth();
  const { walletId } = useParams();

  const address =
    wallet &&
    wallet.address &&
    wallet.address.slice(0, 6) +
      '......' +
      wallet.address.slice(wallet.address.length - 4);

  const getClassName = (status: string) => {
    return `${walletStatusToColor[status]}`;
  };

  return (
    <div
      className={
        walletId === wallet.id
          ? 'bg-short-description dark:bg-blue-dark p-4 rounded-xl'
          : 'card p-4 rounded-xl hover:bg-gray-text dark:hover:bg-blue-dark'
      }
    >
      <div className="flex space-x-4 ">
        <div className="h-8 w-10p rounded-full">
          <AssetLogo
            width={28}
            height={28}
            type={wallet.walletType}
            logoUrl={wallet.logoUrl}
          />
          <div
            className={`h-4 w-4 relative left-6 bottom-4 rounded-full border-2 solid border-white dark:border-black`}
            style={{
              background:
                wallet.status === 'error'
                  ? getClassName(wallet.status)
                  : getClassName(wallet.readyStatus!),
            }}
          ></div>
        </div>
        <div className="flex justify-between w-full">
          <div>
            <div className="flex flex-col space-y-1">
              <div className="leading-17 text-sm flex space-x-1 items-center">
                <div className="w-24 flex space-x-1">
                  <div className="add-overflow-dot">{wallet.name}</div>
                  {wallet.isVerified && (
                    <img
                      src="/assets/icons/verified.png"
                      alt="verified"
                      className="w-4 h-4"
                    />
                  )}
                </div>
                {!wallet.isVerified &&
                  ['zapper', 'solana', 'sui'].includes(wallet.walletType!) && (
                    <div className="text-red-600 border border-red-600 text-10 leading-17 w-max px-4p py-2p rounded-2xl mt-1">
                      Not Verified
                    </div>
                  )}
                {wallet.isPrizeWallet && (
                  <div className="text-price-green border border-price-green text-10 leading-17 w-max px-4p py-2p rounded-2xl mt-1">
                    Prize Wallet
                  </div>
                )}
              </div>

              <div>{address}</div>
            </div>

            <div className="mt-6 font-semibold text-xl leading-6">
              {convertUSDCurrency(wallet.totalValue)}
            </div>
            {wallet.status === 'error' && (
              <div className="text-primary-red text-xs font-semibold mt-2">
                Not Connected
              </div>
            )}
          </div>
          <div className="h-16 w-16 text-white my-auto space-x-4">
            <CircularProgressbar
              value={wallet?.portfolioAllocation!}
              text={`${wallet?.portfolioAllocation?.toFixed(2)}%`}
              styles={{
                // Customize the root svg element
                root: {},
                // Customize the path, i.e. the "completed progress"
                path: {
                  // Path color
                  stroke: '#21CE99',
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'butt',

                  // Customize transition animation
                  transition: 'stroke-dashoffset 0.5s ease 0s',
                  // Rotate the path
                  transform: 'rotate(0.25turn)',
                  transformOrigin: 'center center',
                },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                  // Trail color
                  stroke: strokeTrail,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'butt',
                  // Rotate the trail
                  transform: 'rotate(0.25turn)',
                  transformOrigin: 'center center',
                },
                // Customize the text
                text: {
                  // Text color
                  fill: theme === 'dark' ? 'white' : 'black',
                  // Text size
                  fontSize: '18px',
                  fontWeight: '600',
                  lineHeight: '17px',
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
