import React, { useState } from 'react';
import { OptionsTab } from '@/components/common/Tab/OptionsTab';
import { GoldCenterHeader } from './GoldCenterHeader';
import { GoldStats } from './GoldStats';
import { GoldDistributions } from './GoldDistributions';
import { GoldSpendings } from './GoldSpendings';

export const GoldCenterContainer: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('distributions');
  const modalTabs: { [key: string]: string } = {
    distributions: 'Received',
    spendings: 'Spent',
  };

  return (
    <div className="mx-auto main-content grid-cols-1">
      <GoldCenterHeader />
      <GoldStats />
      <div className="py-2 sm:py-5 md:px-6 flex items-center justify-center">
        <OptionsTab
          tabs={modalTabs}
          activeTab={selectedTab}
          onClick={(key: string) => setSelectedTab(key)}
        />
      </div>
      <div className={`${selectedTab === 'distributions' ? '' : 'hidden'}`}>
        <GoldDistributions />
      </div>
      <div className={`${selectedTab === 'spendings' ? '' : 'hidden'}`}>
        <GoldSpendings />
      </div>
    </div>
  );
};
