import { UsersIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import { formatNumber } from '@/utils/number';

export default function TrendingCourse({ data }) {
  const navigate = useNavigate();

  const handleStart = course => {
    analytics.track('learn_trending_course_view_detail', {
      id: course.id,
    });
    navigate(`/learn/${course.id}`);
  };

  return (
    <div className="space-y-4">
      {data && data.length > 0 && (
        <p className="px-2 font-bold text-22">Trending</p>
      )}
      <div className="flex justify-between overflow-x-scroll space-x-2">
        {data?.map(course => {
          const { reward, num_views } = course;
          const { coins, cash_back, tickets } = reward || '';
          return (
            <div
              className="text-left w-72"
              key={course.id}
              onClick={() => handleStart(course)}
            >
              <div className="bg-toggle-bg w-72 dark:bg-black p-4 mr-4 rounded-15 border border-solid border-input-box cursor-pointer">
                <div className="space-y-6">
                  <div className="relative">
                    <img
                      src={course.banner_image_url}
                      alt={course.title}
                      className="h-43 w-full object-cover rounded-lg"
                    />
                    <div className="absolute top-2 right-2">
                      {num_views && (
                        <div
                          className="flex space-x-2 items-center py-1 px-2 rounded-md"
                          style={{
                            background: 'rgba(255, 255, 255, 0.26)',
                            backdropFilter: 'blur(10.5px)',
                          }}
                        >
                          <UsersIcon className="h-4 w-4" />
                          <p>{formatNumber(num_views, 0)}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center space-x-2">
                      <img
                        src={course.partner_logo_url}
                        alt={course.partner_name}
                        className="h-10p w-10p rounded-full object-cover"
                      />
                      <div>
                        <p className="text-price-green text-sm lineClamp-2">
                          {course.partner_name}
                        </p>
                      </div>
                    </div>
                    <div className="w-full space-y-2 overflow-hidden">
                      <div className="flex items-center space-x-2 whitespace-nowrap overflow-x-scroll scrollbar-hidden">
                        {coins > 0 && (
                          <div className="flex space-x-1 md:space-x-2 items-center bg-light-yellow-green py-1 px-1 md:px-2 rounded-full border-1 border-solid border-price-green">
                            <img
                              src="/assets/icons/coin.png"
                              className="h-4 w-4"
                              alt="coin"
                            />
                            <p className="text-price-green text-11">
                              {coins} Coins
                            </p>
                          </div>
                        )}
                        {tickets > 0 && (
                          <div className="flex w-max space-x-1 md:space-x-2 items-center bg-light-yellow-green py-1 px-1 md:px-2 rounded-full border-1 border-solid border-price-green">
                            <img
                              src="/assets/images/ticket.png"
                              className="h-4 w-4"
                              alt="ticket"
                            />
                            <p className="text-price-green text-11">
                              <span className="font-bold">{tickets}</span>{' '}
                              {tickets > 1 ? 'Tickets' : 'Ticket'}
                            </p>
                          </div>
                        )}
                        {cash_back > 0 && (
                          <div className="flex space-x-1 md:space-x-2 items-center bg-light-yellow-green py-1 px-1 md:px-2 rounded-full border-1 border-solid border-price-green">
                            <img
                              src="/assets/images/cash.png"
                              className="h-4 w-4"
                              alt="ticket"
                            />
                            <p className="text-price-green text-11">
                              <span className="font-bold">${cash_back}</span>{' '}
                              Cash
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="space-y-4">
                    <p className="text-lg font-bold lineClamp-1">
                      {course.title}
                    </p>
                    <p className="text-sm lineClamp-3 h-16">
                      {course.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
