import {
  BoltIcon,
  LockClosedIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { PlayIcon } from '../components/common/Icon/PlayIcon';
import { ProgressBar } from '../components/common/ProgressBar';
import { useLearn } from '../hooks/useLearn';

import { SkeletonLoader } from '../components/common/Skeleton/SkeletonLoader';

import { formatNumber } from '../utils/number';

import { useMember } from '../hooks/useMember';

import { ConnectInvestmentsModal } from '../components/common/Modal/ConnectInvestmentsModal';

export default function LearnCourseDetails() {
  const { memberCenterData } = useMember();
  const member_status = memberCenterData?.member_status;
  const [connectInvestmentsModalOpen, setConnectInvestmentsModalOpen] =
    useState(false);
  const { id } = useParams();
  const {
    fetchCourseDetails,
    courseDetails,
    isLoading,
    startCourse,
    generateShareUrl,
  } = useLearn();

  const navigate = useNavigate();

  const [buttonText, setButtonText] = useState('Start Course');

  const [isShareClicked, setIsShareClicked] = useState(false);

  const generateUrl = async () => {
    setIsShareClicked(true);
    const payload = {
      course_id: id,
    };
    const resp = await generateShareUrl(payload);

    if (resp && resp.share_url) {
      navigator.clipboard.writeText(resp.share_url);
      setTimeout(() => {
        toast.success('Copied to clipboard');
        setIsShareClicked(false);
      }, 1000);
    } else {
      setIsShareClicked(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCourseDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const {
    title,
    description,
    banner_image_url,
    partner_name,
    partner_logo_url,
    steps,
    is_boosted,
    reward,
    cta,
    num_views,
    is_locked,
    weekly_completions_percent,
    weekly_unlock_time,
  } = courseDetails?.course || '';

  useEffect(() => {
    if (courseDetails) {
      const { course_user } = courseDetails;
      if (course_user && !is_locked) {
        if (course_user.status === 'completed') {
          setButtonText('View Course');
        } else {
          setButtonText('Resume Course');
        }
      }
    }
  }, [courseDetails, is_locked]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { coins, cash_back, tickets } = reward || '';

  const handleClick = async () => {
    if (member_status === 'standard') {
      setConnectInvestmentsModalOpen(true);
      return;
    }
    if (!courseDetails?.course_user) await startCourse(id);
    else {
      analytics.track('learn_course_start', { id });
      navigate(`/learn/${id}/start`);
    }
  };

  useEffect(() => {
    if (is_locked) {
      setButtonText('Locked');
    }
  }, [is_locked]);

  return (
    <div className="mx-auto main-learn-content">
      {!isLoading && courseDetails?.course ? (
        <div className="lg:h-80vh mt-4 px-4 md:px-0">
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 lg:gap-8 h-full">
            <div className="lg:col-span-2 space-y-7">
              <img
                src={banner_image_url}
                className="w-max object-contain h-44"
                alt="banner"
              />
              <div className="space-y-10">
                <div className="flex justify-between flex-wrap">
                  <div className="flex space-x-2">
                    {is_boosted && (
                      <div className="flex space-x-2 items-center bg-boosted-bg py-2 px-2 md:px-4 rounded-full border-1 border-solid border-boosted-blue">
                        <BoltIcon
                          height="24"
                          width="24"
                          className="stroke-boosted-blue"
                        />
                        <p className="text-boosted-blue text-sm md:text-base">
                          Boosted
                        </p>
                      </div>
                    )}
                    {cash_back > 0 && (
                      <div className="flex space-x-2 items-center bg-light-yellow-card py-2 px-2 md:px-4 rounded-full border-1 border-solid border-main-yellow">
                        <img src="/assets/images/cash.png" alt="cash" />
                        <p className="text-main-yellow text-sm md:text-base">
                          <span className="font-bold">${cash_back}</span> Cash
                        </p>
                      </div>
                    )}
                    {coins > 0 && (
                      <div className="flex space-x-2 items-center bg-light-yellow-green py-2 px-2 md:px-4 rounded-full border-1 border-solid border-price-green">
                        <img
                          src="/assets/icons/coin.png"
                          width={24}
                          alt="coin"
                        />
                        <p className="text-price-green text-sm md:text-base">
                          <span className="font-bold">{coins} Coins</span>
                        </p>
                      </div>
                    )}
                    {tickets > 0 && (
                      <div className="flex space-x-2 items-center bg-light-yellow-green py-2 px-2 md:px-4 rounded-full border-1 border-solid border-price-green">
                        <img src="/assets/images/ticket.png" alt="ticket" />
                        <p className="text-price-green text-sm md:text-base">
                          <span className="font-bold">{tickets}</span>{' '}
                          {tickets > 1 ? 'Tickets' : 'Ticket'}
                        </p>
                      </div>
                    )}
                  </div>
                  {num_views && (
                    <p className="flex space-x-2 items-center ml-auto">
                      <UsersIcon className="h-4 w-4" />
                      {formatNumber(num_views, 0)}
                    </p>
                  )}
                </div>
                <div className="space-y-3">
                  <p className="text-22 font-bold font-nova">Description</p>
                  <p className="text-base">{description}</p>
                </div>
                <div>
                  {is_locked && (
                    <ProgressBar
                      progress={
                        is_locked && weekly_completions_percent
                          ? weekly_completions_percent
                          : 100
                      }
                      bgcolor="#21CE99"
                      multiplier={1}
                      height={'4'}
                    />
                  )}
                  {is_locked && (
                    <p className="text-sm font-bold flex items-center space-x-2">
                      <span>
                        Locked until{' '}
                        {weekly_unlock_time
                          ? moment(weekly_unlock_time).format('dddd')
                          : 'Sunday'}
                      </span>
                      <LockClosedIcon className="h-4 w-4 text-price-green" />
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 space-y-8">
              <p className="text-3xl">{title}</p>
              <div className="flex space-x-2 items-center">
                <img
                  src={partner_logo_url}
                  className="w-12 h-12 rounded-full object-cover"
                  alt="banner"
                />
                <p className="text-2xl">{partner_name}</p>
              </div>
              <div className="flex space-x-2 items-center">
                <button
                  className="myBtnSmall font-normal flex space-x-2 items-center w-32 justify-center"
                  onClick={() => {
                    generateUrl();
                  }}
                >
                  <p>Share</p>
                  {/* <img
                  src="/images/earn.png"
                  alt="reward"
                  className="h-4 w-4"
                /> */}
                </button>
                {isShareClicked && (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx={12}
                      cy={12}
                      r={10}
                      stroke="currentColor"
                      strokeWidth={4}
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                )}
              </div>
              <div>
                <p className="text-22 mb-6">
                  Lessons{' '}
                  {/* <span className="text-white opacity-30 ml-4 font-normal">
                  Reviews
                </span> */}
                </p>
                <ul className="pb-20 xl:pb-0 py-10p space-y-4 h-80 max-h-80 xl:h-96 xl:max-h-96 overflow-auto scroller lg:w-2/3">
                  {steps?.map(step => {
                    return (
                      <li className="bg-learn-lesson-card py-5 px-4 rounded-lg">
                        <div className="flex justify-between items-center">
                          <div className="flex items-center space-x-4">
                            <PlayIcon />
                            <p className="lineClamp-1 text-17">{step.title}</p>
                          </div>
                          <p className="whitespace-nowrap text-17">
                            {step.duration} sec
                          </p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="lg:sticky lg:bottom-4 lg:left-full lg:w-max">
            <div className="flex flex-col lg:w-max mx-auto lg:mx-0 mt-4">
              {cta && courseDetails?.course_user?.status === 'completed' && (
                <button
                  className="bg-main-yellow rounded-lg h-max mb-2 py-3 lg:px-4 w-full text-black font-bold text-base lg:text-sm"
                  onClick={() => {
                    window.open(cta.cta_url);
                  }}
                >
                  {cta.title}
                </button>
              )}
              <button
                className={`flex space-x-2 text-center justify-center items-center rounded-lg h-max mb-2 py-3 lg:px-4 w-full text-black text-base lg:text-sm ${
                  is_locked ? 'bg-gray-text' : 'bg-price-green'
                }`}
                onClick={() => {
                  if (!is_locked) handleClick();
                }}
              >
                <p>{buttonText}</p>
                {is_locked && <LockClosedIcon className="h-4 w-4" />}
              </button>
            </div>
          </div>
        </div>
      ) : (
        isLoading && (
          <div>
            <SkeletonLoader height={500} />
          </div>
        )
      )}
      <ConnectInvestmentsModal
        isOpen={connectInvestmentsModalOpen}
        closeModal={() => setConnectInvestmentsModalOpen(false)}
        title="Verify to access exclusive courses"
        description="You need to be Bronze Tier or higher to access this feature. AssetDash recommends having a verified wallet of $150 or higher to become Bronze Tier. Tap continue below to get started."
        imageLocation="/assets/images/trophy-invite.png"
      />
    </div>
  );
}
