// vendor libraries
import { SwapRewardsContainer } from '@/components/v2/common/SwapRewardsContainer';
import { useEffect } from 'react';
import { SuperSEO } from 'react-super-seo';

export default function SwapRewardsPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="mx-auto main-content grid-cols-1">
      <SuperSEO title={`AssetDash | Rewards`} lang="en_IE" />
      <SwapRewardsContainer />
    </div>
  );
}
