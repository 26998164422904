export const SORT_DESC = ':desc';

export const SORT_ASC = ':asc';

export const ASSET_TYPE_TO_DISPLAY_NAME: { [key: string]: string } = {
  equity: 'Equity',
  derivative: 'Derivative',
  etf: 'ETF',
  mutual_fund: 'Mutual Fund',
  currency: 'Currency',
  nft: 'NFT',
  crypto_token: 'Crypto Token',
  crypto_protocol: 'Crypto Platform',
  stocks: 'Stocks',
  commodity: 'Commodity',
  metals: 'Metals',
  real_estate: 'Real Estate',
  private_equity: 'Private Equity',
  other: 'Other',
  collectibles: 'Collectibles',
  crypto: 'Crypto',
  defi: 'DeFi',
  cash: 'Cash',
  options: 'Options',
  all: 'Total',
  staked: 'Staked',
};

export enum DealsPurchaseByOption {
  All = 'all',
  Gold = 'gold',
  Coins = 'coins',
}

export enum DealSortByOption {
  Priority = 'priority',
  Created = 'created',
  GoldCostDesc = 'cost_gold_desc',
  GoldCostAsc = 'cost_gold_asc',
  CoinsCostDesc = 'cost_coins_desc',
  CoinsCostAsc = 'cost_coins_asc',
}

export enum DealFilterByOption {
  All = 'all',
  Active = 'active',
  NotLiveYet = 'not_live_yet',
  SoldOut = 'sold_out',
}

export const getDealsPurchaseByApiValue = (
  option: DealsPurchaseByOption,
): string => {
  return option;
};

export const getDealsPurchaseByDisplayValue = (
  option: DealsPurchaseByOption,
): string => {
  switch (option) {
    case DealsPurchaseByOption.All:
      return 'Gold/Coins';
    case DealsPurchaseByOption.Gold:
      return 'Gold';
    case DealsPurchaseByOption.Coins:
      return 'Coins';
    default:
      return '';
  }
};

export const getDealSortByApiValue = (option: DealSortByOption): string => {
  return option;
};

export const getDealSortByDisplayValue = (option: DealSortByOption): string => {
  switch (option) {
    case DealSortByOption.Priority:
      return 'Popularity';
    case DealSortByOption.Created:
      return 'Latest';
    case DealSortByOption.CoinsCostDesc:
      return 'Coins ⇩';
    case DealSortByOption.CoinsCostAsc:
      return 'Coins ⇧';
    case DealSortByOption.GoldCostDesc:
      return 'Gold ⇩';
    case DealSortByOption.GoldCostAsc:
      return 'Gold ⇧';
    default:
      return '';
  }
};

export const getDealFilterByApiValue = (option: DealFilterByOption): string => {
  return option;
};

export const getDealFilterByDisplayValue = (
  option: DealFilterByOption,
): string => {
  switch (option) {
    case DealFilterByOption.All:
      return 'All';
    case DealFilterByOption.Active:
      return 'Active';
    case DealFilterByOption.NotLiveYet:
      return 'Not Live Yet';
    case DealFilterByOption.SoldOut:
      return 'Sold Out';
    default:
      return '';
  }
};
