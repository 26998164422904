import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { getUserGoldCenter } from '@/api/gold';
import {
  GoldCenter,
  GoldCenterResponse,
} from '@/features/golds/models/GoldCenter';

export const GoldStats: React.FC = () => {
  const [goldCenter, setGoldCenter] = useState<GoldCenter>();

  useEffect(() => {
    fetchGoldCenter();
  }, []);

  const fetchGoldCenter = async () => {
    try {
      const response = await getUserGoldCenter();
      setGoldCenter(GoldCenterResponse.fromJson(response.data).goldCenter);
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div className="mt-2 space-y-6">
      {/* Liquid Gold - Full Width */}
      <div className="w-full p-4 border card rounded-lg flex flex-col items-center">
        <img
          src="/assets/icons/gold.png"
          alt="Liquid Gold"
          className="h-auto w-8 mb-2"
        />
        <CountUp
          duration={2}
          decimals={2}
          end={goldCenter?.liquidGold || 0}
          className="text-2xl md:text-3xl font-bold"
        />
        <div className="text-lg font-semibold">Liquid Gold</div>
      </div>

      {/* Other Gold Stats */}
      <div className="grid grid-cols-2 gap-4">
        {/* Locked Gold */}
        <div className="p-4 border card rounded-lg flex flex-col items-center">
          <img
            src="/assets/icons/locked_gold.png"
            alt="Locked Gold"
            className="h-auto w-6 mb-2"
          />
          <CountUp
            duration={2}
            decimals={2}
            end={goldCenter?.lockedGold || 0}
            className="text-xl md:text-2xl font-bold"
          />
          <div className="text-md font-semibold">Locked Gold</div>
        </div>

        {/* Spent Gold */}
        <div className="p-4 border card rounded-lg flex flex-col items-center">
          <img
            src="/assets/icons/spent_gold.png"
            alt="Spent Gold"
            className="h-auto w-6 mb-2"
          />
          <CountUp
            duration={2}
            end={goldCenter?.spentGold || 0}
            className="text-xl md:text-2xl font-bold"
          />
          <div className="text-md font-semibold">Spent Gold</div>
        </div>
      </div>
    </div>
  );
};
