// vendor libraries
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/solid';

// components
import { AssetLogo } from '../../common/Logo/AssetLogo';
import { ConfirmDeleteManualHoldingModal } from './modals/ConfirmDeleteManualHoldingModal';

// utils
import { formatNumber } from '@/utils/number';
import { getQuantityDenomination } from '@/features/portfolio/utils/holdings/holdingsUtils';

// hooks
import { useAuth } from '@/hooks/useAuth';
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { Wallet } from '@/features/portfolio/models/Wallet';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';

interface WalletHoldingsListItemProps {
  walletHolding: WalletHolding;
  wallet: Wallet;
  isLast?: boolean;
  handleManualAssetSelect: (
    selectedAsset: string,
    holding?: WalletHolding,
  ) => void;
}

export const WalletHoldingsListItem: React.FC<WalletHoldingsListItemProps> = ({
  walletHolding,
  wallet,
  isLast,
  handleManualAssetSelect,
}) => {
  const { convertUSDCurrency } = useAuth();
  const { assetPricesResponseData } = usePortfolio();
  const navigate = useNavigate();
  const [walletValue, setWalletValue] = useState<number>();
  const [
    confirmDeleteManualHoldingModalOpen,
    setConfirmDeleteManualHoldingModalOpen,
  ] = useState(false);

  useEffect(() => {
    setWalletValue(walletHolding.value);
  }, [assetPricesResponseData, walletHolding]);

  const handleClick = () => {
    if (!walletHolding.manualAsset) {
      navigate(`/assets/${walletHolding.assetId}`);
    }
  };

  const onClickEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    let type = asset?.assetType;
    if (asset?.assetType === 'equity') {
      type = 'stocks';
    }
    handleManualAssetSelect(type!, walletHolding);
  };

  const onClickDelete = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setConfirmDeleteManualHoldingModalOpen(true);
  };

  const asset = walletHolding?.asset
    ? walletHolding.asset
    : walletHolding.manualAsset;

  return (
    <div
      className={`${
        walletHolding.manualAsset ? '' : 'cursor-pointer'
      } text-gray-text hover:text-black dark:hover:text-white bg-primary-gray
       dark:bg-dark hover:bg-short-description dark:hover:bg-deals-card grid grid-cols-9 gap-4 items-center 
       px-2 md:px-6 py-4 h-22 ${
         isLast ? 'rounded-bl-card-box rounded-br-card-box' : ''
       }`}
      onClick={handleClick}
    >
      <div className="h-10p w-10p">
        <AssetLogo
          width={48}
          height={48}
          type={asset?.assetTypeGroup}
          logoUrl={asset?.logoUrl}
        />
      </div>
      <div className="col-span-3 ml-4 md:ml-2 lg:ml-0 flex space-x-2 items-center">
        <div className="capitalize break-words leading-17 text-sm font-semibold">
          {asset?.tickerDisplay ? asset?.tickerDisplay : asset?.name}
        </div>
      </div>
      <div className="col-span-2 justify-left text-xs font-semibold ">
        {' '}
        {walletHolding?.quantity! > 0 &&
          formatNumber(walletHolding?.quantity!) +
            ' ' +
            getQuantityDenomination(walletHolding)}
      </div>
      <div className="ml-auto col-span-3 text-price-green font-semibold text-lg lg:text-2xl flex space-x-2 items-center">
        <div>{convertUSDCurrency(walletValue)}</div>
        {wallet?.walletType === 'manual' && (
          <>
            <PencilSquareIcon
              onClick={event => onClickEdit(event)}
              className="lg:w-6 lg:h-6 w-4 h-4 text-orange-dark cursor-pointer"
            />
            <TrashIcon
              onClick={event => onClickDelete(event)}
              className="lg:w-6 lg:h-6 w-4 h-4 text-orange-dark cursor-pointer"
            />
          </>
        )}
      </div>
      {confirmDeleteManualHoldingModalOpen && (
        <ConfirmDeleteManualHoldingModal
          walletId={wallet.id!}
          walletHoldingId={walletHolding.id!}
          isOpen={confirmDeleteManualHoldingModalOpen}
          closeModal={() => setConfirmDeleteManualHoldingModalOpen(false)}
        />
      )}
    </div>
  );
};
