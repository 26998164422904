type NotificationSettingParams = {
  name?: string;
  description?: string;
  key?: string;
  value?: boolean;
};

export class NotificationSetting {
  readonly name: string;
  readonly description: string;
  readonly key: string;
  readonly value: boolean;

  constructor({
    name = '',
    description = '',
    key = '',
    value = false,
  }: NotificationSettingParams = {}) {
    this.name = name;
    this.description = description;
    this.key = key;
    this.value = value;
  }

  static fromJSON(json: any): NotificationSetting {
    return new NotificationSetting({
      name: json['name'],
      description: json['description'],
      key: json['key'],
      value: json['value'] ?? false,
    });
  }

  toJSON(): Record<string, any> {
    return {
      name: this.name,
      description: this.description,
      key: this.key,
      value: this.value,
    };
  }

  copyWith(params: NotificationSettingParams): NotificationSetting {
    return new NotificationSetting({
      name: params.name ?? this.name,
      description: params.description ?? this.description,
      key: params.key ?? this.key,
      value: params.value ?? this.value,
    });
  }
}

type NotificationSettingsParams = {
  id?: string;
  settings?: NotificationSetting[];
};

export class NotificationSettings {
  readonly id: string;
  readonly settings: NotificationSetting[];

  constructor({ id = '', settings = [] }: NotificationSettingsParams = {}) {
    this.id = id;
    this.settings = settings;
  }

  static fromJSON(json: any): NotificationSettings {
    return new NotificationSettings({
      id: json['id'],
      settings: (json['settings'] as any[]).map(setting =>
        NotificationSetting.fromJSON(setting),
      ),
    });
  }

  toJSON(): Record<string, any> {
    return {
      id: this.id,
      settings: this.settings.map(setting => setting.toJSON()),
    };
  }

  copyWith(params: NotificationSettingsParams): NotificationSettings {
    return new NotificationSettings({
      id: params.id ?? this.id,
      settings: params.settings ?? this.settings,
    });
  }
}
