// vendor libraries
import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';

// components
import { NFTCard } from './NFTCard';
import { SkeletonLoader } from '../common/Skeleton/SkeletonLoader';

// recoil
import { useThemeState } from '@/recoil/theme';

// utils
import { isMobile } from '@/utils/device';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';

interface NFTItemsSliderProps {
  items: WalletNFTItem[];
  large?: boolean;
}

export const NFTItemsSlider: React.FC<NFTItemsSliderProps> = ({
  items,
  large,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [theme] = useThemeState();
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobileView = isMobile();

  const calculateSlides = () => {
    const minCardWidth = 180;
    const gap = 16;

    if (containerWidth === 0) return large ? 4 : 3;

    const availableWidth = containerWidth - gap;
    const possibleSlides = Math.floor(availableWidth / (minCardWidth + gap));

    return Math.max(1, Math.min(possibleSlides, large ? 5 : 4));
  };

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();

    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  const slidesToShow = calculateSlides();
  const scrollLength = Math.max(1, Math.min(3, slidesToShow));
  const sliderStep = currentIndex / scrollLength;

  const getStyledDots = (dots: React.ReactNode[]): JSX.Element => {
    return (
      <ul
        style={{
          display: 'contents',
          marginTop: '20px',
        }}
      >
        {dots &&
          dots.map((dot, index) => {
            if (React.isValidElement(dot)) {
              return (
                <li key={index} className="inline-flex align-middle">
                  {dot.props.children}
                </li>
              );
            }
            return null;
          })}
      </ul>
    );
  };

  const styledDot = (index: number): JSX.Element => {
    return (
      <div
        style={{
          width: '15px',
          height: '15px',
          background:
            index === sliderStep
              ? theme === 'light'
                ? '#302f2f'
                : '#ffffff'
              : theme === 'light'
              ? '#D8D8D8'
              : '#49494D',
          borderRadius: '15px',
          marginLeft: '8px',
          cursor: 'pointer',
        }}
      />
    );
  };

  const setIndexes = (next: number) => {
    setCurrentIndex(next);
  };

  if (isMobileView) {
    return (
      <div
        ref={containerRef}
        className="px-4 py-4 bg-white dark:bg-dark-bg rounded-card-box"
      >
        <div className="grid grid-cols-2 gap-4">
          {items.map(item => (
            <div key={item.id}>
              <NFTCard item={item} showChainTag />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className="px-0 md:px-3 py-4 flex justify-center items-center grow bg-white dark:bg-dark-bg rounded-card-box"
    >
      {(items.length > 0 && (
        <div className="w-full nft-slider">
          <Slider
            className="text-center"
            autoplay={true}
            swipe={true}
            slidesToShow={Math.min(slidesToShow, items.length)}
            slidesToScroll={scrollLength}
            arrows={false}
            dots={true}
            infinite={items.length > slidesToShow}
            speed={800}
            customPaging={index => styledDot(index)}
            beforeChange={(_prev, next) => setIndexes(next)}
            appendDots={dots => getStyledDots(dots)}
            centerPadding="20px"
          >
            {items.map(item => (
              <div key={item.id} className="px-2">
                <div className="appear-front">
                  <NFTCard item={item} showChainTag />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )) || (
        <div className="flex-grow">
          <SkeletonLoader height="100%" />
        </div>
      )}
    </div>
  );
};
