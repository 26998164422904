export const ImageTag: React.FC<{
  url: string;
  alt: string;
  className?: string;
}> = ({ url, alt, className }) => {
  if (
    url?.includes('webm') ||
    url?.includes('mp4') ||
    url?.match(
      /(?:https?:\/\/(?:www\.)?)?vimeo.com\/(?:channels\/|groups\/([^]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/,
    )
  ) {
    return (
      <video
        src={url}
        className="min-h-[138px] h-[138px] min-w-[138px] w-full rounded-md -m-1 object-cover"
      />
    );
  } else
    return (
      <img
        src={url}
        alt={alt}
        className={`${className} min-w-[120px] md:min-h-[138px] h-[138px] md:min-w-[138px] w-full rounded-md -m-1 object-cover`}
      />
    );
};
