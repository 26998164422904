// vendor libraries
import { DealCardV2 } from './DealCardV2';

// components
export const DealsRowV2 = ({ deals }) => {
  return (
    <div>
      {deals.length > 0 && (
        <div className="card rounded-card-box h-full flex flex-col">
          <div className="grid grid-cols-2 md:px-6 md:flex md:flex-wrap md:justify-start">
            {deals.map(deal => (
              <DealCardV2 key={deal.id} deal={deal} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
