import React from 'react';
import { formatPriceNumber } from '@/utils/number';

interface DealLootBoxProps {
  deal: {
    loot_box_asset: {
      ticker: string;
      price_usd: number;
      name: string;
    };
    loot_box_asset_quantity_low: number;
    loot_box_asset_quantity_high: number;
  };
}

export const DealLootBox: React.FC<DealLootBoxProps> = ({ deal }) => {
  const {
    loot_box_asset,
    loot_box_asset_quantity_low,
    loot_box_asset_quantity_high,
  } = deal;
  const valueLow = loot_box_asset_quantity_low * loot_box_asset.price_usd;
  const valueHigh = loot_box_asset_quantity_high * loot_box_asset.price_usd;

  return (
    <div className="card p-6 rounded-13">
      <h3 className="text-start text-18 font-bold">Loot Box</h3>
      <div className="text-sm md:text-base mt-4 space-y-2">
        {/* Minimum Value */}
        <div className="flex justify-between">
          <span className="text-sm md:text-base text-fade-text-dark">
            Minimum Value
          </span>
          <div>
            <span className="font-semibold">
              {loot_box_asset_quantity_low.toFixed(2)} {loot_box_asset.ticker}
            </span>
            <span className="ml-2 text-fade-text-dark">
              (${formatPriceNumber(valueLow)})
            </span>
          </div>
        </div>

        {/* Maximum Value */}
        <div className="flex justify-between">
          <span className="text-sm md:text-base text-fade-text-dark">
            Maximum Value
          </span>
          <div>
            <span className="font-semibold">
              {loot_box_asset_quantity_high.toFixed(2)} {loot_box_asset.ticker}
            </span>
            <span className="ml-2 text-fade-text-dark">
              (${formatPriceNumber(valueHigh)})
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
