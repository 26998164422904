// vendor libraries
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Confetti from 'react-confetti';
import { useThemeState } from '@/recoil/theme';

interface DealPurchasedPopupProps {
  isOpen: boolean;
  closeModal: () => void;
  deal: any;
}

export const DealPurchasedPopup: React.FC<DealPurchasedPopupProps> = ({
  isOpen,
  closeModal,
  deal,
}) => {
  const [theme] = useThemeState();
  const background = theme === 'light' ? 'bg-white' : 'bg-deals-card-top';
  const textColor = theme === 'light' ? 'text-black' : 'text-white';
  const primaryColor = 'text-primary';

  const dealTitle = deal?.title || 'Deal';
  const dealCostCoins = deal?.cost_coins || 0;
  const dealCostGold = deal?.cost_gold || 0;
  const dealImageUrl = deal?.image_url || '';

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm md:max-w-md sm:w-full sm:p-6 ${background}`}
              >
                <Confetti numberOfPieces={200} gravity={0.2} />
                <div className={textColor}>
                  <div className="text-center space-y-4">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-left flex justify-between items-center"
                    >
                      <div className="text-2xl font-semibold text-center">
                        Congrats, you just purchased
                      </div>
                      <div className="flex justify-end items-center">
                        <button onClick={closeModal}>
                          <XMarkIcon className="text-price-red h-6 w-6" />
                        </button>
                      </div>
                    </Dialog.Title>

                    {/* Deal title in primary color */}
                    <h2
                      className={`text-2xl font-bold text-center ${primaryColor}`}
                    >
                      {dealTitle}
                    </h2>

                    {dealCostCoins > 0 && (
                      <p className="text-center">
                        for {dealCostCoins} AssetDash Coins
                      </p>
                    )}

                    {dealCostGold > 0 && (
                      <p className="text-center">
                        for {dealCostGold} AssetDash Gold
                      </p>
                    )}

                    <div className="flex justify-center mt-6">
                      <img
                        src={dealImageUrl}
                        alt={dealTitle}
                        className="w-24 h-24 rounded-md"
                      />
                    </div>

                    {/* Added gap between the text and button */}
                    <div className="mt-8">
                      <button
                        type="button"
                        className="w-full py-2 px-4 bg-primary text-black rounded-lg font-semibold"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
