// vendor libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// hooks
import { useGames } from '@/hooks/useGames';

// components
import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';
import { OptionsTab } from '../../common/Tab/OptionsTab';
import { GiveawayGiveawayTab } from './tabs/GiveawayGiveawayTab';
import { GiveawayActivityTab } from './tabs/GiveawayActivityTab';
import { GiveawayLeaderboardTab } from './tabs/GiveawayLeaderboardTab';
import { GiveawayResultsTab } from './tabs/GiveawayResultsTab';

import { Giveaway, MyGiveawayUserPrize } from '@/api/games';

export const SuperGiveawayDetailsContainer = () => {
  const { giveawayId } = useParams();
  const {
    loadGiveaway,
    giveawayDetails,
    giveawayMyDetails,
    giveawayPassPurchases,
    giveawayPassLeaderboards,
    giveawayUserPrizes,
  } = useGames();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [giveaway, setGiveaway] = useState<Giveaway>();
  const [giveawayPassesCount, setGiveawayPassesCount] = useState<number>(0);
  const [myGiveawayUserPrizes, setMyGiveawayUserPrizes] = useState<
    MyGiveawayUserPrize[]
  >([]);

  useEffect(() => {
    if (giveawayId && giveawayId !== giveawayDetails?.id) {
      loadSuperGiveaway(giveawayId);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giveawayId]);

  const loadSuperGiveaway = async (giveawayId: string) => {
    try {
      await loadGiveaway(giveawayId);
    } catch (err) {
      navigate('/games');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (giveawayDetails) {
      setGiveaway(giveawayDetails);
    }
  }, [giveawayDetails]);

  useEffect(() => {
    if (giveawayMyDetails) {
      setGiveawayPassesCount(giveawayMyDetails.giveaway_passes_count);
      setMyGiveawayUserPrizes(giveawayMyDetails.giveaway_user_prizes);
    }
  }, [giveawayMyDetails]);

  const [activeTab, setActiveTab] = useState('details');
  const tabs: { [key: string]: string } = {
    details: 'Details',
    activity: 'Activity',
    leaderboard: 'Leaderboard',
    results: 'Results',
  };

  return (
    <div className="mx-auto main-content grid grid-cols-1 gap-y-6 md:gap-y-0 md:gap-6">
      <div className="flex w-full justify-center pb-8">
        <OptionsTab
          tabs={tabs}
          activeTab={activeTab}
          onClick={(key: string) => setActiveTab(key)}
        />
      </div>
      {!isLoading && giveaway ? (
        <>
          {activeTab === 'details' && (
            <GiveawayGiveawayTab
              giveaway={giveaway}
              giveawayPassesCount={giveawayPassesCount}
            />
          )}
          {activeTab === 'activity' && (
            <GiveawayActivityTab
              giveawayPassPurchases={giveawayPassPurchases}
            />
          )}
          {activeTab === 'leaderboard' && (
            <GiveawayLeaderboardTab
              giveawayPassLeaderboards={giveawayPassLeaderboards}
              giveawayPassesCount={giveawayPassesCount}
            />
          )}
          {activeTab === 'results' && (
            <GiveawayResultsTab
              giveawayUserPrizes={giveawayUserPrizes}
              myGiveawayUserPrizes={myGiveawayUserPrizes}
              giveawayId={giveawayId!}
            />
          )}
        </>
      ) : (
        isLoading && <SkeletonLoader height={800} />
      )}
    </div>
  );
};
