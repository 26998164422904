import { useDeals } from '@/hooks/useDeals';

import { SkeletonLoader } from '../../common/Skeleton/SkeletonLoader';
import { useEffect } from 'react';
import { DealUserPrizeCard } from './DealUserPrizeCard';

export const MyDealsTab = () => {
  const { dealsLoading, userDealPrizes, loadUserDealPrizes } = useDeals();

  useEffect(() => {
    loadUserDealPrizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {dealsLoading ? (
        <SkeletonLoader height={600} />
      ) : userDealPrizes.length === 0 ? (
        <div className="flex flex-col justify-center items-center">
          <img
            className="w-full md:w-1/2"
            src="/assets/images/empty_deals.png"
            alt="empty deals"
          />
          <div className="font-bold text-md sm:text-2xl text-center -mt-12">
            You don't have any prizes yet.
          </div>
        </div>
      ) : (
        <div className="card rounded-card-box h-full flex flex-col pt-8 px-2">
          <div className="grid grid-cols-2 px-2 py-4 md:px-6 md:flex md:flex-wrap md:justify-start">
            {userDealPrizes.map((dealPrize: any) => (
              <DealUserPrizeCard key={dealPrize.id} userPrize={dealPrize} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
