import { InboxMessage } from '@/features/inbox/models/InboxUserMessages';

interface InboxUserMessageProps {
  inboxMessage: InboxMessage;
  onMessageClick: (id: string) => void;
  selected?: boolean;
}

export const InboxUserMessage: React.FC<InboxUserMessageProps> = ({
  inboxMessage,
  onMessageClick,
  selected = false,
}) => {
  return (
    <div
      className={`p-4 rounded-xl space-y-2 cursor-pointer transition-all duration-200
        ${
          !inboxMessage.isRead()
            ? 'border border-fade-text-light dark:border-fade-text-dark'
            : ''
        }
        ${
          selected
            ? 'bg-price-green bg-opacity-[0.15] dark:bg-opacity-[0.15]'
            : 'hover:bg-price-green hover:bg-opacity-[0.08] dark:hover:bg-opacity-[0.08]'
        }
        ${
          !selected && inboxMessage.isRead()
            ? 'bg-white dark:bg-card-color'
            : ''
        }
      `}
      onClick={() => {
        onMessageClick(inboxMessage.id);
      }}
    >
      <div className="flex flex-col justify-between h-full">
        <div className="flex justify-between items-center">
          <div
            className={`font-semibold text-base md:text-lg truncate md:max-w-[calc(100%-3rem)]
            ${
              inboxMessage.isRead() && !selected
                ? 'text-fade-text-light dark:text-fade-text-dark'
                : 'text-element-text-light dark:text-element-text-dark'
            }`}
          >
            {inboxMessage.title}
          </div>
        </div>
        <div
          className={`text-sm line-clamp-2 ${
            inboxMessage.isRead() && !selected
              ? 'text-fade-text-light dark:text-fade-text-dark'
              : 'text-element-text-light dark:text-element-text-dark'
          }`}
        >
          {inboxMessage.description}
        </div>
        <div className="flex items-center my-2">
          <div
            className={`h-5 flex items-center justify-center p-2 text-xs bg-boosted ${
              inboxMessage.isRead() && !selected
                ? 'bg-opacity-25'
                : 'bg-opacity-50'
            } rounded-full whitespace-nowrap overflow-hidden`}
          >
            {inboxMessage.tag?.name}
          </div>
        </div>
        <div className="flex items-center justify-center self-start whitespace-nowrap text-primary font-bold py-1 h-8 rounded-2xl text-sm">
          {inboxMessage.getCreatedDay()}
        </div>
      </div>
    </div>
  );
};
