import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// components
import { OptionsTab } from '../common/Tab/OptionsTab';
import { DealsTab } from './deals/DealsTab';
import { MyDealsTab } from './deals/MyDealsTab';
import { AuctionsTab } from './auctions/AuctionsTab';
import { MyBidsTab } from './auctions/MyBidsTab';
// import { AppDownloadBanner } from '../deal/AppDownloadBanner';

type DealsTabKeys = 'auctions' | 'my_bids' | 'deals' | 'my_deals';

export const MarketContainer: React.FC = () => {
  const dealsTabs: Record<DealsTabKeys, string> = {
    auctions: 'Auctions',
    my_bids: 'My Bids',
    deals: 'Marketplace',
    my_deals: 'My Prizes',
  };

  const navigate = useNavigate();
  const location = useLocation();

  const getInitialTab = (): DealsTabKeys => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab') as DealsTabKeys;
    return tab && dealsTabs[tab] ? tab : 'auctions';
  };

  const [activeTab, setActiveTab] = useState<DealsTabKeys>(getInitialTab);

  const onTabClick = (key: DealsTabKeys) => {
    setActiveTab(key);
    navigate(`?tab=${key}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab') as DealsTabKeys;
    if (tab && dealsTabs[tab]) {
      setActiveTab(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <div>
      {/* {activeTab === 'deals' && <AppDownloadBanner />} */}
      <div className="py-2 sm:py-5 md:px-6 flex items-center justify-center">
        <OptionsTab
          tabs={dealsTabs}
          activeTab={activeTab}
          onClick={onTabClick}
        />
      </div>
      {activeTab === 'auctions' && <AuctionsTab />}
      {activeTab === 'my_bids' && <MyBidsTab />}
      {activeTab === 'deals' && <DealsTab />}
      {activeTab === 'my_deals' && <MyDealsTab />}
    </div>
  );
};
