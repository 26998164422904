// vendor libraries
import React, { useState, useEffect, useCallback } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CogIcon, QrCodeIcon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import toast from 'react-hot-toast';
import { useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import Web3 from 'web3';
import { useSDK } from '@metamask/sdk-react';
import bs58 from 'bs58';

// components
import { AssetLogo } from '../../common/Logo/AssetLogo';
import { ManualHoldingDropdown } from './ManualHoldingDropdown';
import { AddressModal } from './modals/AddressModal';
import { WalletSettingsModal } from './modals/WalletSettingsModal';
import { ConfirmRemoveWalletModal } from './modals/ConfirmRemoveWalletModal';
import { ChooseVerificationModal } from './modals/ChooseVerificationModal';
import { ProofOfOwnershipModal } from './modals/ProofOfOwnershipModal';

// constants
import { walletStatusToColor } from '../constants';

// hooks
import { useAuth } from '@/hooks/useAuth';
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { Wallet } from '@/features/portfolio/models/Wallet';
import { WalletHolding } from '@/features/portfolio/models/WalletHolding';

// utils
import { formatNumber } from '@/utils/number';
import { isMobile } from '@/utils/device';

// apis
import { postVerifyWallet } from '@/api/wallets';

// recoil
import { useTokenState } from '@/recoil/token';
import { useThemeState } from '@/recoil/theme';

interface WalletDetailsCardProps {
  selectedWallet: Wallet;
  handleManualAssetSelect: (
    selectedAsset: string,
    holding?: WalletHolding,
  ) => void;
}

export const WalletDetailsCard: React.FC<WalletDetailsCardProps> = ({
  selectedWallet,
  handleManualAssetSelect,
}) => {
  const [token] = useTokenState();
  const [theme] = useThemeState();

  const { walletId } = useParams();
  const { convertUSDCurrency } = useAuth();
  const { walletHoldings, load } = usePortfolio();

  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [confirmRemoveWalletModalOpen, setConfirmRemoveWalletModalOpen] =
    useState(false);
  const [chooseVerificationModalOpen, setChooseVerificationModalOpen] =
    useState(false);
  const [openProofOfOwnershipModal, setProofOfOwnershipModalOpen] =
    useState(false);
  const [isExploding, setIsExploding] = useState(false);

  const walletHoldingLists = walletHoldings.filter(
    holding => holding.userWalletId === walletId,
  );

  const openSettingsModal = () => {
    setSettingsModalOpen(true);
  };

  const handleNewModalSelected = async (item: string) => {
    if (item === 'connect-wallet') {
      if (process.env.REACT_APP_ENABLE_LINK_APP === 'true') {
        window.location.href = `${process.env
          .REACT_APP_LINK_URL!}/verify?walletId=${
          selectedWallet.id
        }&token=${token}&theme=${theme}&source=web`;
      } else {
        setChooseVerificationModalOpen(false);
        if (selectedWallet.walletType === 'zapper') {
          if (isMobile()) {
            const accounts = await sdk?.connect();
            setEthAccount(accounts?.[0]);
          } else {
            verifyEthWallet(
              selectedWallet.walletType,
              selectedWallet.address!,
              selectedWallet.id!,
            );
          }
        } else if (selectedWallet.walletType === 'solana') {
          if (isMobile()) {
            await connect();
          } else {
            setVisible(true);
            setSignatureRequested(true);
          }
        }
      }
    } else if (item === 'proof-of-payment') {
      setChooseVerificationModalOpen(false);
      setProofOfOwnershipModalOpen(true);
    }
  };

  const handleVerifyWalletSuccess = async () => {
    setIsExploding(true);
  };

  const handleFix = () => {
    const token = localStorage.getItem('token');
    if (process.env.REACT_APP_ENABLE_LINK_APP === 'true') {
      window.open(
        `${process.env.REACT_APP_LINK_URL}/provider?providerId=${selectedWallet?.providerId}&token=${token}&walletId=${selectedWallet?.id}&theme=${theme}&source=web&mode=fix`,
        '_blank',
      );
    } else {
      window.open(
        `${process.env.REACT_APP_PROVIDER_LINK}/verify?providerId=${selectedWallet?.providerId}&token=${token}&walletId=${selectedWallet?.id}&theme=${theme}&source=web&mode=fix`,
        '_blank',
      );
    }
  };

  // sol verification
  const {
    publicKey,
    signMessage,
    connected: solConnected,
    disconnect,
    connect,
    wallet,
  } = useWallet();
  const [ethAccount, setEthAccount] = useState<string>();
  const { sdk, connected: ethConnected } = useSDK();
  const [signatureRequested, setSignatureRequested] = useState(false);
  const { setVisible } = useWalletModal();

  const requestSignature = useCallback(async () => {
    if (publicKey && wallet) {
      try {
        const data = new TextEncoder().encode(
          process.env.REACT_APP_WALLET_VERIFICATION_MESSAGE,
        );
        const signature = await signMessage!(data);
        // Verify the signature against the message and the public key
        // The public key that the client claims to have used
        const clientPublicKey = new PublicKey(publicKey);

        // Convert the public key to the wallet address format and compare it
        const isPublicKeyValid =
          clientPublicKey.toString() === selectedWallet.address;
        // Send to backend for verification
        if (isPublicKeyValid) {
          const resp = await postVerifyWallet({
            user_wallet_id: walletId!,
            wallet_type: selectedWallet.walletType!,
            address: selectedWallet.address!,
            signature: bs58.encode(signature),
          });

          if (resp.data.success) {
            handleVerifyWalletSuccess();
            await load({ checkCache: false });
            toast.success('Verification successful');
          } else {
            toast.error('Verification unsuccessful');
          }
        } else {
          toast.error('Please check if you are connected to the right account');
        }
      } catch (error: any) {
        toast.error(
          'Error verifying: ' +
            (error?.response?.data?.detail ||
              error?.message ||
              'Somethine went wrong'),
        );
      } finally {
        setSignatureRequested(false);
        disconnect();
      }
    }
  }, [
    load,
    wallet,
    walletId,
    publicKey,
    disconnect,
    setSignatureRequested,
    selectedWallet,
    signMessage,
  ]);

  useEffect(() => {
    if (solConnected) {
      disconnect();
    }
    if (ethConnected) {
      sdk?.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This will listen to the onConnect event and call requestSignature for desktop after choosing a solana wallet from modal
  useEffect(() => {
    if (solConnected && signatureRequested) {
      requestSignature();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solConnected, signatureRequested]);

  // eth verification
  const verifyEthWallet = async (
    walletType: string,
    walletAddress: string,
    walletId: string,
  ) => {
    try {
      const web3 = new Web3(window.ethereum);
      let account;
      if (isMobile()) {
        account = ethAccount;
      } else {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        account = accounts[0];
      }
      const signature = await web3.eth.personal.sign(
        process.env.REACT_APP_WALLET_VERIFICATION_MESSAGE!,
        account,
        '',
      );

      if (signature && typeof signature === 'string') {
        const verifyWalletData = await postVerifyWallet({
          user_wallet_id: walletId,
          wallet_type: walletType,
          address: walletAddress,
          signature,
        });
        if (verifyWalletData && verifyWalletData.status === 200) {
          handleVerifyWalletSuccess();
          await load({ checkCache: false });
        }
      }
    } catch (err) {
      toast.error(
        (err as any)?.response?.data?.detail
          ? err?.response?.data?.detail
          : 'Could not verify. Make sure you are opened to the correct wallet and try again.',
      );
    }
  };

  return (
    <>
      <div className="card space-y-6 px-2 md:px-6 py-4">
        <div className="space-y-10">
          <div className="flex justify-between items-center flex-wrap pt-4">
            <div className="space-x-2 flex items-center">
              <div className="h-8 w-10p rounded-full">
                <AssetLogo
                  width={28}
                  height={28}
                  type={selectedWallet.walletType}
                  logoUrl={selectedWallet.logoUrl}
                />
                <div
                  className={`h-4 w-4 relative left-6 bottom-4 rounded-full border-2 solid border-white dark:border-black`}
                  style={{
                    background:
                      selectedWallet.status === 'error'
                        ? walletStatusToColor[selectedWallet.status]
                        : walletStatusToColor[selectedWallet.readyStatus!],
                  }}
                ></div>
              </div>
              <div className="flex space-x-2 my-4">
                <div className="capitalize font-semibold flex space-x-1 leading-4.75">
                  <div className="add-overflow-dot">{selectedWallet.name}</div>

                  {selectedWallet.isVerified && (
                    <img
                      src="/assets/icons/verified.png"
                      alt="verified"
                      className="w-4 h-4"
                    />
                  )}
                </div>
                {!selectedWallet.isVerified &&
                  ['zapper', 'solana', 'sui'].includes(
                    selectedWallet.walletType!,
                  ) && (
                    <div className="text-red-600 border border-red-600 text-10 leading-17 w-max px-4p py-2p rounded-2xl mt-1">
                      Not Verified
                    </div>
                  )}
                {selectedWallet.isPrizeWallet && (
                  <div className="text-price-green border border-price-green text-10 leading-17 w-max px-4p py-2p rounded-2xl mt-1">
                    Prize Wallet
                  </div>
                )}
                {isExploding && (
                  <ConfettiExplosion
                    duration={5000}
                    height={3000}
                    width={3000}
                  />
                )}
              </div>
            </div>
            <div className="flex space-x-0 md:space-x-2 flex-wrap md:flex-row space-y-2 md:space-y-0">
              {selectedWallet?.walletType === 'manual' && (
                <ManualHoldingDropdown
                  handleManualAssetSelect={handleManualAssetSelect}
                />
              )}
              {selectedWallet.walletType !== 'manual' &&
                selectedWallet.address && (
                  <div
                    className="cursor-pointer flex space-x-2  bg-short-description dark:bg-blue-dark text-center py-4 px-6 min-w-43 leading-17 font-semibold text-sm rounded-xl"
                    onClick={() => setAddressModalOpen(true)}
                  >
                    <QrCodeIcon className="text-price-green cursor-pointer h-4 w-4" />
                    <div>
                      {selectedWallet.address?.slice(0, 6) +
                        '....' +
                        selectedWallet.address?.slice(
                          selectedWallet.address.length - 4,
                        )}
                    </div>
                  </div>
                )}
              <button
                className="w-full min-w-43 md:w-max flex items-center bg-short-description dark:bg-blue-dark py-4 px-6 leading-17 font-semibold text-sm rounded-xl"
                onClick={openSettingsModal}
              >
                <span>Settings</span>
                <CogIcon className="h-4 w-4 ml-auto md:ml-4 text-price-red" />
              </button>
            </div>
          </div>
          <div className="flex space-x-0 md:space-x-0 lg:space-x-4 flex-wrap md:flex-col lg:flex-row space-y-4 lg:space-y-0 items-center">
            <div className="bg-orange-dark  rounded-xl py-3 px-4 space-y-2 w-full lg:w-53.5">
              <div className="text-10 font-bold leading-3">Value</div>
              <div className="text-2xl leading-5 font-semibold">
                {convertUSDCurrency(selectedWallet.totalValue!)}
              </div>
            </div>
            <div className="bg-sky-blue rounded-xl py-3 px-4 space-y-2 w-full lg:w-22">
              <div className="text-10 font-bold leading-3">Holdings</div>
              <div className="text-2xl leading-5 font-semibold">
                {walletHoldingLists ? walletHoldingLists.length : '-'}
              </div>
            </div>
            <div className="flex justify-between bg-card-blue rounded-xl py-3 px-4 w-full lg:w-41.5">
              <div className="space-y-2">
                <div className="text-10 font-bold leading-3">Allocation</div>
                <div className="text-2xl my-auto leading-5 font-semibold flex justify-between items-center">
                  {formatNumber(selectedWallet.portfolioAllocation!)}%
                </div>
              </div>
              <div className="w-8 h-8 text-black mt-auto">
                <CircularProgressbar
                  value={selectedWallet.portfolioAllocation!}
                  styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `rgba(255,255,255)`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',

                      // Customize transition animation
                      transition: 'stroke-dashoffset 0.5s ease 0s',
                      // Rotate the path
                      transform: 'rotate(0.25turn)',
                      transformOrigin: 'center center',
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: `rgba(120, 240, 199, ${
                        selectedWallet.portfolioAllocation! / 100
                      })`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',
                      // Rotate the trail
                      transform: 'rotate(0.25turn)',
                      transformOrigin: 'center center',
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: '#f88',
                      // Text size
                      fontSize: '16px',
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                      fill: '#3e98c7',
                    },
                  }}
                />
              </div>
            </div>
            {isMobile() &&
              selectedWallet.walletType === 'zapper' &&
              ethConnected && (
                <div className="text-center w-full md:w-max text-accent">
                  Wallet Connected. Click again to verify.
                </div>
              )}
            {!selectedWallet.isVerified &&
              ['zapper', 'solana'].includes(selectedWallet.walletType!) && (
                <button
                  className="px-10 py-5 text-center w-full md:w-max bg-price-green text-black rounded-12 cursor-pointer"
                  onClick={() => {
                    if (selectedWallet.walletType === 'zapper') {
                      if (isMobile() && ethConnected) {
                        verifyEthWallet(
                          selectedWallet.walletType,
                          selectedWallet.address!,
                          selectedWallet.id!,
                        );
                      } else {
                        setChooseVerificationModalOpen(true);
                      }
                    } else if (selectedWallet.walletType === 'solana') {
                      if (isMobile() && solConnected) {
                        requestSignature();
                      } else {
                        setChooseVerificationModalOpen(true);
                      }
                    }
                  }}
                >
                  {(isMobile() &&
                    selectedWallet.walletType === 'solana' &&
                    solConnected) ||
                  (selectedWallet.walletType === 'zapper' && ethConnected)
                    ? 'Sign To Verify'
                    : 'Verify'}
                </button>
              )}
          </div>
          {selectedWallet.status === 'error' && (
            <div className="bg-price-red text-white font-semibold px-6 py-4 leading-4.75 rounded-lg">
              This account connection is out of sync.
              <br />
              Please reconnect. If the issue persists, email{' '}
              <span
                className="text-black font-bold cursor-pointer"
                onClick={() => {
                  window.location.href =
                    'mailto:support@assetdash.com?subject=Account connection out of sync';
                }}
              >
                support@assetdash.com
              </span>
              <button
                onClick={handleFix}
                className="py-2 px-8 bg-primary-red rounded-xl ml-4"
              >
                Fix
              </button>
            </div>
          )}
        </div>
      </div>
      <AddressModal
        isOpen={addressModalOpen}
        closeModal={() => setAddressModalOpen(false)}
        address={selectedWallet.address!}
      />
      <WalletSettingsModal
        isOpen={settingsModalOpen}
        closeModal={() => setSettingsModalOpen(false)}
        wallet={selectedWallet}
        openConfirmRemoveWalletModal={() =>
          setConfirmRemoveWalletModalOpen(true)
        }
      />
      <ConfirmRemoveWalletModal
        wallet={selectedWallet}
        modalOpen={confirmRemoveWalletModalOpen}
        closeModal={() => setConfirmRemoveWalletModalOpen(false)}
      />
      {setChooseVerificationModalOpen && (
        <ChooseVerificationModal
          open={chooseVerificationModalOpen}
          setOpen={setChooseVerificationModalOpen}
          handleNewModalSelected={handleNewModalSelected}
          wallet={selectedWallet}
        />
      )}
      {openProofOfOwnershipModal && (
        <ProofOfOwnershipModal
          open={openProofOfOwnershipModal}
          setOpen={setProofOfOwnershipModalOpen}
          wallet={selectedWallet}
        />
      )}
    </>
  );
};
