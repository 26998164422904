import { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';

import { DEFAULT_ERROR_MESSAGE } from '../features/core/constants/constants';

import {
  getNotificationSettings,
  updateNotificationSettings,
} from '../api/user';

import { NotificationSettings } from '../features/settings/models/NotificationSettings';

interface NotificationSettingsContextProps {
  settings?: NotificationSettings;
  fetch: () => Promise<void>;
  update: (settings: any) => Promise<void>;
}

export const NotificationSettingsContext = createContext<
  NotificationSettingsContextProps | undefined
>(undefined);

export const useNotificationSettings = (): NotificationSettingsContextProps => {
  const context = useContext(NotificationSettingsContext);
  if (!context) {
    throw new Error(
      'useNotificationSettings must be used within a NotificationSettingsProvider',
    );
  }
  return context;
};

export const NotificationSettingsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [settings, setSettings] = useState<NotificationSettings>();

  const fetch = async () => {
    try {
      const response = await getNotificationSettings();
      setSettings(NotificationSettings.fromJSON(response.data));
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  const update = async (settings: any) => {
    try {
      await updateNotificationSettings(settings);
      setSettings(settings);
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.detail ||
          (error as any)?.response?.data?.errors[0]?.msg ||
          DEFAULT_ERROR_MESSAGE,
      );
    }
  };

  return (
    <NotificationSettingsContext.Provider
      value={{
        // methods
        fetch,
        update,

        // values
        settings,
      }}
    >
      {children}
    </NotificationSettingsContext.Provider>
  );
};
