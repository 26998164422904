import { Giveaway } from '@/api/games';

import { GiveawayPrizesCard } from '../cards/GiveawayPrizesCard';
import { GiveawayCountdownCard } from '../cards/GiveawayCountdownCard';
import { GiveawayEligibilityCard } from '../cards/GiveawayEligibilityCard';
import { GiveawayBuyPassesCard } from '../cards/GiveawayBuyPassesCard';
import { GiveawayInfoCard } from '../cards/GiveawayInfoCard';
import { GiveawayInstructionsCard } from '../cards/GiveawayInstructionsCard';

interface GiveawayGiveawayTabProps {
  giveaway: Giveaway;
  giveawayPassesCount: number;
}

export const GiveawayGiveawayTab: React.FC<GiveawayGiveawayTabProps> = ({
  giveaway,
  giveawayPassesCount,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-8 mt-8 px-4">
      <div className="md:col-span-2 lg:col-span-2 bg-white p-3 dark:bg-deals-card-top rounded-13 h-max">
        <img
          src={giveaway.image_url!}
          className="w-full h-96 object-contain mx-auto"
          alt="logo"
        />
      </div>
      <div className="text-center md:col-span-2 lg:col-span-3 space-y-4">
        <div className="flex flex-col space-y-2 justify-between items-start">
          <div className={`font-semibold text-left text-3xl`}>
            {giveaway.title}
          </div>
        </div>
        <div
          className={`grid 
                  'grid-cols-1'
                } gap-6 my-8`}
        >
          <div className="text-left space-y-2">
            <p className="text-2xl">Description</p>
            <p>{giveaway.description}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 my-8">
          <GiveawayPrizesCard prizes={giveaway.prizes} />
        </div>
        <div className="grid grid-cols-1 gap-6 my-8">
          <GiveawayEligibilityCard giveaway={giveaway} />
        </div>
        {giveaway.instructions && giveaway.instructions.length > 0 && (
          <div className="grid gap-6 my-8 ">
            <GiveawayInstructionsCard instructions={giveaway.instructions} />
          </div>
        )}
        <div className="grid grid-cols-1 gap-6 my-8">
          <GiveawayBuyPassesCard giveaway={giveaway} />
        </div>
        <div className="grid grid-cols-1 gap-6 my-8 text-gray-300">
          <GiveawayInfoCard
            giveaway={giveaway}
            giveawayPassesCount={giveawayPassesCount}
          />
        </div>

        <div className="grid gap-6 my-8 ">
          <GiveawayCountdownCard giveaway={giveaway} />
        </div>
      </div>
    </div>
  );
};
