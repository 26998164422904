import React, { useEffect, useState } from 'react';
import { getGoldSpendings } from '@/api/gold';
import {
  GoldSpending,
  GoldSpendingsResponse,
} from '@/features/golds/models/GoldSpendings';

export const GoldSpendings: React.FC = () => {
  const [goldSpendings, setGoldSpendings] = useState<GoldSpending[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchGoldSpendings();
  }, []);

  const fetchGoldSpendings = async () => {
    setIsLoading(true);
    try {
      const response = await getGoldSpendings();
      setGoldSpendings(
        GoldSpendingsResponse.fromJson(response.data).goldSpendings,
      );
    } catch (error) {
      // Handle error
    }
    setIsLoading(false);
  };

  return (
    <div className="space-y-4">
      {!isLoading && goldSpendings?.length === 0 && (
        <div className="flex justify-center font-semibold text-lg text-gray-text">
          You have not spent any of your gold yet
        </div>
      )}
      {goldSpendings?.map((spending: GoldSpending, i) => {
        return (
          <div
            key={i}
            className="bg-white dark:bg-deals-card-top p-4 rounded-xl text-gray-600 dark:text-gray-300"
          >
            <div className="flex justify-between">
              <div className="flex space-x-2">
                <div className="mt-1">
                  <img
                    className="rounded-full"
                    src="/assets/icons/gold.png"
                    alt="Gold"
                    height={24}
                    width={24}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="font-semibold text-red-600 text-lg">
                    {spending.goldSpent} AssetDash Gold
                  </div>
                  <div className="text-sm text-gray-text">
                    {spending.spendingDescription}
                  </div>
                </div>
              </div>
              <div className="flex space-x-1 whitespace-nowrap items-center bg-primary-gray dark:bg-dark text-gray-600 dark:text-gray-300 px-2 py-1 h-8 rounded-2xl text-sm">
                <img
                  src="/assets/icons/gold_check.png"
                  alt="check"
                  height={16}
                  width={16}
                />
                <div>{spending.getCreatedDay()}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
