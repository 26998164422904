import { useNavigate } from 'react-router-dom';

// hooks
import { useAuth } from '@/hooks/useAuth';
import { usePortfolio } from '@/hooks/usePortfolio';

// models
import { WalletNFTItem } from '@/features/portfolio/models/WalletNFTItem';
import { SvgIcon } from '../common/Icon/SvgIcon';
import { AppColors } from '@/features/core/theming/colors';

// utils
import { formatPriceNumber } from '@/utils/number';

// recoil
import { useCurrencyVisibleState } from '@/recoil/currencyVisible';

// components
import { ImageWrapper } from '../common/Image/ImageWrapper';

export const NFTCard: React.FC<{
  item: WalletNFTItem;
  showChainTag?: boolean;
}> = ({ item, showChainTag = false }) => {
  const navigate = useNavigate();
  const { convertUSDCurrency } = useAuth();
  const { customNFTPricesMap } = usePortfolio();
  const [currencyVisible] = useCurrencyVisibleState();

  const handleClick = () => {
    navigate(`/nfts/${item?.nftItemId}`);
  };

  return (
    <div
      onClick={handleClick}
      className="h-full cursor-pointer bg-white dark:bg-dark rounded-12 border border-gray-text/10 dark:border-white/10"
      style={{
        minWidth: '160px',
        maxWidth: '300px',
      }}
    >
      <div>
        <div className="relative">
          <ImageWrapper
            verified={item.verified!}
            url={item?.nftItem?.getThumbnailUrl()!}
            alt="nft"
            aspectRatio={1}
          />
          <div className="absolute top-2 left-2">
            {showChainTag && (
              <div className="text-price-green text-10 leading-15 bg-primary-gray dark:bg-tabs-bg px-3p py-2p rounded-8">
                {item?.chain}
              </div>
            )}
          </div>
        </div>
        <div className="p-3 text-left">
          <div className="text-17 leading-21 font-semibold text-element-text-light dark:text-white text-ellipsis whitespace-nowrap overflow-hidden">
            {item?.nftItem?.name}
          </div>
          <div className="mt-1">
            <div className="text-primary text-17 leading-21">
              Rank #{item?.nftItem?.rarityRank ?? 'N/A'}
            </div>
          </div>
          <div className="text-17 leading-21 text-black dark:text-white mt-4 flex items-center space-x-2">
            <SvgIcon
              fullPath={`https://storage.googleapis.com/assetdash-prod-images/mobile_app/nft_chains/${item.chain}.svg`}
              color={AppColors.primary()}
              size={{ height: 14, width: 14 }}
            />
            <span className="text-primary">
              {currencyVisible
                ? formatPriceNumber(item?.priceNative!)
                : '****.****'}
            </span>
          </div>
          <div className="text-17 leading-21 text-black dark:text-white">
            {customNFTPricesMap.has(item?.assetId!)
              ? convertUSDCurrency(
                  customNFTPricesMap.get(item?.assetId!)?.priceUsd!,
                )
              : convertUSDCurrency(item?.priceUsd!)}
          </div>
        </div>
      </div>
    </div>
  );
};
